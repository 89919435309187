import { CopyOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import cn from 'classnames';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ErrorLine from '../ErrorLine';
import styles from './ErrorLines.module.less';

type ErrorLines = Readonly<{
  text?: string;
  console?: boolean;
  error?: boolean;
  success?: boolean;
}> &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ErrorLines: React.FC<ErrorLines> = ({ text, className, console, error, success, ...props }) => {
  const lines = React.useMemo(
    () =>
      text
        ?.split(/\n|\\n/g)
        .filter((str) => !!str)
        .map((str, index) => <ErrorLine key={index}>{str}</ErrorLine>),
    [text],
  );

  return (
    <div
      className={cn(
        className,
        styles.ErrorLines,
        console && text && styles.Console,
        error && text && styles.Error,
        success && text && styles.Success,
      )}
      {...props}
    >
      <div className={styles.Lines}>{lines}</div>
      {console && text && (
        <div>
          <Tooltip title="Скопировать">
            <CopyToClipboard text={text}>
              <Button type="link" onClick={() => document.execCommand('copy')}>
                <CopyOutlined />
              </Button>
            </CopyToClipboard>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default ErrorLines;
