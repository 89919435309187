import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse, Typography } from 'antd';
import cn from 'classnames';

import { State } from 'store';
import { getListOfChanges } from 'store/listOfChanges/actions';

import styles from './List.module.less';

const { Text, Title } = Typography;
const { Panel } = Collapse;

const List = () => {
  const dispatch = useDispatch();

  const [key, setKey] = useState<string | string[]>('0');

  const listOfChanges = useSelector((state: State) => state.listOfChanges.data);

  const panels = useMemo(
    () =>
      listOfChanges.map(({ version, date, add, change }, index) => (
        <Panel
          header={
            <div className={styles.Title}>
              <Title level={5}>v{version}</Title> <Text type="secondary">{date}</Text>
            </div>
          }
          key={index}
        >
          {add && add?.length > 0 && (
            <div className={styles.Block}>
              <Title level={5}>Добавлено</Title>
              {add?.map((str) => (
                <div key={str}> - {str}</div>
              ))}
            </div>
          )}
          {change && change?.length > 0 && (
            <div className={styles.Block}>
              <Title level={5}>Исправлено</Title>
              {change?.map((str) => (
                <div key={str}> - {str}</div>
              ))}
            </div>
          )}
        </Panel>
      )),
    [listOfChanges],
  );

  useEffect(() => {
    dispatch(getListOfChanges());

    // eslint-disable-next-line
  }, []);

  return (
    <div className={cn(styles.List)}>
      <Collapse activeKey={key} onChange={setKey}>
        {panels}
      </Collapse>
    </div>
  );
};

export default List;
