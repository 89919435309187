import { Card } from 'antd';
import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { State } from 'store';
import styles from './ApplicationForms.module.less';
import { CustomerSchedulingKind } from './components/CustomerSchedulingKind';
import LogoPartner from './components/LogoPartner';
import SitePartner from './components/SitePartner';

const ApplicationForms = () => {
  const {
    profile: { data: user },
    loading: { user_current: loading },
  } = useSelector((state: State) => state);

  return (
    <Card title="Кастомизация формы завки" loading={loading}>
      <div className={cn(styles.ApplicationForms)}>
        <LogoPartner url={user?.partner_settings?.logo_url || ''} />
        <SitePartner url={user?.partner_settings?.site_url || ''} />
        <CustomerSchedulingKind />
      </div>
    </Card>
  );
};

export default ApplicationForms;
