import { put, takeLeading, all } from 'redux-saga/effects';

import { endEffectsStatuses, errorEffectsStatuses, startEffectsStatuses } from 'store/utils';

import data from './mock.json';
import * as actions from './actions';

function* getListOfChanges() {
  const name = 'feedbacks';

  try {
    yield startEffectsStatuses({ name });

    yield endEffectsStatuses({ name });
    yield put(actions.getListOfChangesSuccess(data.data));
  } catch (error) {
    yield errorEffectsStatuses({ name, error });
    yield put(actions.getListOfChangesFailure());
  }
}

export default function* watchReports() {
  yield all([takeLeading(actions.getListOfChanges, getListOfChanges)]);
}
