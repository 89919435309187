import { Select, SelectProps } from 'antd';
import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { useCity } from 'shared/utils/hooks';
import { State } from 'store';
import styles from './SelectCity.module.less';

type SelectCityProps<T = any> = SelectProps<T>;

const SelectCity: React.FC<SelectCityProps> = (popss) => {
  useCity();
  const {
    cities: { byId },
  } = useSelector((state: State) => state);

  return (
    <Select
      showSearch
      placeholder="Город доставки"
      className={cn(styles.SelectCity)}
      size="large"
      allowClear
      {...popss}
    >
      {Object.values(byId)?.map(({ name, id }) => (
        <Select.Option value={id} key={id}>
          {name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectCity;
