import React from 'react';
import { getDeliveryRequest } from 'store/deliveryRequests/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { State } from 'store';
import cn from 'classnames';

import Header from './components/Header';

import styles from './DeliveryRequest.module.less';
import { Card } from 'antd';
import { selectLoadedFor, selectLoadingFor } from 'store/common/selectors';
import { Helmet } from 'react-helmet';
import Stup from './components/Stup';
import Form from './components/Form';
import Map from './components/Map';

const DeliveryRequest = () => {
  const dispatch = useDispatch();

  const { id }: { id: string } = useParams();

  const state = useSelector((state: State) => state);

  const error = state.error[`deliveryRequest_${id}`];
  const isDeliveryRequestLoading = selectLoadingFor(`deliveryRequest_${id}`)(state);
  const isDeliveryRequestLoaded = selectLoadedFor(`deliveryRequest_${id}`)(state);

  const action = React.useMemo(() => (id ? 'Изменить' : 'Заказать' + ''), [id]);

  React.useEffect(() => {
    if (id) {
      dispatch(getDeliveryRequest({ id }));
    }
  }, [id, dispatch]);

  return (
    <>
      <Helmet title={`${action} доставку`} />
      <Stup />
      <div className={cn(styles.DeliveryRequest, { [styles.Hide]: error })}>
        <Header />
        <div className={cn(styles.Body, isDeliveryRequestLoading && !isDeliveryRequestLoaded && 'disabled')}>
          <Card className={cn(styles.Card)}>
            <Form />
          </Card>
          <Map />
        </div>
      </div>
    </>
  );
};

export default DeliveryRequest;
