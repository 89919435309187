import { Input, Tag } from 'antd';
import moment from 'moment';
import { IFilters } from 'pages/DeliveryRequestsList/DeliveryRequestsList';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilterIconSearch from 'shared/components/FilterIconSearch';
import SelectAroundDate from 'shared/components/_selectComponents/SelectAroundDate';
import SelectCity from 'shared/components/_selectComponents/SelectCity';
import SelectDeliveryRequestStatus from 'shared/components/_selectComponents/SelectDeliveryRequestStatus';
import SelectUsers from 'shared/components/_selectComponents/SelectUsers';
import SelectProduct from 'shared/components/_selectComponents/SelectProduct';
import { IStatusColors, STATUS_COLORS } from 'shared/constants';
import { useCity, useDeliveryRequestStatus } from 'shared/utils/hooks';
import makeFullName from 'shared/utils/makeFullName';
import { selectCities } from 'store/cities/selectors';
import { selectLoadedFor } from 'store/common/selectors';
import { selectDeliveryRequestStatuses } from 'store/deliveryRequestStatuses/selectors';
import { getDeliveryStatuses } from 'store/deliveryStatuses/actions';
import { getProducts } from 'store/products/actions';
import { selectProducts } from 'store/products/selectors';
import MoreMenu from './components/MoreMenu';
import { selectDeliveryStatuses } from 'store/deliveryStatuses/selectors';
import SelectDeliveryStatus from 'shared/components/_selectComponents/SelectDeliveryStatus';

interface IColumns {
  setIsCancelModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsRejectModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOKModal: React.Dispatch<React.SetStateAction<boolean>>;
  setId: React.Dispatch<React.SetStateAction<string>>;
  filters: IFilters;
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>;
}

export const useColumns = ({
  setIsRejectModal,
  setIsCancelModal,
  setIsOKModal,
  setId,
  filters,
  setFilters,
}: IColumns) => {
  const dispatch = useDispatch();
  useCity();
  useDeliveryRequestStatus();

  const state: any = useSelector((state) => state);

  const isDeliveryStatusesLoaded = useMemo(() => selectLoadedFor('deliveryStatusesLoaded')(state), [state]);
  const deliveryRequestStatuses = useMemo(() => selectDeliveryRequestStatuses(state), [state]);
  const deliveryStatuses = useMemo(() => selectDeliveryStatuses(state), [state]);

  const isProductsLoaded = useMemo(() => selectLoadedFor('productsLoaded')(state), [state]);
  const products = useMemo(() => selectProducts(state), [state]);

  const citiesState = useMemo(() => selectCities(state), [state]);

  useEffect(() => {
    if (!isProductsLoaded) {
      dispatch(getProducts());
    }

    if (!isDeliveryStatusesLoaded) {
      dispatch(getDeliveryStatuses());
    }

    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      key: 'dateRequest',
      title: 'Дата по заявке',
      render: ({ date, time }: any) =>
        `${(date && moment(date).format('DD.MM.YYYY')) || ''} ${
          (time && moment.utc().startOf('day').add(time, 'minutes').format('HH:mm')) || ''
        }`,
      filterDropdown: () => (
        <SelectAroundDate
          props={[
            {
              value: filters.request_date_gte ? moment(filters.request_date_gte) : undefined,
              onChange: (date) =>
                setFilters((prev) => ({ ...prev, page: '1', request_date_gte: date?.format('YYYY-MM-DD') })),
            },
            {
              value: filters.request_date_lte ? moment(filters.request_date_lte) : undefined,
              onChange: (date) =>
                setFilters((prev) => ({ ...prev, page: '1', request_date_lte: date?.format('YYYY-MM-DD') })),
            },
          ]}
        />
      ),
      filterIcon: () => <FilterIconSearch {...{ active: !!filters.request_date_gte || !!filters.request_date_lte }} />,
    },
    {
      key: 'dateDelivery',
      title: 'Дата доставки',
      render: ({ active_delivery }: any) =>
        `${(active_delivery?.date && moment(active_delivery.date).format('DD.MM.YYYY')) || ''}`,
      filterDropdown: () => (
        <SelectAroundDate
          props={[
            {
              value: filters.date_gte ? moment(filters.date_gte) : undefined,
              onChange: (date) => setFilters((prev) => ({ ...prev, page: '1', date_gte: date?.format('YYYY-MM-DD') })),
            },
            {
              value: filters.date_lte ? moment(filters.date_lte) : undefined,
              onChange: (date) => setFilters((prev) => ({ ...prev, page: '1', date_lte: date?.format('YYYY-MM-DD') })),
            },
          ]}
        />
      ),
      filterIcon: () => <FilterIconSearch {...{ active: !!filters.date_gte || !!filters.date_lte }} />,
    },
    {
      key: 'city',
      title: 'Город',
      render: ({ delivery_city }: any) => citiesState[delivery_city]?.name || delivery_city,
      filterDropdown: () => (
        <SelectCity value={filters.city} onChange={(city) => setFilters((prev) => ({ ...prev, page: '1', city }))} />
      ),
      filterIcon: () => <FilterIconSearch {...{ active: !!filters.city }} />,
    },
    {
      key: 'address',
      title: 'Адрес',
      render: ({ address }: any) => address,
    },
    {
      key: 'request_status',
      title: 'Статус заявки',
      render: ({ status }: any) => (
        <Tag color={STATUS_COLORS[status as keyof IStatusColors]}>
          {deliveryRequestStatuses[status]?.name || status}
        </Tag>
      ),
      filterDropdown: () => (
        <SelectDeliveryRequestStatus
          value={filters.status}
          onChange={(status) => setFilters((prev) => ({ ...prev, page: '1', status }))}
        />
      ),
      filterIcon: () => <FilterIconSearch {...{ active: !!filters.status }} />,
    },
    {
      key: 'delivery_status',
      title: 'Статус доставки',
      render: ({ active_delivery }: any) =>
        active_delivery?.status && (
          <Tag color={STATUS_COLORS[active_delivery.status as keyof IStatusColors]}>
            {deliveryStatuses[active_delivery.status]?.name || active_delivery.status}
          </Tag>
        ),
      filterDropdown: () => (
        <SelectDeliveryStatus
          value={filters.delivery_status}
          onChange={(delivery_status) => setFilters((prev) => ({ ...prev, page: '1', delivery_status }))}
        />
      ),
      filterIcon: () => <FilterIconSearch {...{ active: !!filters.delivery_status }} />,
    },
    {
      key: 'product',
      title: 'Продукт',
      render: ({ product }: any) => products[product]?.name || product,
      filterDropdown: () => (
        <SelectProduct
          value={filters.product_id}
          onChange={(product_id) => setFilters((prev) => ({ ...prev, page: '1', product_id }))}
        />
      ),
      filterIcon: () => <FilterIconSearch {...{ active: !!filters.product_id }} />,
    },
    {
      key: 'fullName',
      title: 'Кому',
      render: ({ destination: { first_name, last_name, patronymic, company_name, inn } }: any) => (
        <>
          <div>{company_name || makeFullName({ first_name, last_name, patronymic })}</div>
          {inn ? <div>{inn}</div> : null}
        </>
      ),
      filterDropdown: () => (
        <Input
          placeholder="Фамилия/Компания/ИНН"
          id="last-name"
          name="last_name"
          value={filters.last_name}
          onChange={({ target: { name, value } }) => setFilters((prev) => ({ ...prev, page: '1', [name]: value }))}
          size="large"
          allowClear
          style={{ minWidth: '250px' }}
        />
      ),
      filterIcon: () => <FilterIconSearch {...{ active: !!filters.last_name }} />,
    },
    {
      key: 'external_id',
      title: 'Номер заявки',
      render: ({ external_id }: any) => external_id,
      filterDropdown: () => (
        <Input
          placeholder="Номер заявки"
          id="external-id"
          name="external_id"
          value={filters.external_id}
          onChange={({ target: { name, value } }) => setFilters((prev) => ({ ...prev, page: '1', [name]: value }))}
          size="large"
          allowClear
        />
      ),
      filterIcon: () => <FilterIconSearch {...{ active: !!filters.external_id }} />,
    },
    {
      key: 'created_by_id',
      title: 'Создал',
      render: ({ created_by_name }: any) => created_by_name,
      filterDropdown: () => (
        <SelectUsers onChange={(created_by_id) => setFilters((prev) => ({ ...prev, page: '1', created_by_id }))} />
      ),
      filterIcon: () => <FilterIconSearch {...{ active: !!filters.created_by_id }} />,
    },
    {
      key: 'created_at',
      title: 'Создание',
      render: ({ created_at }: any) => (created_at ? moment(created_at * 1000).format('DD.MM.YYYY HH:mm') : ''),
    },
    {
      key: 'unread_comments_count',
      title: 'Непрочитанные комментарии',
      render: ({ unread_comments_count }: any) => unread_comments_count,
    },
    {
      key: 'options',
      render: ({ ...rest }: any) => (
        <MoreMenu {...{ setIsRejectModal, setIsCancelModal, setIsOKModal, setId, ...rest }} />
      ),
    },
  ];

  return columns;
};
