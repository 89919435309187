import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import billingDocuments from './billingDocuments/reducers';
import cancelReasons from './cancelReasons/reducers';
import cityAreas from './citieAreas/reducers';
import cities from './cities/reducers';
import common from './common/reducers';
import daDataCity from './dadataCity/reducers';
import dadataFio from './dadataFio/reducers';
import dashboard from './dashboard/reducers';
import deliveries from './deliveries/reducers';
import deliveryRequests from './deliveryRequests/reducers';
import deliveryRequestStatuses from './deliveryRequestStatuses/reducers';
import deliveryStatuses from './deliveryStatuses/reducers';
import documents from './documents/reducers';
import failureReasons from './failureReasons/reducers';
import feedbackReasons from './feedbackReasons/reducers';
import listOfChanges from './listOfChanges/reducers';
import notifications from './notifications/reducers';
import phoneCallHistory from './phoneCallHistory/reducers';
import photoTypes from './photoTypes/reducers';
import products from './products/reducers';
import profile from './profile/reducers';
import rejectReasons from './rejectReasons/reducers';
import timeIntervals from './timeIntervals/reducers';
import userRoles from './userRoles/reducers';
import users from './users/reducers';
import taskComments from './taskComments/reducers';
import { DESTROY_SESSION } from 'shared/constants';

const appReducer = (history: any, state: any, action: any) =>
  combineReducers({
    router: connectRouter(history),
    deliveryRequestStatuses,
    deliveryRequests,
    phoneCallHistory,
    billingDocuments,
    deliveryStatuses,
    feedbackReasons,
    failureReasons,
    listOfChanges,
    cancelReasons,
    rejectReasons,
    notifications,
    timeIntervals,
    deliveries,
    photoTypes,
    daDataCity,
    dadataFio,
    documents,
    dashboard,
    userRoles,
    cityAreas,
    products,
    profile,
    cities,
    users,
    taskComments,
    ...common,
  })(state, action);

export default (history: any) => (state: any, action: any) => {
  // Clear all data in redux store to initial.
  if (action.type === DESTROY_SESSION) state = undefined;

  return appReducer(history, state, action);
};
