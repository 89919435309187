import { call, put, all, takeLatest } from 'redux-saga/effects';
import { get } from 'shared/libs/request';
import * as actions from './actions';

function* getProducts() {
  try {
    const { data } = yield call(get, '/products');

    yield put(actions.getProductsSuccess(data));
  } catch (error) {
    yield put(actions.getProductsFailure());
  }
}

export default function* watchProducts() {
  yield all([takeLatest(actions.getProducts, getProducts)]);
}
