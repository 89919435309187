import React from 'react';
import cn from 'classnames';

import List from './components/List';

import styles from './HistoryDelivery.module.less';

const HistoryDelivery = () => {
  return (
    <div className={cn(styles.HistoryDelivery)} id="historyDelivery">
      <List />
    </div>
  );
};

export default HistoryDelivery;
