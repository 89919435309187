import { call, put, all, takeLatest } from 'redux-saga/effects';
import { get } from 'shared/libs/request';
import qs from 'qs';
import * as actions from './actions';

function* getPhotoTypes({ payload: { params } }) {
  const query = qs.stringify(params, { encode: false, arrayFormat: 'brackets' });

  try {
    const { data } = yield call(get, `/photo_types?${query}`);

    yield put(actions.getPhotoTypesSuccess(data));
  } catch (error) {
    yield put(actions.getPhotoTypesFailure());
  }
}

export default function* watchPhotoTypes() {
  yield all([takeLatest(actions.getPhotoTypes, getPhotoTypes)]);
}
