import React from 'react';

import cn from 'classnames';
import { useSelector } from 'react-redux';
import { State } from 'store';

import styles from './CustomerSchedulingKind.module.less';
import WrapperField from 'shared/components/WrapperField';
import { CustomerSchedulingKindTypeEnum } from 'shared/components/_selectComponents/SelectCustomerSchedulingKind/SelectCustomerSchedulingKind';

type CustomerSchedulingKindProps = Readonly<unknown>;

const CustomerSchedulingKind: React.FC<CustomerSchedulingKindProps> = () => {
  const {
    profile: { data: user },
  } = useSelector((state: State) => state);

  // const [isModalVisible, setVisible] = React.useState(false);

  // const handlerEdit = React.useCallback(() => setVisible(true), []);

  return (
    <div className={cn(styles.CustomerSchedulingKind)}>
      <WrapperField
        value={
          user?.partner_settings?.customer_scheduling_kind &&
          CustomerSchedulingKindTypeEnum[user?.partner_settings?.customer_scheduling_kind]
        }
        title="Точное время на клиентском согласовании"
        // handlerEdit={handlerEdit}
      />
      {/* <EditModal {...{ isModalVisible, setVisible }} /> */}
    </div>
  );
};

export default CustomerSchedulingKind;
