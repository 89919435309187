import { Table } from 'antd';
import moment from 'moment';
import React from 'react';
import { BillingDocuments } from 'store/billingDocuments/reducers';
import ShowDocuments from '../ShowDocuments';

type BillingDocumentsTableProps = Readonly<{
  dataSource: BillingDocuments[];
}>;

const columns = [
  {
    title: 'Юр лицо',
    render: (record: BillingDocuments) => record?.legal_entity?.company_name,
  },
  {
    title: 'Месяц',
    render: (record: BillingDocuments) => (
      <>
        {moment().month(record.month).format('MMM')} {record.year}
      </>
    ),
  },
  {
    title: 'Тип',
    dataIndex: 'kind_rus',
    key: 'kind_rus',
  },
  {
    title: 'Документ',
    width: 160,
    render: (record: any) => <ShowDocuments data={[record]} width="140px" />,
  },
];

const BillingDocumentsTable: React.FC<BillingDocumentsTableProps> = (props) => (
  <Table {...props} columns={columns} rowKey={(record) => record.id} />
);

export default BillingDocumentsTable;
