export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const WEBSOCKET_ENDPOINT = process.env.REACT_APP_WEBSOCKET_ENDPOINT;

export const HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const DADATA_API_KEY = process.env.REACT_APP_DADATA_API_KEY;

export interface IStatusColors {
  created: string;
  validated: string;
  executed: string;
  rejected: string;
  canceled: string;
  confirmed: string;
  pending: string;
  reserved: string;
  scheduled: string;
  completed: string;
  failed: string;
}

export const STATUS_COLORS: IStatusColors = {
  created: 'purple',
  validated: 'geekblue',
  executed: 'green',
  rejected: 'gold',
  canceled: 'magenta',
  confirmed: 'lime',
  pending: 'cyan',
  reserved: 'volcano',
  scheduled: 'lime',
  completed: 'green',
  failed: 'red',
};

export interface IOperatorKind {
  nobody: string;
  agent: string;
  operator: string;
}

export const OPERATOR_KIND: IOperatorKind = {
  nobody: '',
  agent: 'Представитель',
  operator: 'Оператор',
};

export interface IStatusCall {
  [key: string]: {
    status: string;
    hint?: string;
  };
}

export const STATUS_CALL: IStatusCall = {
  pending: {
    status: 'В ожидании',
    hint: 'звонок создан, ожидает действий',
  },
  pending_answer: {
    status: 'В ожидании',
    hint: 'В ожидании начала ответа, ждём подтверждения о начале разговора из сервиса звонков',
  },
  in_progress: {
    status: 'Разговаривают',
    hint: 'Звонок в стадии разговора',
  },
  paused: {
    status: 'На паузе',
  },
  in_transfer: {
    status: 'Начат перевод звонка',
  },
  transferred: {
    status: 'Переведён на другого оператора',
  },
  from_transfer: {
    status: 'Переведенный звонок от другого оператора',
  },
  terminated: {
    status: 'Завершён',
  },
  failed: {
    status: 'Не дозвонился',
    hint: 'занят / сбросил / долго не отвечал / вне зоны действия сетиа',
  },
};

export const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS;

export const DESTROY_SESSION = 'DESTROY SESSION';
