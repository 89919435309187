import Axios from 'axios';
import qs from 'qs';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { endEffectsStatuses, errorEffectsStatuses, startEffectsStatuses } from 'store/utils';
import * as actions from './actions';

type GetBillingDocumentsProps = Readonly<{
  payload: {
    params?: {
      include?: string[];
    };
  };
}>;

function* getBillingDocuments({ payload: { params = {} } = { params: {} } }: GetBillingDocumentsProps) {
  const query = qs.stringify(params, { encode: false, arrayFormat: 'brackets' });
  const name = 'getBillingDocuments';

  try {
    yield startEffectsStatuses({ name });
    const { data } = yield call(Axios.request, {
      url: `/billing_documents?${query}`,
      method: 'GET',
    });

    yield endEffectsStatuses({ name });
    yield put(actions.getBillingDocumentsSuccess(data));
  } catch (error) {
    yield errorEffectsStatuses({ name, error });
    yield put(actions.getBillingDocumentsFailure());
  }
}

export default function* watchCities() {
  yield all([takeLatest(actions.getBillingDocuments, getBillingDocuments)]);
}
