import { call, put, all, takeLatest } from 'redux-saga/effects';
import { get } from 'shared/libs/request';
import * as actions from './actions';

function* getFailureReasons() {
  try {
    const data = yield call(get, '/failure_reasons');

    yield put(actions.getFailureReasonsSuccess(data));
  } catch (error) {
    yield put(actions.getFailureReasonsFailure());
  }
}

export default function* watchFailureReasons() {
  yield all([takeLatest(actions.getFailureReasons, getFailureReasons)]);
}
