import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import createSagaMiddleware from 'redux-saga';
import { BillingDocumentsState } from './billingDocuments/reducers';
import { CityAreasState } from './citieAreas/reducers';
import { CitiesState } from './cities/reducers';
import { DaDataCityState } from './dadataCity/reducers';
import { DaDataFioState } from './dadataFio/reducers';
import { DashboardState } from './dashboard/reducers';
import { DocumentsState } from './documents/reducers';
import { FailureReasonsState } from './failureReasons/reducers';
import { FeedbackReasonsState } from './feedbackReasons/reducers';
import { ListOfChangesState } from './listOfChanges/reducers';
import { NotificationsState } from './notifications/reducers';
import { PhoneCallHistoryState } from './phoneCallHistory/reducers';
import { ProfileState } from './profile/reducers';
import makeReducers from './reducers';
import sagas from './sagas';
import { TaskCommentsState } from './taskComments/reducers';
import { TimeIntervalsState } from './timeIntervals/reducers';
import { UserRolesState } from './userRoles/reducers';
import { UsersState } from './users/reducers';

export interface State {
  billingDocuments: BillingDocumentsState;
  phoneCallHistory: PhoneCallHistoryState;
  feedbackReasons: FeedbackReasonsState;
  failureReasons: FailureReasonsState;
  listOfChanges: ListOfChangesState;
  notifications: NotificationsState;
  timeIntervals: TimeIntervalsState;
  documents: DocumentsState;
  dashboard: DashboardState;
  cityAreas: CityAreasState;
  dadataFio: DaDataFioState;
  userRoles: UserRolesState;
  profile: ProfileState;
  daDataCity: DaDataCityState;
  cities: CitiesState;
  users: UsersState;
  taskComments: TaskCommentsState;
  deliveryStatuses: any;
  loading: { [key: string]: boolean };
  loaded: { [key: string]: boolean };
  deliveryRequests: any;
  deliveries: any;
  error: {
    [key: string]:
      | false
      | {
          status: 403 | 404 | 500;
          title?: string;
        };
  };
  deliveryRequestStatuses: {
    byId: {
      [key: string]: { name: string };
    };
  };
  products: {
    byId: {
      [key: string]: any;
    };
  };
}

export const history = createBrowserHistory();

const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  if (process.env.NODE_ENV === 'development' && !!window.__REDUX_DEVTOOLS_EXTENSION__) {
    const devTools = window.__REDUX_DEVTOOLS_EXTENSION__();

    const developmentStore = createStore(
      enableBatching(makeReducers(history)),
      compose(applyMiddleware(routerMiddleware(history), sagaMiddleware), devTools),
    );

    sagaMiddleware.run(sagas);

    return developmentStore;
  }

  const productionStore = createStore(
    enableBatching(makeReducers(history)),
    applyMiddleware(routerMiddleware(history), sagaMiddleware),
  );

  sagaMiddleware.run(sagas);

  return productionStore;
};

export const store = makeStore();
