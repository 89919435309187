import 'antd/lib/style/color/colorPalette.less';
import 'antd/lib/style/themes/default.less';
import 'antd/dist/antd.less';
import './shared/assets/styles/app-fonts-styles.less';
import './shared/assets/styles/app-global-less-styles.less';
import './shared/assets/styles/app-import-styles.less';
import './shared/assets/styles/app-global-styles.less';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import * as Sentry from '@sentry/react';
import App from 'App';
import { Integrations } from '@sentry/tracing';
import { SENTRY_DNS } from 'shared/constants';
import { store, history } from 'store';

import './index.less';
import { ReactQuery } from 'shared/reactQuery';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: SENTRY_DNS,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    normalizeDepth: 10,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ReactQuery>
        <App />
      </ReactQuery>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);
