import * as Yup from 'yup';

export const validationSchema = Yup.object({
  email: Yup.string().email('Некорректный Email').required('Введите логин'),
  password: Yup.string().required('Введите пароль'),
});

export const initialValues = {
  email: '',
  password: '',
};
