import React from 'react';
import styles from './SelectAddress.module.less';
import AutoCompleteAddress from 'shared/components/AutoCompleteAddress';
import { useSelector } from 'react-redux';
import { State } from 'store';
import { selectCities } from 'store/cities/selectors';
import { SetFieldValue } from '../../useGenFormik';
import _ from 'lodash';
import { useInitialUpdateField } from 'pages/DeliveryRequest/utils';
import { Typography } from 'antd';

type SelectAddressProps = Readonly<{
  delivery_city?: string;
  value?: string;
  setFieldValue: SetFieldValue;
  configBlur: any;
  touched?: boolean;
  error?: string;
}>;

const SelectAddress: React.FC<SelectAddressProps> = ({
  delivery_city,
  value,
  setFieldValue,
  touched,
  error,
  configBlur,
}) => {
  const state = useSelector((state: State) => state);

  const cities = _.values(selectCities(state));

  const onBlurAddress = useInitialUpdateField({
    name: 'address',
    updateMessage: 'Адрес',
    ...configBlur,
  });

  const city = cities?.find(({ id }) => id === delivery_city);

  return (
    <div className={styles.SelectAddress}>
      <AutoCompleteAddress
        city={city?.name}
        region={city?.region}
        value={value}
        disabled={!delivery_city}
        onChange={(v) => setFieldValue('address', v.value, true)}
        // setLocation={(v) => console.log(v)}
        onBlur={onBlurAddress}
      />
      {touched && error && <Typography.Text type="danger">{error}</Typography.Text>}
    </div>
  );
};

export default SelectAddress;
