import React from 'react';

import { SendOutlined } from '@ant-design/icons';
import cn from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import { getapiAdminTaskCommentsOptions, useMutationpostapiAdminTaskComments } from 'shared/api/DeliveryRequestComment';

import styles from './CreateComment.module.less';

type CreateCommentProps = NonNullable<getapiAdminTaskCommentsOptions['query']> & { refetch: () => void };

const CreateComment: React.FC<CreateCommentProps> = ({ filter, refetch }) => {
  const [comment, setComment] = React.useState<string>('');
  const { mutate } = useMutationpostapiAdminTaskComments();

  const handlerSubmit: React.FormEventHandler<HTMLFormElement | HTMLTextAreaElement> = (event) => {
    event.preventDefault();

    if (comment && (filter as any)['filter[delivery_request_uuid]']) {
      mutate(
        {
          data: {
            data: {
              attributes: { body: comment, delivery_request_uuid: (filter as any)['filter[delivery_request_uuid]'] },
            },
          } as any,
          params: { headers: { 'x-api-version': 'v2' } },
        },
        {
          onSuccess: () => {
            refetch();
            setComment('');
          },
        },
      );
    }
  };

  return (
    <div className={cn(styles.CreateComment)}>
      <form className={cn('ant-input', styles.TextArea)} onSubmit={handlerSubmit}>
        <TextareaAutosize
          value={comment}
          onChange={(event) => setComment(event.target.value)}
          placeholder="Сообщение"
          onKeyDown={(event) => {
            const isEnter = event.key === 'Enter' || event.keyCode === 13;

            if (isEnter && event.shiftKey) {
              event.preventDefault();
              handlerSubmit(event);
            }
          }}
        />
        <div className={cn(styles.Actions)}>
          <div className={cn(styles.Buttons)}>
            <button type="submit" disabled={!comment} style={{ cursor: !comment ? 'not-allowed' : '' }}>
              <SendOutlined style={{ fontSize: '24px', color: comment ? 'rgb(34 167 240)' : 'rgb(145, 145, 145)' }} />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateComment;
