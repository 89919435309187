import React from 'react';

import { FileWordOutlined, FilePdfOutlined, FileOutlined } from '@ant-design/icons';

import { Document } from 'shared/types/document';

type ShowDocumentType = Readonly<{
  data: Document;
  width?: string;
  height?: string;
}>;

const ShowDocument: React.FC<ShowDocumentType> = ({
  data: { thumb_url, original_url, url },
  width = '100%',
  height = '140px',
}) => {
  if (thumb_url) return <img alt="" width={width} height={height} src={thumb_url} />;
  if (original_url) return <FileWordOutlined style={{ fontSize: height }} />;
  if (url) return <FilePdfOutlined style={{ fontSize: height }} />;

  return <FileOutlined style={{ fontSize: height }} />;
};

export default ShowDocument;
