import Button from 'antd/es/button';
import cn from 'classnames';
import React, { FC, memo, useMemo, useState } from 'react';
import WrapperField from 'shared/components/WrapperField';
import { User } from 'store/users/reducers';
import styles from './ChangeStatusTwoFA.module.less';
import EditModal from './components/EditModal';

type ChangeStatusTwoFAProps = Readonly<{
  user: User;
}>;

const ChangeStatusTwoFA: FC<ChangeStatusTwoFAProps> = ({ user }) => {
  const [isModalVisible, setVisible] = useState(false);
  const { two_fa_status: twoFaStatus } = user;

  const block = useMemo(
    () => (
      <Button type="link" className={styles.Button} onClick={() => setVisible(true)}>
        {twoFaStatus === 'disabled' ? 'Активировать' : 'Деактивировать'} 2FA
      </Button>
    ),
    [setVisible, twoFaStatus],
  );

  return (
    <div className={cn(styles.ChangeStatusTwoFA)}>
      <WrapperField value={block} />
      <EditModal {...{ isModalVisible, setVisible, user }} />
    </div>
  );
};

export default memo(ChangeStatusTwoFA);
