import React, { memo, useMemo } from 'react';
import {
  CommentOutlined,
  DisconnectOutlined,
  FieldTimeOutlined,
  HistoryOutlined,
  StopOutlined,
  SwapLeftOutlined,
  SwapRightOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignMiddleOutlined,
  VerticalAlignTopOutlined,
} from '@ant-design/icons';
import { Card, Empty, Timeline, Tooltip, Typography } from 'antd';
import { OPERATOR_KIND, STATUS_CALL } from 'shared/constants';
import { useSelector } from 'react-redux';
import { State } from 'store';
import cn from 'classnames';
import _ from 'lodash';

import styles from './PhoneCallHistory.module.less';
import moment from 'moment';

const { Text } = Typography;

const PhoneCallHistory = () => {
  const phoneCallHistory = useSelector((state: State) => state.phoneCallHistory.byId);
  const loading = useSelector((state: State) => state.loading.phoneCallHistory);

  const data = useMemo(() => _.values(phoneCallHistory), [phoneCallHistory]);

  const content = useMemo(() => {
    if (data.length === 0 && !loading) return <Empty description={false} />;

    return (
      <Timeline>
        {data.reverse().map(({ status, direction, id, operator_kind, answered_at, talk_duration, created_at }) => {
          let icon;

          status === 'pending' && (icon = <HistoryOutlined className={styles.Pending} />);
          status === 'pending_answer' && (icon = <HistoryOutlined className={styles.PendingAnswer} />);
          status === 'in_progress' && (icon = <CommentOutlined className={styles.InProgress} />);
          status === 'paused' && (icon = <StopOutlined className={styles.Paused} />);
          status === 'in_transfer' && (icon = <VerticalAlignMiddleOutlined className={styles.Transfer} />);
          status === 'transferred' && (icon = <VerticalAlignBottomOutlined className={styles.Transfer} />);
          status === 'from_transfer' && (icon = <VerticalAlignTopOutlined className={styles.Transfer} />);
          status === 'terminated' && (icon = <DisconnectOutlined className={styles.Terminated} />);
          status === 'failed' && (icon = <FieldTimeOutlined className={styles.Failed} />);

          const startCall = (typeof answered_at === 'number' || typeof created_at === 'number') && (
            <Tooltip title="Время начала разговора">
              <Text type="secondary">
                {moment((answered_at || created_at || 0) * 1000).format('DD.MM.YYYY HH:mm:ss')}
              </Text>
            </Tooltip>
          );

          return (
            <Timeline.Item key={id} dot={icon}>
              <div>
                <Tooltip title={STATUS_CALL[status].hint}>
                  <Text>{STATUS_CALL[status].status}</Text>
                </Tooltip>{' '}
                {startCall}
              </div>

              <div>
                Клиент {direction === 'in' && <SwapRightOutlined className={styles.In} />}{' '}
                {direction === 'out' && <SwapLeftOutlined className={styles.Out} />}{' '}
                {OPERATOR_KIND[operator_kind] || ''}{' '}
                {['terminated'].includes(status) && talk_duration && (
                  <Tooltip title="Длительность звонка">
                    <Text type="secondary">
                      {moment('1900-01-01 00:00:00').add(talk_duration, 'seconds').format('mm мин ss сек')}
                    </Text>
                  </Tooltip>
                )}
              </div>
            </Timeline.Item>
          );
        })}
      </Timeline>
    );
  }, [data, loading]);

  return (
    <Card className={cn(styles.PhoneCallHistory)} title="История звонков" loading={loading} id="phoneCallHistory">
      {content}
    </Card>
  );
};

export default memo(PhoneCallHistory);
