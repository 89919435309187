import React from 'react';
import { Redirect } from 'react-router-dom';

export default (ReactNode) => ({ location }) => {
  const token = localStorage.getItem('token');
  const expiresAt = localStorage.getItem('expiresAt');

  const hasAuth = Boolean(token) && expiresAt * 1000 > Date.now();

  if (!hasAuth) {
    return (
      <Redirect
        to={{
          pathname: '/authentication',
          state: { from: location },
        }}
      />
    );
  }

  return ReactNode;
};
