import { Select, SelectProps } from 'antd';
import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { useDeliveryRequestStatus } from 'shared/utils/hooks/entities/useDeliveryRequestStatus';
import { State } from 'store';
import styles from './SelectDeliveryRequestStatus.module.less';

type SelectDeliveryRequestStatusProps<T = any> = SelectProps<T>;

const SelectDeliveryRequestStatus: React.FC<SelectDeliveryRequestStatusProps> = (props) => {
  useDeliveryRequestStatus();
  const {
    deliveryRequestStatuses: { byId },
  } = useSelector((state: State) => state);

  return (
    <Select
      showSearch
      placeholder="Статус заявки"
      className={cn(styles.SelectDeliveryRequestStatus)}
      id="status"
      value={status}
      //   onChange={(status) => setFilters((prev) => ({ ...prev, page: '1', status }))}
      size="large"
      allowClear
      {...props}
    >
      {Object.values(byId)?.map(({ name, id }: any) => (
        <Select.Option value={id} key={id}>
          {name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectDeliveryRequestStatus;
