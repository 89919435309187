import React from 'react';

import {
  FileWordOutlined,
  FilePdfOutlined,
  DeleteOutlined,
  PrinterOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Button, Empty, Modal, Tooltip } from 'antd';
import cn from 'classnames';

import { onSinglePrintClick } from 'shared/utils/onSinglePrintClick';
import { Document } from 'shared/types/document';

import ShowDocument from '../ShowDocument';

import styles from './ShowDocuments.module.less';

type ShowDocumentsType = Readonly<{
  data?: Document[];
  width?: string;
  height?: string;
  loading?: boolean;
  deletedButton?: {
    title?: string | null;
    onClick: (document: Document) => void;
    disabled?: boolean;
  };
}>;

const ShowDocuments: React.FC<ShowDocumentsType> = ({ data, width, height, loading, deletedButton }) => {
  const handlerDeleteDocument = ({ id, url, original_url, ...props }: Document) =>
    Modal.confirm({
      okType: 'danger',
      okText: 'Удалить',
      cancelText: 'Отмена',
      icon: <ExclamationCircleOutlined />,
      content: <>Вы уверены, что хотите удалить этот документ?</>,
      onOk: () => {
        deletedButton?.onClick({ id, url, original_url, ...props });
        Modal.destroyAll();
      },
    });

  if (loading) return <LoadingOutlined />;
  if (!data || data?.length === 0) return <Empty description={false} />;

  return (
    <div className={cn(styles.ShowDocuments)}>
      {data.map(({ id, url, original_url, ...props }) => (
        <div className={styles.Item} key={id}>
          <div className={styles.Title}>
            <div>
              {original_url && (
                <Tooltip title="Скачать Word">
                  <Button type="link" className={cn(styles.Primary)} onClick={() => window.open(original_url)}>
                    <FileWordOutlined className={cn(styles.Icon)} />
                  </Button>
                </Tooltip>
              )}
              {url && (
                <Tooltip title="Скачать Pdf">
                  <Button type="link" className={cn(styles.Primary)} onClick={() => window.open(url)}>
                    <FilePdfOutlined className={cn(styles.Icon)} />
                  </Button>
                </Tooltip>
              )}
            </div>

            <div>
              {url && (
                <Tooltip title={'Распечатать'}>
                  <Button type="link" className={cn(styles.Primary)} onClick={() => onSinglePrintClick(url, 'pdf')}>
                    <PrinterOutlined className={cn(styles.Icon)} />
                  </Button>
                </Tooltip>
              )}

              {deletedButton && (
                <Tooltip title={deletedButton?.title || 'Удалить'}>
                  <Button
                    type="link"
                    className={cn(styles.Danger)}
                    onClick={() => handlerDeleteDocument({ id, url, original_url, ...props })}
                    disabled={deletedButton?.disabled}
                  >
                    <DeleteOutlined className={cn(styles.Icon)} />
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
          <div className={styles.Image}>
            <ShowDocument {...{ data: { id, url, original_url, ...props }, width, height }} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ShowDocuments;
