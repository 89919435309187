import React from 'react';
import { CloseCircleOutlined, EditOutlined, ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Tooltip } from 'antd';
import cn from 'classnames';

import { useStatusActions } from 'shared/components/DeliveryRequest/utils';

import styles from './useActions.module.less';

interface IActions {
  setIsCancelModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsRejectModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOkModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const useActions = ({ setIsCancelModal, setIsRejectModal, setIsOkModal }: IActions): JSX.Element[] => {
  const fontSize = 22;
  const { id }: { id: string } = useParams();

  const history = useHistory();

  const { canBeEdited, canBeCanceled, canBeRejected, canBeOk } = useStatusActions({ id });

  return [
    <Tooltip title="Отменить" key="cancel">
      <Button type="link" disabled={!canBeCanceled} onClick={() => setIsCancelModal(true)}>
        <CloseCircleOutlined className={cn({ [styles.DangerColor]: canBeCanceled })} style={{ fontSize }} />
      </Button>
    </Tooltip>,
    <Tooltip title="Изменить" key="edit">
      <Button type="link" disabled={!canBeEdited} onClick={() => history.push(`/delivery/request/edit/${id}`)}>
        <EditOutlined className={cn({ [styles.PrimaryColor]: canBeEdited })} style={{ fontSize }} />
      </Button>
    </Tooltip>,
    <Tooltip title="Что-то не так" key="reject">
      <Button type="link" disabled={!canBeRejected} onClick={() => setIsRejectModal(true)}>
        <ExclamationCircleOutlined className={cn({ [styles.WarningColor]: canBeRejected })} style={{ fontSize }} />
      </Button>
    </Tooltip>,
    <Tooltip title="Подтвердить" key="ok">
      <Button type="link" disabled={!canBeOk} onClick={() => setIsOkModal(true)}>
        <CheckCircleOutlined className={cn({ [styles.SuccessColor]: canBeOk })} style={{ fontSize }} />
      </Button>
    </Tooltip>,
  ];
};

export default useActions;
