import { MessageOutlined, ScheduleOutlined } from '@ant-design/icons';
import { Divider, Typography } from 'antd';
import cn from 'classnames';
import React, { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import routes from 'routes';
import FeedBack from 'shared/components/FeedBack';
import Reporting from 'shared/components/Reporting';
import { State } from 'store';
import styles from './Menu.module.less';

const { Text } = Typography;

interface IMenu {
  className?: string;
  cb?: () => void;
}

const Menu: FC<IMenu> = ({ className, cb = () => {} }) => {
  const history = useHistory();

  const menu = useMemo(() => routes.filter(({ useWith }) => useWith.includes('sidebar')), []);

  const user = useSelector((state: State) => state.profile.data);

  const [isVisibleFeedBack, setVisibleFeedBack] = useState(false);
  const [isOpenReportingModal, setIOpenReportingModal] = useState(false);

  return (
    <div className={cn(styles.Menu, className)}>
      {menu.map(({ title, icon: Icon, id, path, roles }) => (
        <div
          className={cn(styles.Item, {
            [styles.Disabled]: roles && !user?.roles?.find((r) => roles?.includes(r)),
          })}
          key={id}
          onClick={() => {
            history.push(`/${path}`);
            cb();
          }}
        >
          {Icon && <Icon />}
          <Text type="secondary">{title}</Text>
        </div>
      ))}
      {user?.roles?.includes('billing') && (
        <div className={cn(styles.Item)} onClick={() => setIOpenReportingModal(true)}>
          <ScheduleOutlined />
          <Text type="secondary">Отчётность</Text>
        </div>
      )}
      <Divider />
      <div className={cn(styles.Item)} onClick={() => setVisibleFeedBack(true)}>
        <MessageOutlined />
        <Text type="secondary">Обратная связь</Text>
      </div>
      <FeedBack visible={isVisibleFeedBack} onCancel={() => setVisibleFeedBack(false)} />
      <Reporting visible={isOpenReportingModal} onCancel={() => setIOpenReportingModal(false)} />
    </div>
  );
};

export default Menu;
