import React, { useState } from 'react';
import { ScheduleOutlined } from '@ant-design/icons';
import { Button, Card, Tooltip } from 'antd';
import cn from 'classnames';

import ReportingShared from 'shared/components/Reporting';

import styles from './Reporting.module.less';

const Reporting = () => {
  const [isVisibleReporting, setVisibleReporting] = useState(false);

  return (
    <Tooltip title="Отчётность">
      <ReportingShared visible={isVisibleReporting} onCancel={() => setVisibleReporting(false)} />
      <Card className={cn(styles.Reporting)} onClick={() => setVisibleReporting(true)}>
        <Button type="link" className={cn(styles.Button)}>
          <ScheduleOutlined />
        </Button>
      </Card>
    </Tooltip>
  );
};

export default Reporting;
