import { combineReducers } from 'redux';
import { combineActions, handleActions } from 'redux-actions';
import * as actions from './actions';

export type TwoFaStaus = Readonly<'disabled' | 'initiation' | 'enabled'>;
export type TwoFaType = 'sms' | 'authenticator' | null;

export interface User {
  id: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  patronymic?: string;
  mobile_phone?: string;
  roles?: string[];
  created_at: number;
  blocked_at: number;
  intrusion_locked_at: null | number;
  last_logged_in: number;
  two_fa_attempts: number;
  failed_attempts: number;
  two_fa_passed_at: null | string;
  two_fa_status: TwoFaStaus;
  two_fa_type: TwoFaType;
  otp_sent_at: null | string;
}

export interface UsersState {
  byId: { [key: string]: User };
  allIds: string[];
}

const initialState: UsersState = {
  byId: {},
  allIds: [],
};

interface UsersAction {
  payload: User[];
}

const byId = handleActions(
  {
    [combineActions(actions.getUserSuccess, actions.getUsersSuccess).toString()]: (
      state,
      { payload = [] }: UsersAction,
    ) => ({
      ...payload?.reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: { ...item },
        }),
        {},
      ),
    }),
  },
  initialState.byId,
);

const allIds = handleActions(
  {
    [combineActions(actions.getUserSuccess, actions.getUsersSuccess).toString()]: (
      state,
      { payload = [] }: UsersAction,
    ) => [...new Set([...payload?.map(({ id }) => id)])],
  },
  initialState.allIds,
);

export default combineReducers({
  byId,
  allIds,
});
