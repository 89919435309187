import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Card, Divider } from 'antd';
import cn from 'classnames';

import { selectDeliveryRequest } from 'store/deliveryRequests/selectors';
import { selectRejectReasons } from 'store/rejectReasons/selectors';

import styles from './DataRejected.module.less';

const DataRejected = () => {
  const { id }: { id: string } = useParams();

  const state = useSelector((state) => state);

  const deliveryRequest = useMemo(() => selectDeliveryRequest(id)(state), [state, id]);

  const rejectReasons = useMemo(() => selectRejectReasons(state), [state]);

  if (
    deliveryRequest?.status === 'canceled' ||
    (!deliveryRequest?.reject_reason_code && !deliveryRequest?.reject_reason_comment)
  )
    return null;

  return (
    <div className={cn(styles.DataRejected)}>
      <Card.Meta
        title={rejectReasons?.[deliveryRequest?.reject_reason_code].name}
        description={<>{deliveryRequest?.reject_reason_comment}</>}
      />
      <Divider />
    </div>
  );
};

export default DataRejected;
