import React from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'antd';
import cn from 'classnames';
import _ from 'lodash';

import { selectLoadedFor } from 'store/common/selectors';

import DownloadAllPhotosButton from './components/DownloadAllPhotosButton';
import DownloadCertificateButton from './components/DownloadCertificateButton';

import styles from './Photos.module.less';
import List from './components/List';
import { selectDeliveries } from 'store/deliveries/selectors';
import Axios from 'axios';

interface IPhotos {
  className?: string;
}

const Photos: React.FC<IPhotos> = ({ className }) => {
  const state = useSelector((state) => state);
  const [loading, setLoading] = React.useState(false);
  const [photoFiles, setPhotoFiles] = React.useState<any[]>([]);

  const isDeliveriesLoaded = React.useMemo(() => selectLoadedFor('deliveries')(state), [state]);

  const photos = React.useMemo(() => _(selectDeliveries(state)).values().map('photos').flatMap().value(), [state]);

  React.useEffect(() => {
    if (photos.length > 0) {
      setLoading(true);
      Promise.all(
        photos.map(({ url }) =>
          Axios.get(url, {
            responseType: 'arraybuffer',
          }),
        ),
      )
        .then((photos) => {
          setPhotoFiles(photos);
          setLoading(false);
        })
        .finally(() => setLoading(false));
    }

    // eslint-disable-next-line
  }, [JSON.stringify(photos)]);

  return (
    <Card
      title={'Фотографии'}
      className={cn(styles.Photos, className)}
      loading={!isDeliveriesLoaded}
      extra={
        <div className={styles.Extra}>
          <DownloadCertificateButton />
          <DownloadAllPhotosButton {...{ photos: photoFiles, loading }} />
        </div>
      }
      id="photos"
    >
      <List {...{ photos: photoFiles }} />
    </Card>
  );
};

export default React.memo(Photos);
