import {
  Dashboard,
  DeliveryRequest,
  DeliveryRequestsList,
  DeliveryRequestsShow,
  ListOfChanges,
  NotFound,
  Profile,
  User,
  UserList,
} from 'pages';
import BillingDocuments from 'pages/BillingDocuments';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router';
import { useNotifications } from 'shared/hooks/';
import { MainLayout } from 'shared/layouts';
import { getProfile } from 'store/profile/actions';

const PartnerCabinet = () => {
  const dispatch = useDispatch();

  useNotifications();

  useEffect(() => {
    dispatch(getProfile());

    // eslint-disable-next-line
  }, []);

  return (
    <MainLayout>
      <Switch>
        <Route exact path="/profile">
          <Profile />
        </Route>

        <Route exact path="/delivery/requests/list">
          <DeliveryRequestsList />
        </Route>

        <Route exact path="/delivery/request/create">
          <DeliveryRequest />
        </Route>

        <Route exact path="/delivery/request/edit/:id?">
          <DeliveryRequest />
        </Route>

        <Route exact path="/user/list">
          <UserList />
        </Route>

        <Route exact path="/user/:id">
          <User />
        </Route>

        <Route path="/delivery/requests/:id">
          <DeliveryRequestsShow />
        </Route>

        <Route exact path="/">
          <Dashboard />
        </Route>

        <Route path="/list-of-changes" exact>
          <ListOfChanges />
        </Route>

        <Route path="/billing/documents" exact>
          <BillingDocuments />
        </Route>

        <Route>
          <NotFound />
        </Route>
      </Switch>
    </MainLayout>
  );
};

export default PartnerCabinet;
