/* eslint-disable max-len */
import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as actions from './actions';
import * as deliveryRequestActions from '../deliveryRequests/actions';

const initialState = {
  byId: {},
  allIds: [],
  data: { activeId: null },
};

const byId = handleActions(
  {
    [actions.getDeliveriesSuccess]: (state, { payload }) => ({
      ...payload.reduce(
        (acc, delivery) => ({
          ...acc,
          [delivery.id]: { id: delivery.id, ...delivery.attributes },
        }),
        {},
      ),
    }),
    [deliveryRequestActions.getDeliveryRequest]: () => ({}),
  },
  initialState.byId,
);

const allIds = handleActions(
  {
    [actions.getDeliveriesSuccess]: (state, { payload }) => [...new Set([...payload.map(({ id }) => id)])],
    [deliveryRequestActions.getDeliveryRequest]: () => [],
  },
  initialState.allIds,
);

const data = handleActions(
  {
    [actions.getDeliveryIdSuccess]: (state, { payload }) => ({
      activeId: payload?.data?.id || null,
    }),
    [deliveryRequestActions.getDeliveryRequest]: () => ({ activeId: null }),
  },
  initialState.data,
);

export default combineReducers({
  byId,
  allIds,
  data,
});
