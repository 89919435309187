import React, { FC, memo } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import cn from 'classnames';

import Photos from './components/Photos';
import Request from './components/Request';
import Address from './components/Address';
import Documents from './components/Documents';
import HistoryDelivery from './components/HistoryDelivery';
import PhoneCallHistory from './components/PhoneCallHistory';

import { deliveryRequestStatusesLoadedCounter } from '../../DeliveryRequestsShow';

import styles from './Content.module.less';
import { TaskCommentsWidget } from 'shared/components/TaskCommentsWidget';
import { useParams } from 'react-router-dom';

const Content: FC<unknown> = () => {
  const { isNotFound } = deliveryRequestStatusesLoadedCounter.useContainer();

  const { id }: { id: string } = useParams();

  const taskCommentsFilter = React.useMemo(() => ({ 'filter[delivery_request_uuid]': id }), [id]);

  if (!isNotFound)
    return (
      <div className={cn(styles.Content)}>
        {/* <Navigations /> */}
        <ResponsiveMasonry columnsCountBreakPoints={{ 320: 1, 768: 2 }}>
          <Masonry gutter="15px">
            <Request />
            <Address />
            <Documents />
            <Photos />
            <TaskCommentsWidget filter={taskCommentsFilter as any} />
            <PhoneCallHistory />
          </Masonry>
        </ResponsiveMasonry>
        <HistoryDelivery />
      </div>
    );

  return null;
};

export default memo(Content);
