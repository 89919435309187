import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';
import { CustomerSchedulingKindType } from 'shared/components/_selectComponents/SelectCustomerSchedulingKind/SelectCustomerSchedulingKind';
import * as actions from './actions';

interface Profile {
  id?: number;
  email?: string;
  first_name?: string;
  last_logged_in?: string;
  last_name?: string;
  mobile_phone?: string;
  partner_settings?: {
    customer_scheduling_kind?: CustomerSchedulingKindType;
    demo: boolean;
    logo_url: string;
    notification_email: {
      default: string;
    };
    schedules_self: boolean;
    selects_time: boolean;
    site_url: string;
    tariff_unified: boolean;
    precise_delivery: boolean;
    external_id_required: boolean;
  };
  patronymic?: string;
  roles?: string[];
}

export interface ProfileState {
  data: Profile;
}

const initialState: ProfileState = {
  data: {},
};

interface Action {
  payload: Profile;
}

const data = handleActions(
  {
    [combineActions(actions.getProfileSuccess, actions.updateProfileSuccess).toString()]: (
      state: Profile,
      { payload }: Action,
    ) => ({
      ...state,
      ...payload,
    }),
  },
  initialState.data,
);

export default combineReducers({
  data,
});
