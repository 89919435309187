import Axios from 'axios';
import { call, put, all, takeLatest } from 'redux-saga/effects';

import { endEffectsStatuses, errorEffectsStatuses, startEffectsStatuses } from 'store/utils';

import * as actions from './actions';

interface GetCitieAreasProps {
  payload?: {
    city_code?: string;
  };
}

function* getCitieAreas(props: GetCitieAreasProps) {
  const city_code = props?.payload?.city_code;
  const name = 'cityAreas';

  try {
    yield startEffectsStatuses({ name });
    const {
      data: { data: cityAreas },
    } = yield call(Axios.request, {
      url: '/city/areas',
      method: 'GET',
      params: { ['filter[city_code]']: city_code },
    });

    yield endEffectsStatuses({ name });
    yield put(actions.getCitieAreasSuccess(cityAreas));
  } catch (error) {
    yield errorEffectsStatuses({ name, error });
    yield put(actions.getCitieAreasFailure());
  }
}

export default function* watchCities() {
  yield all([takeLatest(actions.getCitieAreas, getCitieAreas)]);
}
