import { Card, Empty, Typography } from 'antd';
import Tag from 'antd/es/tag';
import Meta from 'antd/lib/card/Meta';
import cn from 'classnames';
import moment from 'moment';
import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GeolocationControl, Map, Placemark, YMaps, ZoomControl } from 'react-yandex-maps';
import { IStatusColors, STATUS_COLORS } from 'shared/constants';
import { getCenterBetwen2dots } from 'shared/utils/getCenterBetwen2dots';
import { selectLoadedFor } from 'store/common/selectors';
import { selectActiveDeliveryId, selectDelivery } from 'store/deliveries/selectors';
import { selectDeliveryStatuses } from 'store/deliveryStatuses/selectors';
import { selectFailureReasons } from 'store/failureReasons/selectors';
import { State } from 'store/index';
import { getAllTimeIntervals } from 'store/timeIntervals/actions';
import styles from './Address.module.less';

const { Text } = Typography;

interface IAddress {
  className?: string;
}

const Address: FC<IAddress> = ({ className }) => {
  const { id }: { id: string } = useParams();
  const dispatch = useDispatch();

  const state = useSelector((state: State) => state);

  const isDeliveriesLoaded = useMemo(() => selectLoadedFor('deliveries')(state), [state]);
  const { delivery_city } = useSelector((state: State) => state.deliveryRequests.byId[id] || {});
  const city = useSelector((state: State) => state.cities.byId[delivery_city]);

  const activeDeliveryId = selectActiveDeliveryId(state);
  const failureReasons = useMemo(() => selectFailureReasons(state), [state]);

  const delivery = useMemo(() => selectDelivery(activeDeliveryId)(state), [activeDeliveryId, state]);
  const deliveryStatuses = useMemo(() => selectDeliveryStatuses(state), [state]);

  const timeInterval = useMemo(() => state.timeIntervals.byId?.[delivery?.time_interval_id]?.attributes?.name || '', [
    delivery,
    state.timeIntervals,
  ]);

  const mapData = React.useMemo(() => {
    // если есть данные место фактической встречи, то выставляем центр карты между фактическим местом и место доставки
    if (delivery?.fact_lat && delivery?.fact_lon && delivery?.destination?.lat && delivery?.destination?.lon)
      return {
        center: getCenterBetwen2dots(
          [delivery?.destination?.lat, delivery?.destination?.lon],
          [delivery?.fact_lat, delivery?.fact_lon],
        ),
        zoom: 10,
      };

    // если нету данных фактического места встречи, но есть данные места доставки, то выставляем центр карты доставку
    if (delivery?.destination?.lat && delivery?.destination?.lon)
      return { center: [delivery.destination.lat, delivery.destination.lon], zoom: 16 };

    // если есть город выставляем город
    if (city?.lat && city?.lon) return { center: [city?.lat, city?.lon], zoom: 10 };

    // если вообще ничего не нашлось выставляем Мосокву
    return { center: [55.76, 37.64], zoom: 10 };
  }, [delivery?.destination?.lat, delivery?.destination?.lon, delivery?.fact_lat, delivery?.fact_lon, city]);

  const coordinates = [[delivery?.destination?.lat, delivery?.destination?.lon]];

  const extra = isDeliveriesLoaded && (
    <div className={styles.Status}>
      <Text type="secondary">
        {`${delivery?.date ? moment(delivery.date).format('DD.MM.YYYY') : ''}`}
        {` ${timeInterval} `}
      </Text>

      {delivery?.status && (
        <Tag color={STATUS_COLORS[delivery?.status as keyof IStatusColors]}>
          {deliveryStatuses?.[delivery?.status]?.name || delivery?.status}
        </Tag>
      )}
    </div>
  );

  const reason = useMemo(() => {
    const { failure_reason, failure_comment } = delivery || {};

    if (!failure_reason && !failure_comment) return null;

    return (
      <Card.Meta
        title={failureReasons?.[failure_reason]?.name}
        description={<>{failure_comment}</>}
        className={styles.FailureReasons}
      />
    );
  }, [delivery, failureReasons]);

  useEffect(() => {
    dispatch(getAllTimeIntervals());
  }, [dispatch]);

  return (
    <div className={cn(styles.Address, className)} id="address">
      {isDeliveriesLoaded && (
        <YMaps>
          <Map defaultState={mapData} width="100%" height="400px">
            {coordinates.map((coordinate, index) => (
              <Placemark geometry={coordinate} key={index} />
            ))}
            {delivery?.fact_lat && delivery?.fact_lon && (
              <Placemark geometry={[delivery?.fact_lat, delivery.fact_lon]} options={{ iconColor: 'green' }} />
            )}
            <ZoomControl options={{ float: 'right' }} />
            <GeolocationControl options={{ float: 'right' }} />
          </Map>
        </YMaps>
      )}
      <Card title="Доставка" loading={!isDeliveriesLoaded} extra={extra}>
        {delivery?.destination?.address && <Meta description={`Адрес доставки: ${delivery.destination.address}`} />}
        {delivery?.fact_address && <Meta description={`Адрес встречи: ${delivery.fact_address}`} />}
        {delivery?.service_time_fact && (
          <Meta
            description={`Время встречи - ${moment('1900-01-01 00:00:00')
              .add(delivery?.service_time_fact, 'seconds')
              .format(`${delivery.service_time_fact >= 3600 ? 'HH ч' : ''} mm мин ss сек`)}`}
          />
        )}
        {delivery?.completed_at && (
          <Meta description={`Доставлена: ${moment(delivery.completed_at * 1000).format('DD.MM.YYYY HH:mm')}`} />
        )}
        {reason}
        {delivery?.agent_info?.name && (
          <>
            <Meta
              description={`Доставил: ${delivery.agent_info?.name}
`}
            />
          </>
        )}
        {!delivery && <Empty />}
      </Card>
    </div>
  );
};

export default Address;
