import { combineReducers } from 'redux';
import { combineActions, handleActions } from 'redux-actions';
import * as actions from './actions';

export interface Attributes {
  name: string;
}

export interface Datum {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Meta {
  total: number;
}

export interface Payload {
  payload: {
    data: Datum[];
    meta: Meta;
  };
}

export interface FailureReasonsState {
  allIds: string[];
  byId: {
    [key: string]: {
      id: string;
      type: string;
      name: string;
    };
  };
}

const initialState: FailureReasonsState = {
  byId: {},
  allIds: [],
};

const byId = handleActions(
  {
    [combineActions(actions.getFailureReasonsSuccess).toString()]: (state, { payload }: Payload) => ({
      ...state,
      ...payload.data.reduce(
        (acc, reason) => ({
          ...acc,
          [reason.id]: { id: reason.id, ...reason.attributes },
        }),
        {},
      ),
    }),
  },
  initialState.byId,
);

const allIds = handleActions(
  {
    [combineActions(actions.getFailureReasonsSuccess).toString()]: (state, { payload }: Payload) => [
      ...new Set([...state, ...payload.data.map(({ id }) => id)]),
    ],
  },
  initialState.allIds,
);

export default combineReducers({
  byId,
  allIds,
});
