import React from 'react';
import styles from './CountdownTimer.module.less';
import cn from 'classnames';

type CountdownTimerProps = Readonly<{
  text?: string;
  timer: number;
  onChange?: (v: number) => void;
}>;

const CountdownTimer: React.FC<CountdownTimerProps> = (props) => {
  const [timer, setTimer] = React.useState(0);

  React.useEffect(() => setTimer(props.timer), [props.timer]);

  React.useEffect(() => {
    props.timer !== timer && props?.onChange?.(timer);
    // eslint-disable-next-line
  }, [timer]);

  React.useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (timer > 0) {
      timerId = setTimeout(() => setTimer((prev) => prev - 1), 1000);
    }

    return () => clearTimeout(timerId);
  }, [timer]);

  return <div className={cn(styles.CountdownTimer)}>{`${props.text} ${timer > 0 ? timer : ''}`}</div>;
};

export default CountdownTimer;
