import React, { FC, memo } from 'react';
import { Image } from 'antd';
import cn from 'classnames';

import WrapperField from 'shared/components/WrapperField';

import styles from './LogoPartner.module.less';

interface LogoPartnerProps {
  url: string;
}

const LogoPartner: FC<LogoPartnerProps> = ({ url }) => {
  return (
    <div className={cn(styles.LogoPartner)}>
      <WrapperField value={<Image className={styles.Image} src={url} />} title="Лого" />
    </div>
  );
};

export default memo(LogoPartner);
