import React from 'react';
import { Input, Typography } from 'antd';
import { SetFieldValue } from '../../useGenFormik';
import styles from './SelectNotes.module.less';
import { useInitialUpdateField } from 'pages/DeliveryRequest/utils';

type SelectNotesProps = Readonly<{
  value?: string;
  setFieldValue: SetFieldValue;
  configBlur: any;
  touched?: boolean;
  error?: string;
}>;

const SelectNotes: React.FC<SelectNotesProps> = ({ value, setFieldValue, configBlur, touched, error }) => {
  const onBlurNotes = useInitialUpdateField({
    name: 'notes',
    updateMessage: 'Пожелания',
    ...configBlur,
  });

  return (
    <div className={styles.SelectNotes}>
      <Input.TextArea
        placeholder="Это поможет нам доставлять лучше"
        name="notes"
        value={value}
        onChange={(e) => setFieldValue('notes', e.target.value, true)}
        onBlur={onBlurNotes}
        rows={4}
        className={styles.Select}
      />
      {touched && error && <Typography.Text type="danger">{error}</Typography.Text>}
    </div>
  );
};

export default SelectNotes;
