import React from 'react';

import { Tooltip, Typography } from 'antd';
import cn from 'classnames';
import { DeliveryRequestComment, useMutationpatchapiAdminTaskCommentsIdRead } from 'shared/api/DeliveryRequestComment';

import styles from './Comments.module.less';
import { ShowDate } from 'shared/components/_showComponents/ShowDate';
import { Arrow } from 'shared/assets/sprite/arrow';

const useIsInViewport = (ref: React.RefObject<Element>) => {
  const [isIntersecting, setIsIntersecting] = React.useState(false);

  const observer = React.useMemo(
    () => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)),
    [],
  );

  React.useEffect(() => {
    observer.observe(ref.current as any);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
};

const { Text } = Typography;

type CommentProps = Readonly<{
  handlerRead: (id: number) => void;
}> &
  DeliveryRequestComment;

const Comment = ({ id, body, updated_at, read, handlerRead, author, reason_t, created_at_t }: CommentProps) => {
  const messageRef = React.useRef<HTMLDivElement>(null);
  const fontSizeActionIcon = '10px';

  const hasPartner = author?.type === 'partner_user';

  const uiRead = (
    <Arrow
      style={{
        fontSize: fontSizeActionIcon,
        // color: read ? '#4eae50' : 'rgba(0, 0, 0, 0.45)',
        strokeWidth: 10,
        width: '19px',
        height: '15px',
      }}
      fill={read ? '#4eae50' : 'rgb(145, 145, 145)'}
    />
  );
  const usReason = reason_t ? (
    <div className={styles.Reason}>
      <span>{reason_t}</span>
    </div>
  ) : null;

  const isInViewport = useIsInViewport(messageRef);
  React.useEffect(() => {
    if (isInViewport && !read && !hasPartner) {
      handlerRead(id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInViewport]);

  return (
    <div className={cn(styles.Message)} ref={messageRef}>
      <div className={cn(styles.Wrapper, hasPartner ? styles.FrMessage : styles.PartnerMessage)}>
        <div className={cn(styles.Content)}>
          <div className={cn(styles.Text)}>{body}</div>
          <div className={cn(styles.Actions)}>
            <div>
              <Text type="secondary">
                {author?.last_name} {author?.first_name}
              </Text>
            </div>
            <div>
              <Tooltip title={<ShowDate date={new Date(updated_at * 1000)} format="dd MMMM yyyy" />}>
                <Text type="secondary">{created_at_t}</Text>
              </Tooltip>
              {uiRead}
            </div>
          </div>
        </div>
        {usReason}
      </div>
    </div>
  );
};

type CommentsProps = Readonly<{ data: DeliveryRequestComment[]; refetch: () => void }>;

const Comments: React.FC<CommentsProps> = ({ data, refetch }) => {
  const commentsRef = React.useRef<HTMLDivElement>(null);
  const { mutate } = useMutationpatchapiAdminTaskCommentsIdRead();
  const handlerRead = (id: number) => {
    mutate(
      { id, params: { headers: { 'x-api-version': 'v2' } } },
      {
        onSuccess: () => {
          refetch();
        },
      },
    );
  };

  const comments =
    !data || data?.length === 0 ? (
      <div className={styles.Empty}>
        <Text type="secondary">Пока здесь пусто</Text>
      </div>
    ) : (
      data?.map?.((comment) => <Comment key={comment.id} handlerRead={handlerRead} {...comment} />)
    );

  React.useEffect(() => {
    if (commentsRef.current) {
      commentsRef.current.scrollTop = commentsRef.current.scrollHeight;
    }
  }, [data?.length]);

  return (
    <div className={cn(styles.Comments)} ref={commentsRef}>
      {comments}
    </div>
  );
};

export default Comments;
