import React, { FC, memo, useCallback, useState, useMemo } from 'react';
import { Switch } from 'antd';
import moment from 'moment';
import cn from 'classnames';

import WrapperField from 'shared/components/WrapperField';
import { User } from 'store/users/reducers';

import EditModal from './components/EditModal';

import styles from './BlockStatus.module.less';

interface BlockStatusProps {
  user: User;
}

const BlockStatus: FC<BlockStatusProps> = ({ user }) => {
  const [isModalVisible, setVisible] = useState(false);

  const handlerEdit = useCallback(() => setVisible(true), []);

  const block = useMemo(
    () => (
      <>
        <Switch
          checked={!!user.blocked_at}
          onClick={handlerEdit}
          checkedChildren={moment(user.blocked_at * 1000).format('DD.MM.YYYY')}
        />
      </>
    ),
    [user, handlerEdit],
  );

  return (
    <div className={cn(styles.BlockStatus)}>
      <WrapperField value={block} title="Блокировка" />
      <EditModal {...{ isModalVisible, setVisible, user }} />
    </div>
  );
};

export default memo(BlockStatus);
