import print from 'print-js';

export const onSinglePrintClick = (url: any, type?: 'pdf' | 'html' | 'image' | 'json' | 'raw-html') => {
  if (url?.includes('yandexcloud')) {
    print({
      printable: url,
      type: type || url.match(/.[a-z]+\?/)[0].slice(1, -1),
      showModal: false,
    });
  } else {
    print(url);
  }
};
