import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import * as actions from './actions';

interface UserRoles {
  id: string;
  name: string;
}

export interface UserRolesState {
  byId: { [key: string]: UserRoles };
  allIds: string[];
}

const initialState: UserRolesState = {
  byId: {},
  allIds: [],
};

interface Payload {
  id: string;
  attributes: {
    name: string;
  };
}

interface UsersAction {
  payload: Payload[];
}

const byId = handleActions(
  {
    [actions.getUserRolesSuccess.toString()]: (state, { payload }: UsersAction) => ({
      ...payload?.reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: { ...item.attributes, id: item.id },
        }),
        {},
      ),
    }),
  },
  initialState.byId,
);

const allIds = handleActions(
  {
    [actions.getUserRolesSuccess.toString()]: (state, { payload }: UsersAction) => [
      ...new Set([...state, ...payload.map(({ id }) => id)]),
    ],
  },
  initialState.allIds,
);

export default combineReducers({
  byId,
  allIds,
});
