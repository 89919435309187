/* eslint-disable max-len */
// import { combineReducers } from 'redux'
import { handleActions, combineActions } from 'redux-actions';

import * as actions from './actions';

import * as deliveryRequestStatusesActions from '../deliveryRequestStatuses/actions';
import * as deliveryStatusesActions from '../deliveryStatuses/actions';
import * as deliveryRequestActions from '../deliveryRequests/actions';
import * as failureReasonsActions from '../failureReasons/actions';
import * as cancelReasonsActions from '../cancelReasons/actions';
import * as rejectReasonsActions from '../rejectReasons/actions';
import * as photoTypesActions from '../photoTypes/actions';
import * as deliveriesActions from '../deliveries/actions';
import * as documentsActions from '../documents/actions';
import * as productsActions from '../products/actions';
import * as citiesActions from '../cities/actions';
import * as usersActions from '../users/actions';

const initialState = {
  loading: {},
  loaded: {},
  error: {},
};

const error = handleActions(
  {
    [actions.setError]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [combineActions(deliveryRequestActions.getDeliveryRequestFailure)]: (state, { payload }) => ({
      ...state,
      [`deliveryRequest_${payload?.id}`]: { status: payload?.response?.status, title: payload?.response?.data?.error },
    }),
    [combineActions(deliveryRequestActions.getDeliveryRequestSuccess)]: (state, { payload }) => ({
      ...state,
      [`deliveryRequest_${payload?.id}`]: false,
    }),
  },
  initialState.loaded,
);
const loading = handleActions(
  {
    [actions.setLoading]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),

    [citiesActions.getCities]: (state) => ({ ...state, cities: true }),
    [citiesActions.getCitiesSuccess]: (state) => ({ ...state, cities: false }),
    [citiesActions.getCitiesFailure]: (state) => ({ ...state, cities: false }),

    [productsActions.getProducts]: (state) => ({ ...state, products: true }),
    [productsActions.getProductsSuccess]: (state) => ({
      ...state,
      products: false,
    }),
    [productsActions.getProductsFailure]: (state) => ({
      ...state,
      products: false,
    }),

    [deliveryStatusesActions.getDeliveryStatuses]: (state) => ({
      ...state,
      deliveryStatuses: true,
    }),
    [deliveryStatusesActions.getDeliveryStatusesSuccess]: (state) => ({
      ...state,
      deliveryStatuses: false,
    }),
    [deliveryStatusesActions.getDeliveryStatusesFailure]: (state) => ({
      ...state,
      deliveryStatuses: false,
    }),

    [photoTypesActions.getPhotoTypes]: (state) => ({
      ...state,
      photoTypes: true,
    }),
    [photoTypesActions.getPhotoTypesSuccess]: (state) => ({
      ...state,
      photoTypes: false,
    }),
    [photoTypesActions.getPhotoTypesFailure]: (state) => ({
      ...state,
      photoTypes: false,
    }),

    [cancelReasonsActions.getCancelReasons]: (state) => ({
      ...state,
      cancelReasons: true,
    }),
    [cancelReasonsActions.getCancelReasonsSuccess]: (state) => ({
      ...state,
      cancelReasons: false,
    }),
    [cancelReasonsActions.getCancelReasonsFailure]: (state) => ({
      ...state,
      cancelReasons: false,
    }),

    [failureReasonsActions.getFailureReasons]: (state) => ({
      ...state,
      failureReasons: true,
    }),
    [failureReasonsActions.getFailureReasonsSuccess]: (state) => ({
      ...state,
      failureReasons: false,
    }),
    [failureReasonsActions.getFailureReasonsFailure]: (state) => ({
      ...state,
      failureReasons: false,
    }),

    [rejectReasonsActions.getRejectReasons]: (state) => ({
      ...state,
      rejectReasons: true,
    }),
    [rejectReasonsActions.getRejectReasonsSuccess]: (state) => ({
      ...state,
      rejectReasons: false,
    }),
    [rejectReasonsActions.getRejectReasonsFailure]: (state) => ({
      ...state,
      rejectReasons: false,
    }),

    [deliveryRequestStatusesActions.getDeliveryRequestStatuses]: (state) => ({
      ...state,
      deliveryRequestStatuses: true,
    }),
    [deliveryRequestStatusesActions.getDeliveryRequestStatusesSuccess]: (state) => ({
      ...state,
      deliveryRequestStatuses: false,
    }),
    [deliveryRequestStatusesActions.getDeliveryRequestStatusesFailure]: (state) => ({
      ...state,
      deliveryRequestStatuses: false,
    }),

    [combineActions(deliveriesActions.getDeliveriesSuccess, deliveriesActions.getDeliveriesFailure)]: (state) => ({
      ...state,
      deliveries: false,
    }),

    [combineActions(usersActions.getUser, usersActions.updateUser)]: (state, { payload }) => ({
      ...state,
      [`user_${payload.id}`]: true,
    }),

    [deliveryRequestActions.getDeliveryRequests]: (state) => ({
      ...state,
      deliveryRequests: true,
    }),
    [deliveryRequestActions.getDeliveryRequestsSuccess]: (state) => ({
      ...state,
      deliveryRequests: false,
    }),
    [deliveryRequestActions.getDeliveryRequestsFailure]: (state) => ({
      ...state,
      deliveryRequests: false,
    }),
    [deliveryRequestActions.createDeliveryRequest]: (state) => ({
      ...state,
      deliveryRequest: true,
    }),
    [deliveryRequestActions.createDeliveryRequestSuccess]: (state, { payload }) => ({
      ...state,
      [`deliveryRequest_${payload?.id}`]: false,
      deliveryRequest: false,
    }),
    [combineActions(
      deliveryRequestActions.getDeliveryRequest,
      deliveryRequestActions.confirmDeliveryRequest,
      deliveryRequestActions.updateDeliveryRequest,
      deliveryRequestActions.deleteDeliveryRequest,
      deliveryRequestActions.rejectDeliveryRequest,
    )]: (state, { payload }) => ({
      ...state,
      [`deliveryRequest_${payload?.id}`]: true,
      deliveries: true,
    }),
    [combineActions(
      deliveryRequestActions.getDeliveryRequestSuccess,
      deliveryRequestActions.getDeliveryRequestFailure,
      deliveryRequestActions.confirmDeliveryRequestSuccess,
      deliveryRequestActions.confirmDeliveryRequestFailure,
      deliveryRequestActions.updateDeliveryRequestSuccess,
      deliveryRequestActions.updateDeliveryRequestFailure,
      deliveryRequestActions.deleteDeliveryRequestSuccess,
      deliveryRequestActions.deleteDeliveryRequestFailure,
    )]: (state, { payload }) => ({
      ...state,
      [`deliveryRequest_${payload?.id}`]: false,
    }),
    [combineActions(documentsActions.getDocumentsDeliveryRequest, documentsActions.deleteDocumentsDeliveryRequest)]: (
      state,
    ) => ({
      ...state,
      documents: true,
    }),
    [combineActions(
      documentsActions.getDocumentsDeliveryRequestFailure,
      documentsActions.getDocumentsDeliveryRequestSuccess,
      documentsActions.deleteDocumentsDeliveryRequestFailure,
      documentsActions.deleteDocumentsDeliveryRequestSuccess,
    )]: (state) => ({
      ...state,
      documents: false,
    }),
  },
  initialState.loading,
);

const loaded = handleActions(
  {
    [actions.setLoaded]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),

    [citiesActions.getCities]: (state) => ({ ...state, cities: false }),
    [citiesActions.getCitiesSuccess]: (state) => ({ ...state, cities: true }),
    [citiesActions.getCitiesFailure]: (state) => ({ ...state, cities: false }),

    [productsActions.getProducts]: (state) => ({ ...state, products: false }),
    [productsActions.getProductsSuccess]: (state) => ({
      ...state,
      products: true,
    }),
    [productsActions.getProductsFailure]: (state) => ({
      ...state,
      products: false,
    }),

    [deliveryStatusesActions.getDeliveryStatuses]: (state) => ({
      ...state,
      deliveryStatuses: false,
    }),
    [deliveryStatusesActions.getDeliveryStatusesSuccess]: (state) => ({
      ...state,
      deliveryStatuses: true,
    }),
    [deliveryStatusesActions.getDeliveryStatusesFailure]: (state) => ({
      ...state,
      deliveryStatuses: false,
    }),

    [cancelReasonsActions.getCancelReasons]: (state) => ({
      ...state,
      cancelReasons: false,
    }),
    [cancelReasonsActions.getCancelReasonsSuccess]: (state) => ({
      ...state,
      cancelReasons: true,
    }),
    [cancelReasonsActions.getCancelReasonsFailure]: (state) => ({
      ...state,
      cancelReasons: false,
    }),

    [rejectReasonsActions.getRejectReasons]: (state) => ({
      ...state,
      rejectReasons: false,
    }),
    [rejectReasonsActions.getRejectReasonsSuccess]: (state) => ({
      ...state,
      rejectReasons: true,
    }),
    [rejectReasonsActions.getRejectReasonsFailure]: (state) => ({
      ...state,
      rejectReasons: false,
    }),

    [photoTypesActions.getPhotoTypes]: (state) => ({
      ...state,
      photoTypes: false,
    }),
    [photoTypesActions.getPhotoTypesSuccess]: (state) => ({
      ...state,
      photoTypes: true,
    }),
    [photoTypesActions.getPhotoTypesFailure]: (state) => ({
      ...state,
      photoTypes: false,
    }),

    [deliveryRequestStatusesActions.getDeliveryRequestStatuses]: (state) => ({
      ...state,
      deliveryRequestStatuses: false,
    }),
    [deliveryRequestStatusesActions.getDeliveryRequestStatusesSuccess]: (state) => ({
      ...state,
      deliveryRequestStatuses: true,
    }),
    [deliveryRequestStatusesActions.getDeliveryRequestStatusesFailure]: (state) => ({
      ...state,
      deliveryRequestStatuses: false,
    }),

    [deliveriesActions.getDeliveriesSuccess]: (state) => ({
      ...state,
      deliveries: true,
    }),
    [deliveriesActions.getDeliveriesFailure]: (state) => ({
      ...state,
      deliveries: false,
    }),

    [deliveryRequestActions.getDeliveryRequests]: (state) => ({
      ...state,
      deliveryRequests: false,
    }),
    [deliveryRequestActions.getDeliveryRequestsSuccess]: (state) => ({
      ...state,
      deliveryRequests: true,
    }),
    [deliveryRequestActions.getDeliveryRequestsFailure]: (state) => ({
      ...state,
      deliveryRequests: false,
    }),
    [deliveryRequestActions.createDeliveryRequest]: (state) => ({
      ...state,
      deliveryRequest: false,
    }),
    [deliveryRequestActions.createDeliveryRequestSuccess]: (state, { payload }) => ({
      ...state,
      [`deliveryRequest_${payload?.id}`]: true,
      deliveryRequest: true,
    }),
    [combineActions(
      deliveryRequestActions.getDeliveryRequest,
      deliveryRequestActions.updateDeliveryRequest,
      deliveryRequestActions.deleteDeliveryRequest,
    )]: (state) => ({
      ...state,
      deliveries: false,
    }),
    [combineActions(
      deliveryRequestActions.getDeliveryRequestSuccess,
      deliveryRequestActions.updateDeliveryRequestSuccess,
      deliveryRequestActions.deleteDeliveryRequestSuccess,
    )]: (state, { payload }) => ({
      ...state,
      [`deliveryRequest_${payload?.id}`]: true,
    }),
  },
  initialState.loaded,
);

export default {
  loading,
  loaded,
  error,
};
