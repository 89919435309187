import { combineReducers } from 'redux';
import { combineActions, handleActions } from 'redux-actions';
import { DaDataFioActions } from './action';

export interface Data {
  value: string;
  unrestricted_value: string;
  data: {
    surname: string;
    name: string;
    patronymic: string;
    gender: string;
    source: string;
    qc: string;
  };
}

export interface DaDataFioState {
  allIds: string[];
  byId: {
    [key: string]: Data;
  };
}

interface DaDataFioPayload {
  payload: Data[];
}

const initialState: DaDataFioState = {
  byId: {},
  allIds: [],
};

const byId = handleActions(
  {
    [combineActions(DaDataFioActions.getDaDataFioSuccess).toString()]: (state, { payload = [] }: DaDataFioPayload) => ({
      ...payload.reduce(
        (acc, item) => ({
          ...acc,
          [item.value]: item,
        }),
        {},
      ),
    }),
  },
  initialState.byId,
);

const allIds = handleActions(
  {
    [combineActions(DaDataFioActions.getDaDataFioSuccess).toString()]: (state, { payload = [] }: DaDataFioPayload) => [
      ...new Set([...payload.map(({ value }) => value)]),
    ],
  },
  initialState.allIds,
);

export default combineReducers({
  byId,
  allIds,
});
