import { PageHeader } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BillingDocumentsTable from 'shared/components/BillingDocumentsTable';
import { State } from 'store';
import { getBillingDocuments } from 'store/billingDocuments/actions';

const BillingDocuments = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    billingDocuments: { byId: billingDocumentsObject },
  } = useSelector((state: State) => state);

  const billingDocumentsArray = React.useMemo(() => Object.values(billingDocumentsObject), [billingDocumentsObject]);

  React.useEffect(() => {
    dispatch(getBillingDocuments({ params: { include: ['legal_entity'] } }));
  }, [dispatch]);

  return (
    <div>
      <PageHeader onBack={() => history.goBack()} title="Бухгалтерские документы" />
      <BillingDocumentsTable dataSource={billingDocumentsArray} />
    </div>
  );
};

export default BillingDocuments;
