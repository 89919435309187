import { createAction } from 'redux-actions';

export const getProfile = createAction('GET_PROFILE');

export const getProfileSuccess = createAction('GET_PROFILE_SUCCESS');

export const getProfileFailure = createAction('GET_PROFILE_FAILURE');

export const updateProfile = createAction('UPDATE_PROFILE');

export const updateProfileSuccess = createAction('UPDATE_PROFILE_SUCCESS');

export const updateProfileFailure = createAction('UPDATE_PROFILE_FAILURE');

export const resetUserErrors = createAction('RESET_USER_ERRORS_FAILURE');
