import cn from 'classnames';
import React from 'react';
import SharedTwoFAStatus from 'shared/components/TwoFAStatus';
import WrapperField from 'shared/components/WrapperField';
import { User } from 'store/users/reducers';
import styles from './StatusTwoFA.module.less';

type StatusTwoFAFAProps = Readonly<{
  user: User;
}>;

const StatusTwoFA: React.FC<StatusTwoFAFAProps> = ({ user: { two_fa_status: twoFaStatus } }) => {
  const value = <SharedTwoFAStatus twoFaStatus={twoFaStatus} />;
  return (
    <div className={cn(styles.StatusTwoFA)}>
      <WrapperField value={value} title="Статус 2FA" />
    </div>
  );
};

export default StatusTwoFA;
