import { createAction } from 'redux-actions';
import { DESTROY_SESSION } from 'shared/constants';

export const createAuthentication = createAction('CREATE_AUTHENTICATION');

export const createAuthenticationSuccess = createAction('CREATE_AUTHENTICATION_SUCCESS');

export const createAuthenticationFailure = createAction('CREATE_AUTHENTICATION_FAILURE');

export const deleteAuthentication = createAction('DELETE_AUTHENTICATION');

export const deleteAuthenticationSuccess = createAction('DELETE_AUTHENTICATION_SUCCESS');

export const deleteAuthenticationFailure = createAction('DELETE_AUTHENTICATION_FAILURE');

export const destroySession = createAction(DESTROY_SESSION);
