import cn from 'classnames';
import React from 'react';
import WrapperField from 'shared/components/WrapperField';
import { User } from 'store/users/reducers';
import styles from './AttemptsFailed.module.less';

type AttemptsFailedFAProps = Readonly<{
  user: User;
}>;

const AttemptsFailed: React.FC<AttemptsFailedFAProps> = ({
  user: { failed_attempts: failedAttempts, intrusion_locked_at: intrusionLockedAt },
}) => {
  return (
    <div className={cn(styles.TwoFAStatus)}>
      <WrapperField
        value={
          <div
            className={cn(failedAttempts > 0 && !intrusionLockedAt && styles.Yellow, intrusionLockedAt && styles.Red)}
          >
            {failedAttempts}
          </div>
        }
        title="Неудачные попытки ввода пароля"
      />
    </div>
  );
};

export default AttemptsFailed;
