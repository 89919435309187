import { FilterOutlined } from '@ant-design/icons';
import { Button, Empty, Input, Select, Switch, Tag } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import qs from 'qs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useDebounce } from 'react-use';
import TwoFAStatus from 'shared/components/TwoFAStatus';
import TwoFaType from 'shared/components/TwoFaType';
import useQuery from 'shared/hooks/useQuery';
import makeFullName from 'shared/utils/makeFullName';
import filter from 'shared/utils/qsFilter';
import { State } from 'store';
import { getUsers } from 'store/users/actions';
import { User } from 'store/users/reducers';
import styles from './Table.module.less';

const { Option } = Select;

const email = 'filter[email]';
const lastName = 'filter[last_name]';
const firstName = 'filter[first_name]';
const patronymic = 'filter[patronymic]';
const mobilePhone = 'filter[mobile_phone]';
const role = 'filter[role]';
const active = 'filter[active]';

interface Filters {
  [email]?: string;
  [lastName]?: string;
  [firstName]?: string;
  [patronymic]?: string;
  [mobilePhone]?: string;
  [role]?: string;
  [active]?: string;
}

export const useHooks = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const getQueryParams = useQuery();
  const [filters, setFilters] = useState<Filters>({});

  const setFilter = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const name = e.target.name;

      setFilters((prev) => ({ ...prev, [name]: value }));
    },
    [setFilters],
  );

  const { userRoles } = useSelector((state: State) => state);

  const roleOptions = useMemo(() => {
    if (_.isEmpty(userRoles.byId)) return null;

    return _.values(userRoles.byId).map(({ id, name }) => (
      <Option value={id} label={name} key={id}>
        {name}
      </Option>
    ));
  }, [userRoles.byId]);

  const empty = useMemo(() => {
    if (_.isEmpty(filters)) return <Empty />;

    return (
      <Empty>
        <Button type="primary" onClick={() => setFilters({})}>
          Очистить фильтры
        </Button>
      </Empty>
    );
  }, [filters, setFilters]);

  const { users: userState } = useSelector((state: State) => state);

  const users = useMemo(() => _.values(userState.byId).reverse(), [userState.byId]);

  useEffect(() => {
    dispatch(getUsers(_.omitBy(filters, (v) => _.isUndefined(v) || _.isNull(v) || v === '')));

    // eslint-disable-next-line
  }, [filters]);

  const queryParams: Filters = useMemo(
    () =>
      ({
        [email]: getQueryParams.get(email),
        [lastName]: getQueryParams.get(lastName),
        [firstName]: getQueryParams.get(firstName),
        [patronymic]: getQueryParams.get(patronymic),
        [mobilePhone]: getQueryParams.get(mobilePhone),
        [role]: getQueryParams.get(role),
        [active]: getQueryParams.get(active),
      } as Filters),
    [getQueryParams],
  );

  useEffect(
    () =>
      setFilters((prev) => ({
        ...prev,
        ..._(queryParams).pickBy(_.identity).value(),
      })),

    // eslint-disable-next-line
    [setFilters, ...Object.values(queryParams)],
  );

  useDebounce(
    () => {
      if (_(filters).pickBy(_.identity).isEmpty()) return;

      const queryString = qs.stringify(filters, { filter });

      history.push(`/user/list?${queryString}`);
    },
    2500,
    [filters],
  );

  const columns = [
    {
      title: 'ФИО',
      key: 'fullName',
      render: ({ last_name, first_name, patronymic }: User) => makeFullName({ last_name, first_name, patronymic }),
      filterDropdown: () => (
        <div className={styles.Filter}>
          <Input name={lastName} value={filters[lastName]} onChange={setFilter} placeholder="Фамилия" allowClear />
          <Input name={firstName} value={filters[firstName]} onChange={setFilter} placeholder="Имя" allowClear />
          <Input name={patronymic} value={filters[patronymic]} onChange={setFilter} placeholder="Отчество" allowClear />
        </div>
      ),
      filterIcon: filters[lastName] || filters[firstName] || filters[patronymic] ? null : <FilterOutlined />,
    },
    {
      title: 'Email',
      key: 'email',
      render: ({ email }: User) => email,
      filterDropdown: () => (
        <div className={styles.Filter}>
          <Input name={email} value={filters[email]} onChange={setFilter} placeholder="Email" allowClear />
        </div>
      ),
      filterIcon: filters[email] ? null : <FilterOutlined />,
    },
    {
      title: 'Телефон',
      key: 'mobilePhone',
      width: 155,
      render: ({ mobile_phone }: User) =>
        mobile_phone && (
          <NumberFormat value={mobile_phone} allowEmptyFormatting displayType="text" format="+# (###) ###-##-##" />
        ),
      filterDropdown: () => (
        <div className={styles.Filter}>
          <NumberFormat
            placeholder="+7 (___) ___-__-__"
            name={mobilePhone}
            format="+7 (###) ###-##-##"
            className="ant-input"
            mask="_"
            value={filters[mobilePhone]}
            onValueChange={({ value }) =>
              setFilter({ target: { value: value, name: mobilePhone } } as React.ChangeEvent<HTMLInputElement>)
            }
          />
        </div>
      ),
      filterIcon: filters[mobilePhone] ? null : <FilterOutlined />,
    },
    {
      title: 'Роли',
      key: 'roles',
      render: ({ roles }: User) => (
        <>
          {roles?.map((str, index) => (
            <Tag key={index}>{userRoles?.byId[str]?.name || str}</Tag>
          ))}
        </>
      ),
      filterDropdown: () => (
        <div className={styles.Filter}>
          <Select
            style={{ width: '200px' }}
            placeholder="Роль"
            value={filters[role]}
            onChange={(v: string) =>
              setFilter({ target: { value: v, name: role } } as React.ChangeEvent<HTMLInputElement>)
            }
            optionLabelProp="label"
            allowClear
          >
            {roleOptions}
          </Select>
        </div>
      ),
      filterIcon: filters[role] ? null : <FilterOutlined />,
    },
    {
      title: 'Блокировка',
      key: 'blocked',
      render: ({ blocked_at }: User) => (
        <Switch
          className={styles.Switch}
          checked={!!blocked_at}
          checkedChildren={moment(blocked_at * 1000).format('DD.MM.YYYY')}
        />
      ),
      filterDropdown: () => (
        <div className={styles.Filter}>
          <Select
            style={{ width: '200px' }}
            placeholder="Статус блокировки"
            value={filters[active]}
            onChange={(v: string) =>
              setFilter({ target: { value: v, name: active } } as React.ChangeEvent<HTMLInputElement>)
            }
            optionLabelProp="label"
            allowClear
          >
            <Option value={0} label={'Заблокированные'} key={'block'}>
              Заблокированный
            </Option>
            <Option value={1} label={'Разблокированные'} key={'unblock'}>
              Разблокированный
            </Option>
          </Select>
        </div>
      ),
      filterIcon: filters[active] ? null : <FilterOutlined />,
    },
    {
      title: '2FA',
      key: '2fa',
      render: ({ two_fa_status: twoFaStatus, two_fa_type: twoFaType }: User) => {
        return (
          <TwoFAStatus twoFaStatus={twoFaStatus}>
            <TwoFaType twoFaType={twoFaType} />
          </TwoFAStatus>
        );
      },
    },
  ];

  return { columns, users, empty };
};
