import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Logo } from 'shared/assets/sprite';
import cn from 'classnames';

import { useNotifications } from 'shared/hooks/';

import Auth from './components/Auth';
import CheckTwoFa from './components/CheckTwoFa';
import TitleHelmet from './components/TitleHelmet';
import ChoiceTwoFa from './components/ChoiceTwoFa';
import ResetPassword from './components/ResetPassword';
import RecoveryPassword from './components/RecoveryPassword';
import { authentication, resetRecovery, passwordRecovery, choiceTwoFa, checkTwoFa } from './Authentication.const';

import styles from './Authentication.module.less';

const { primary } = styles;

const Authentication = () => {
  useNotifications();

  return (
    <div className={styles.Authentication}>
      <div className={styles.Body}>
        <Logo className={styles.Icon} colors={['#fff', primary]} />

        <div className={cn(styles.Routers)}>
          <Switch>
            <Route path={[authentication, resetRecovery, choiceTwoFa, passwordRecovery, checkTwoFa]} exact={true}>
              <TitleHelmet />
              <Auth />
              <ResetPassword />
              <ChoiceTwoFa />
              <RecoveryPassword />
              <CheckTwoFa />
            </Route>
            <Route path="*">
              <Redirect to="/authentication" />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Authentication;
