import React, { FC, useEffect, useMemo } from 'react';
import { Modal, ModalProps, DatePicker, Typography, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { State } from 'store';
import moment from 'moment';
import cn from 'classnames';
import * as Yup from 'yup';

import { createReports } from 'store/reports/actions';
import useCheckModal from 'shared/utils/checkModal';

import styles from './Reporting.module.less';

const { Text } = Typography;
const { Option } = Select;

const { RangePicker } = DatePicker;

const initialValues = {
  type: 'partner_daily',
  from: moment(),
  to: moment(),
};

const validationSchema = Yup.object().shape({
  type: Yup.string().required('Обязательное поле'),
  from: Yup.date().nullable().required('Необходимо выбрать дата начала отчёта'),
  to: Yup.date().nullable().required('Необходимо выбрать дата окончания отчёта'),
});

const Reporting: FC<ModalProps> = ({ ...modalProps }) => {
  const dispatch = useDispatch();

  const isReportingLoading = useSelector((state: State) => state.loading.reports);
  const isReportingError = useSelector((state: State) => state.error.reports);

  const { values, handleSubmit, submitForm, touched, errors, setFieldValue, setFieldTouched, resetForm } = useFormik({
    initialValues,
    onSubmit: ({ type, from, to }) => {
      dispatch(
        createReports({
          type,
          from: (from! as moment.Moment).format('DD.MM.YYYY'),
          to: (to! as moment.Moment).format('DD.MM.YYYY'),
        }),
      );
    },
    validationSchema,
  });

  const errorRangePicker = useMemo(() => {
    if (touched.from && errors.from) return <Text type="danger">{errors.from}</Text>;
    if (touched.to && errors.to) return <Text type="danger">{errors.to}</Text>;
    return null;
  }, [touched, errors]);

  useCheckModal({
    isModalVisible: !!modalProps.visible,
    loading: isReportingLoading,
    error: isReportingError,
    onClose: modalProps.onCancel,
    message: '',
  });

  useEffect(() => {
    modalProps.visible && resetForm();

    // eslint-disable-next-line
  }, [modalProps.visible]);

  return (
    <Modal
      {...modalProps}
      title="Отчётность"
      okButtonProps={{ loading: isReportingLoading }}
      okText="Выгрузить"
      onOk={submitForm}
      maskTransitionName=""
    >
      <form onSubmit={handleSubmit} className={cn(styles.Reporting)}>
        <Select
          className={cn(styles.Select)}
          value={values.type}
          placeholder="Тип отчетности"
          onChange={(v) => setFieldValue('type', v)}
          onBlur={() => setFieldTouched('type', true)}
        >
          <Option value="partner_daily">Подневный (по дате доставки)</Option>
          <Option value="partner_daily_by_requests">Подневный (по дате заявки)</Option>
        </Select>
        {touched.type && errors.type && <Text type="danger">{errors.type}</Text>}

        <RangePicker
          value={[values.from, values.to]}
          onChange={(values) => {
            setFieldValue('from', values?.[0], true);
            setFieldValue('to', values?.[1], true);
          }}
          className={cn(styles.RangePicker)}
          onBlur={() => setFieldTouched('from', true)}
          disabledDate={(c) => c && c > moment()}
          format="DD.MM.YYYY"
        />
        {errorRangePicker}
      </form>
    </Modal>
  );
};

export default Reporting;
