import { FormikErrors, useFormik } from 'formik';
import { selectDeliveryRequest } from 'store/deliveryRequests/selectors';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { State } from 'store';
import makeFullName from 'shared/utils/makeFullName';
import React from 'react';

export type InitialValues = Readonly<{
  product?: string;
  delivery_city?: string;
  date?: string;
  address?: string;
  precise?: boolean;
  time_interval_id?: string;
  first_name?: string;
  last_name?: string;
  patronymic?: string;
  fulName?: string;
  mobile_phone?: string;
  email?: string;
  company_name?: string;
  notes?: string;
  time?: number;
  inn?: string;
  external_id?: string;
}>;

export type SetFieldValue = (
  field: string,
  value: any,
  shouldValidate?: boolean | undefined,
) => Promise<void> | Promise<FormikErrors<InitialValues>>;

export const useGenFormik = () => {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const state = useSelector((state: State) => state);

  const deliveryRequest = selectDeliveryRequest(id)(state);
  const currentUser = useSelector((state: State) => state.profile.data);

  const initialValues = React.useMemo(
    () => ({
      product: deliveryRequest?.product,
      delivery_city: deliveryRequest?.delivery_city,
      date: deliveryRequest?.date,
      address: deliveryRequest?.address,
      precise: deliveryRequest?.precise,
      time_interval_id: deliveryRequest?.time_interval_id,
      first_name: deliveryRequest?.destination?.first_name,
      last_name: deliveryRequest?.destination?.last_name,
      patronymic: deliveryRequest?.destination?.patronymic,
      fulName:
        deliveryRequest?.destination?.first_name ||
        deliveryRequest?.destination?.last_name ||
        deliveryRequest?.destination?.patronymic
          ? makeFullName(deliveryRequest?.destination)
          : undefined,
      company_name: deliveryRequest?.destination?.company_name,
      notes: deliveryRequest?.notes,
      inn: deliveryRequest?.destination?.inn,
      external_id: deliveryRequest?.external_id,
      mobile_phone: undefined,
      email: undefined,
    }),
    [deliveryRequest],
  );
  const formik = useFormik<InitialValues>({
    initialValues,
    onSubmit: () => {
      if (Object.values(getErrors())?.length === 0) history.push(`/delivery/requests/${id}`);
    },
    enableReinitialize: true,
  });

  const getErrors = () => ({
    ...formik.errors,

    // если у бека есть ошибка, то сначала покажем ошибку бекенда
    ...Object.keys(deliveryRequest?.validation_errors || {}).reduce(
      (acc, key) => ({ ...acc, [key]: deliveryRequest?.validation_errors[key][0] }),
      {},
    ),

    // если есть не заполненые обязательные поля, то сначала покажем ошибку об - не заполнено
    ...['last_name', 'delivery_city', 'first_name', 'address', 'date', 'product', 'external_id'].reduce<
      Record<string, string>
    >((acc, key) => {
      if (key === 'external_id' && !currentUser?.partner_settings?.external_id_required) return acc;
      if (key === 'date' && !currentUser?.partner_settings?.schedules_self) return acc;
      if ((formik?.values as any)[key]) return acc;

      return {
        ...acc,
        [key]: 'не заполнено',
      };
    }, {}),
  });

  return {
    ...formik,
    errors: getErrors(),
  };
};
