import React from 'react';
import { UnorderedListOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Button, Card, Tooltip } from 'antd';
import cn from 'classnames';

import styles from './ListDeliveris.module.less';

const ListDeliveris = () => {
  const history = useHistory();

  return (
    <Tooltip title="Мои доставки">
      <Card className={cn(styles.ListDeliveris)} onClick={() => history.push('/delivery/requests/list')}>
        <Button type="link" className={cn(styles.Button)}>
          <UnorderedListOutlined />
        </Button>
      </Card>
    </Tooltip>
  );
};

export default ListDeliveris;
