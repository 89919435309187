import { call, put, all, takeLeading } from 'redux-saga/effects';
import { get } from 'shared/libs/request';

import { endEffectsStatuses, errorEffectsStatuses, startEffectsStatuses } from 'store/utils';

import { DashboardActions } from './actions';

function* getDashboardRequest() {
  const name = 'dashboard';

  try {
    yield startEffectsStatuses({ name });

    const { data } = yield call(get, `/dashboard`);

    yield put(DashboardActions.getDashboarSuccess(data || {}));

    yield endEffectsStatuses({ name });
  } catch (error) {
    yield put(DashboardActions.getDashboarFailure());

    yield errorEffectsStatuses({ name, error });
  }
}

export default function* watchDeliveryRequests() {
  yield all([takeLeading(DashboardActions.getDashboar, getDashboardRequest)]);
}
