import Axios from 'axios';
import { API_ENDPOINT } from 'shared/constants/index';

Axios.defaults.baseURL = `${API_ENDPOINT}`;
Axios.interceptors.request.use(
  (config) => {
    !config.headers.Authorization && (config.headers.Authorization = localStorage.getItem('token'));

    return config;
  },
  (e) => {
    if (e.response?.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('expires_at');

      if (window.location.pathname !== '/authentication') {
        window.location.pathname = '/authentication';
      }
    }

    throw e;
  },
);
