import { call, put, all, takeLatest } from 'redux-saga/effects';
import { get } from 'shared/libs/request';
import * as actions from './actions';

function* getDeliveryRequestStatuses() {
  try {
    const { data } = yield call(get, '/delivery_request_statuses');

    yield put(actions.getDeliveryRequestStatusesSuccess(data));
  } catch (error) {
    yield put(actions.getDeliveryRequestStatusesFailure());
  }
}

export default function* watchDeliveryRequestStatuses() {
  yield all([takeLatest(actions.getDeliveryRequestStatuses, getDeliveryRequestStatuses)]);
}
