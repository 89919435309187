import React from 'react';

import { Button, Input, Modal, Typography } from 'antd';
import cn from 'classnames';

import styles from './ChangePhone.module.less';
import NumberFormat from 'react-number-format';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { twoFaType } from 'store/twoFa/actions';
import { useQuery } from 'shared/hooks';
import { State } from 'store';
import useCheckModal from 'shared/utils/checkModal';

const { Text } = Typography;

export const validationSchema = Yup.object({
  mobile_phone: Yup.string().required('Обязательное поле'),
});

type ChangePhoneProps = Readonly<{
  resetTimer?: () => void;
  textButton?: string;
  description?: string;
  okText?: string;
}>;

const ChangePhone: React.FC<ChangePhoneProps> = ({
  resetTimer,
  textButton = 'Изменить номер',
  description,
  okText,
}) => {
  const dispatch = useDispatch();
  const queryParams = useQuery();

  const [isModal, setModal] = React.useState(false);

  const oklVisible = () => setModal(true);
  const cancelVisible = () => setModal(false);

  const token = queryParams.get('token');
  const two_fa_uri = queryParams.get('two_fa_uri');
  const loading = useSelector((state: State) => state.loading.twoFaType);
  const error = useSelector((state: State) => state.error.twoFaType);

  useCheckModal({
    isModalVisible: isModal,
    loading,
    error,
    message: '',
    onClose: () => {
      resetTimer?.();
      cancelVisible();
    },
  });

  const { values, touched, errors, setFieldValue, handleBlur, handleSubmit, resetForm } = useFormik({
    initialValues: {
      mobile_phone: '',
    },
    validationSchema,
    onSubmit: ({ mobile_phone }) => {
      dispatch(
        twoFaType({
          type: 'sms',
          token,
          two_fa_uri,
          mobile_phone: mobile_phone ? `+7${mobile_phone}` : null,
        }),
      );
    },
  });

  React.useEffect(() => {
    isModal && resetForm();
  }, [isModal, resetForm]);

  return (
    <div className={cn(styles.ChangePhone)}>
      <Button type="link" onClick={oklVisible}>
        {textButton}
      </Button>
      <Modal
        visible={isModal}
        onCancel={cancelVisible}
        width={320}
        title="Изменить номер"
        onOk={handleSubmit as any}
        okButtonProps={{ loading: loading }}
        okText={okText}
      >
        <div>
          <NumberFormat
            placeholder="+7 (___) ___-__-__"
            name="mobile_phone"
            format="+7 (###) ###-##-##"
            mask="_"
            onBlur={handleBlur}
            value={values.mobile_phone}
            onValueChange={({ value }) => setFieldValue('mobile_phone', value, true)}
            customInput={Input}
          />
          {touched.mobile_phone && errors.mobile_phone && (
            <div>
              <Text type="danger">{errors.mobile_phone}</Text>
            </div>
          )}
          {description && (
            <div>
              <Text type="secondary" className={styles.Description}>
                {description}
              </Text>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ChangePhone;
