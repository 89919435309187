/* eslint-disable object-curly-newline */
/* eslint-disable camelcase */
import { call, put, all, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import Axios from 'axios';
import _ from 'lodash';

import { endEffectsStatuses, errorEffectsStatuses, startEffectsStatuses } from 'store/utils';

import * as actionsNotification from '../notifications/action';
import * as actions from './actions';

interface CreateAuthenticationProps {
  payload: {
    login: string;
    password: string;
  };
}

function* createAuthentication({ payload: { login, password } }: CreateAuthenticationProps) {
  const name = 'createAuthentication';

  try {
    yield startEffectsStatuses({ name });

    const { data } = yield call(Axios.request, {
      url: '/authentications',
      method: 'POST',
      data: {
        login,
        password,
      },
    });

    const { token, expires_at, two_fa_activated, two_fa_required, two_fa_uri, two_fa_type, two_fa_mobile_phone } = data;

    if (!two_fa_activated && two_fa_required) {
      yield put(
        push(
          `/authentication/choice_two_fa?token=${token}&two_fa_mobile_phone=${
            two_fa_mobile_phone || ''
          }&two_fa_uri=${two_fa_uri}`,
        ),
      );
    } else if (two_fa_activated && two_fa_required) {
      yield put(
        push(
          `/authentication/check_two_fa?token=${token}&two_fa_mobile_phone=${
            two_fa_mobile_phone || ''
          }&two_fa_uri=${two_fa_uri}&type=${two_fa_type === 'sms' ? 'smsAuth' : 'authenticatorAuth'}`,
        ),
      );
    } else {
      yield localStorage.setItem('token', `Bearer ${token}`);
      yield localStorage.setItem('expiresAt', expires_at);

      yield put(push(_.get(location, 'state.from.pathname', '/')));
    }

    yield put(actions.createAuthenticationSuccess());
    yield endEffectsStatuses({ name });
  } catch (error) {
    yield put(actions.createAuthenticationFailure());
    yield put(
      actionsNotification.SetNotifications({
        variant: 'error',
        message: error.response.status,
        description: error.response.data?.error,
      }),
    );
    yield errorEffectsStatuses({ name, error });
  }
}

function* deleteAuthentication() {
  try {
    yield call(Axios.delete, '/authentications');

    yield localStorage.removeItem('token');

    yield localStorage.removeItem('expiresAt');

    yield put(actions.deleteAuthenticationSuccess());

    yield put(push('/authentication'));

    yield put(actions.destroySession());
  } catch (error) {
    yield put(actions.deleteAuthenticationFailure('Strange Error'));
  }
}

export default function* watchUsers() {
  yield all([
    takeLatest(actions.createAuthentication, createAuthentication),
    takeLatest(actions.deleteAuthentication, deleteAuthentication),
  ]);
}
