import { createAction } from 'redux-actions';

export const getUser = createAction('GET_USER');

export const getUserSuccess = createAction('GET_USER_SUCCESS');

export const getUserFailure = createAction('GET_USER_FAILURE');

export const getUsers = createAction('GET_USERS');

export const getUsersSuccess = createAction('GET_USERS_SUCCESS');

export const getUsersFailure = createAction('GET_USERS_FAILURE');

export const createUser = createAction('CREATE_USER');

export const createUserSuccess = createAction('CREATE_USER_SUCCESS');

export const createUserFailure = createAction('CREATE_USER_FAILURE');

export const updateUser = createAction('UPDATE_USER');

export const updateUserSuccess = createAction('UPDATE_USER_SUCCESS');

export const updateUserFailure = createAction('UPDATE_USER_FAILURE');

export const blockUser = createAction('BLOCK_USER');

export const blockUserSuccess = createAction('BLOCK_USER_SUCCESS');

export const blockUserFailure = createAction('BLOCK_USER_FAILURE');

export const unblockUser = createAction('UNBLOCK_USER');

export const unblockUserSuccess = createAction('UNBLOCK_USER_SUCCESS');

export const unblockUserFailure = createAction('UNBLOCK_USER_FAILURE');

export const resetPasswordUser = createAction('RESET_PASSWORD_USER');

export const resetPasswordUserSuccess = createAction('RESET_PASSWORD_USER_SUCCESS');

export const resetPasswordUserFailure = createAction('RESET_PASSWORD_USER_FAILURE');

export const activateTwoFaUser = createAction('ACTIVATE_TWO_FA_USER');
export const activateTwoFaUserSuccess = createAction('ACTIVATE_TWO_FA_USER_SUCCESS');
export const activateTwoFaUserFailure = createAction('ACTIVATE_TWO_FA_USER_FAILURE');

export const disableTwoFaUser = createAction('DISABLE_TWO_FA_USER');
export const disableTwoFaUserSuccess = createAction('DISABLE_TWO_FA_USER_SUCCESS');
export const disableTwoFaUserFailure = createAction('DISABLE_TWO_FA_USER_FAILURE');

export const unlockIntrusionTwoFaUser = createAction('UNLOCK_INTRUSION_TWO_FA_USER');
export const unlockIntrusionTwoFaUserSuccess = createAction('UNLOCK_INTRUSION_TWO_FA_USER_SUCCESS');
export const unlockIntrusionTwoFaUserFailure = createAction('UNLOCK_INTRUSION_TWO_FA_USER_FAILURE');
