import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';
import * as actions from './actions';

interface PhoneCallHistory {
  answered_at?: number;
  direction: 'out' | 'in';
  duration?: number;
  talk_duration?: number | null;
  created_at?: number;
  id: number;
  operator_kind: 'nobody' | 'agent' | 'operator';
  status: string;
  terminated_at: number;
}

export interface PhoneCallHistoryState {
  byId: {
    [key: string]: PhoneCallHistory;
  };
  allIds: string[];
}

const initialState: PhoneCallHistoryState = {
  byId: {},
  allIds: [],
};

interface Action {
  payload: {
    attributes: Omit<PhoneCallHistory, 'id'>;
    id: string;
    type: string;
  }[];
}

const byId = handleActions(
  {
    [combineActions(actions.getPhoneCallHistorySuccess).toString()]: (state, { payload }: Action) => ({
      ...payload.reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: { ...item.attributes },
        }),
        {},
      ),
    }),
  },
  initialState.byId,
);

const allIds = handleActions(
  {
    [combineActions(actions.getPhoneCallHistorySuccess).toString()]: (state, { payload }: Action) => [
      ...new Set([...payload.map(({ id }: any) => id)]),
    ],
  },
  initialState.allIds,
);

export default combineReducers({
  byId,
  allIds,
});
