import React from 'react';

import { Select, SelectProps } from 'antd';
import cn from 'classnames';

import styles from './SelectCustomerSchedulingKind.module.less';

export enum CustomerSchedulingKindTypeEnum {
  slots = 'Слоты',
  precise = 'Точное время',
  both = 'Слоты или точное время',
}

export type CustomerSchedulingKindType = 'slots' | 'precise' | 'both';

const { Option } = Select;

type SelectCustomerSchedulingKindProps<T = any> = SelectProps<T> & {
  value?: CustomerSchedulingKindType;
  onChange?: (id: CustomerSchedulingKindType) => void;
  onBlur?: React.FocusEventHandler<HTMLElement>;
};

const SelectCustomerSchedulingKind: React.FC<SelectCustomerSchedulingKindProps> = ({
  value,
  onChange,
  onBlur,
  placeholder = 'Точное время на клиентском согласовании',
}) => {
  return (
    <Select
      showSearch
      className={cn(styles.SelectCustomerSchedulingKind)}
      filterOption={false}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      allowClear
      onBlur={onBlur}
    >
      {Object.entries(CustomerSchedulingKindTypeEnum)?.map(([id, name]) => (
        <Option value={id} key={id}>
          {name}
        </Option>
      ))}
    </Select>
  );
};

export default SelectCustomerSchedulingKind;
