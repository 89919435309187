import React, { FC, memo, useMemo } from 'react';
import { Empty, Image, Tooltip, Typography } from 'antd';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import _ from 'lodash';

import { selectPhotoTypes } from 'store/photoTypes/selectors';

import styles from './List.module.less';
import { selectDeliveries } from 'store/deliveries/selectors';

const { Text } = Typography;

const List: FC<{ photos: any[] }> = () => {
  const state = useSelector((state) => state);

  const photoTypes = useMemo(() => selectPhotoTypes(state), [state]);

  const photos = useMemo(() => _(selectDeliveries(state)).values().map('photos').flatMap().value(), [state]);

  const photoList = useMemo(() => {
    if (photos?.length > 0) {
      return (
        <div className={styles.Photos}>
          <Image.PreviewGroup>
            {photos.map(({ type, thumb_url, url }) => (
              <div key={url} className={styles.Photo}>
                <Image
                  src={thumb_url}
                  preview={{
                    src: url,
                  }}
                  alt={type}
                  className={styles.Image}
                  role="presentation"
                />
                <Tooltip title={photoTypes[type]?.name || 'Фото'}>
                  <Text type="secondary">{photoTypes[type]?.name || 'Фото'}</Text>
                </Tooltip>
              </div>
            ))}
          </Image.PreviewGroup>
        </div>
      );
    }
    return <Empty description={false} />;

    // eslint-disable-next-line
  }, [JSON.stringify(photos), JSON.stringify(photoTypes)]);

  return <div className={cn(styles.List)}>{photoList}</div>;
};

export default memo(List);
