import React, { FC } from 'react';
import { useHistory } from 'react-router';
import cn from 'classnames';
import { Tag } from 'antd';

import styles from './ItemWrapper.module.less';

export interface ItemWrapperProps {
  color: string;
  label: string;
  value: number;
  key: string;
  status: string;
}

const ItemWrapper: FC<ItemWrapperProps> = ({ color, label, value, status }) => {
  const hisory = useHistory();

  return (
    <div className={cn(styles.ItemWrapper)} key={label}>
      <div>
        <Tag
          color={color}
          className={styles.Tag}
          onClick={() => hisory.push(`/delivery/requests/list?status=${status}&page=1`)}
        >
          {label}
        </Tag>
      </div>
      <div className={styles.Text} onClick={() => hisory.push(`/delivery/requests/list?status=${status}&page=1`)}>
        {value}
      </div>
    </div>
  );
};

export default ItemWrapper;
