import { InboxOutlined } from '@ant-design/icons';
import { Card, message, Upload } from 'antd';
import cn from 'classnames';
import { deliveryRequestStatusesLoadedCounter } from 'pages/DeliveryRequestsShow/DeliveryRequestsShow';
import React, { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ShowDocuments from 'shared/components/ShowDocuments';
import { API_ENDPOINT } from 'shared/constants';
import { selectDeliveryRequest } from 'store/deliveryRequests/selectors';
import { addDocumentsDeliveryRequestSuccess, deleteDocumentsDeliveryRequest } from 'store/documents/actions';
import { selectDocuments } from 'store/documents/selectors';
import { State } from 'store/index';
import styles from './Documents.module.less';

const { Dragger } = Upload;

interface IDocuments {
  className?: string;
}

const Documents: FC<IDocuments> = ({ className }) => {
  const dispatch = useDispatch();

  const { id }: { id: string } = useParams();

  const { isEverythigReady } = deliveryRequestStatusesLoadedCounter.useContainer();

  const state = useSelector((state: State) => state);

  const documents = Object.values(selectDocuments(state));
  const deliveryRequest = useMemo(() => selectDeliveryRequest(id)(state), [state, id]);

  const headers = useMemo(() => {
    const token = localStorage.getItem('token');
    return token ? { Authorization: `${token}` } : {};
  }, []);

  const draggerProp = useMemo(
    () => ({
      name: 'file',
      multiple: true,
      action: `${API_ENDPOINT}/delivery_requests/${id}/document`,
      headers,
      onChange(info: any) {
        const { status } = info.file;

        if (status === 'done') {
          dispatch(addDocumentsDeliveryRequestSuccess(info.file.response));
          message.success(`${info.file.name} файл успешно загружен.`);
        } else if (status === 'error') {
          message.error(`${info.file.name} загрузка файла не удалась.`);
        }
      },
      beforeUpload: (file: File) => {
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
          message.error('Максимальный размер файла 10mb!');
        }
        return isLt2M;
      },
    }),
    [id, dispatch, headers],
  );

  return (
    <Card title="Документы" className={cn(styles.Documents, className)} loading={!isEverythigReady} id="documents">
      <ShowDocuments
        data={documents}
        deletedButton={{ onClick: ({ id }) => dispatch(deleteDocumentsDeliveryRequest({ id })) }}
      />
      {['validated', 'rejected', 'executed'].includes(deliveryRequest?.status) && (
        <Dragger {...(draggerProp as any)} accept=".pdf, .docx" className={styles.Dragger}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Нажмите или перетащите файл сюда, чтобы загрузить его</p>
          <p className="ant-upload-hint">Разрешеныe форматы：.pdf, .docx</p>
        </Dragger>
      )}
    </Card>
  );
};

export default Documents;
