import cn from 'classnames';
import React from 'react';
import styles from './ErrorLine.module.less';

interface ErrorLineProps {
  str?: string;
}

const ErrorLine: React.FC<ErrorLineProps> = ({ str, children }) => {
  return <span className={cn(styles.ErrorLine)}>{str || children || ''}</span>;
};

export default ErrorLine;
