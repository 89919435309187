import React, { memo } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Button, Card, Tooltip } from 'antd';
import cn from 'classnames';

import styles from './Profile.module.less';
import { useHistory } from 'react-router';

const Profile = () => {
  const history = useHistory();

  return (
    <Tooltip title="Профиль">
      <Card className={cn(styles.Profile)} onClick={() => history.push('/profile')}>
        <Button type="link" className={cn(styles.Button)}>
          <UserOutlined />
        </Button>
      </Card>
    </Tooltip>
  );
};

export default memo(Profile);
