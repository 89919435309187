import _ from 'lodash';
import qs from 'qs';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebounceValue, usePrevious } from '.';

type UseUpdateFiltersProps<T> = {
  setFilters: Dispatch<SetStateAction<Readonly<T>>>;
  path: string;
};

export const useUpdateFilters = <T>({
  setFilters,
  path,
}: UseUpdateFiltersProps<T>): Dispatch<SetStateAction<Readonly<T>>> => {
  const history = useHistory();
  const [query, setQuery] = React.useState<T>();
  const debQuery = useDebounceValue(query, 600);
  const debQueryPrev = usePrevious(debQuery);

  useEffect(() => {
    const queryPrev = qs.stringify(_.omit(debQueryPrev, 'include'), {
      encode: false,
      arrayFormat: 'brackets',
    });
    const query = qs.stringify(_.omit(debQuery as T & { include?: string[] | undefined }, 'include'), {
      encode: false,
      arrayFormat: 'brackets',
    });

    queryPrev !== query && history.push(`${path}?${query}`);

    // eslint-disable-next-line
  }, [debQuery, history, path]);

  return (v: T | ((prev: T) => T)) => {
    let query: T;

    setFilters((prev) => {
      if (typeof v === 'function') {
        query = (v as (prev: T) => T)(prev);
      } else {
        query = v;
      }
      return query;
    });
    setQuery(query!);
  };
};
