import { ConfigProvider } from 'antd';
import antdRu from 'antd/es/locale/ru_RU';
import moment from 'moment';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router';
import 'shared/libs/axios';
import { Authentication, PartnerCabinet } from './pages';
import checkAuthentication from './shared/utils/checkAuthentication';

moment.locale('ru');

const App = () => (
  <ConfigProvider locale={antdRu}>
    <Helmet title="Fastriver" />

    <Switch>
      <Route path="/authentication">
        <Authentication />
      </Route>
      <Route path="/" render={checkAuthentication(<PartnerCabinet />)} />
    </Switch>
  </ConfigProvider>
);

export default App;
