import React from 'react';
import styles from './SelectCompanyName.module.less';
import { Input, Typography } from 'antd';
import { SetFieldValue } from '../../useGenFormik';
import { useInitialUpdateField } from 'pages/DeliveryRequest/utils';

type SelectCompanyNameProps = Readonly<{
  value?: string;
  setFieldValue: SetFieldValue;
  configBlur: any;
  touched?: boolean;
  error?: string;
}>;

const SelectCompanyName: React.FC<SelectCompanyNameProps> = ({ value, setFieldValue, configBlur, touched, error }) => {
  const onBlurCompanyName = useInitialUpdateField({
    name: 'company_name',
    updateMessage: 'Организация',
    ...configBlur,
  });

  return (
    <div className={styles.SelectCompanyName}>
      <Input
        placeholder="Организация"
        name="company_name"
        value={value}
        onChange={(e) => setFieldValue('company_name', e.target.value, true)}
        onBlur={onBlurCompanyName}
        className={styles.Select}
      />
      {touched && error && <Typography.Text type="danger">{error}</Typography.Text>}
    </div>
  );
};

export default SelectCompanyName;
