import { Typography } from 'antd';
import { useSetDelivery } from '../../../../utils';
import React from 'react';
import AutoCompleteFio from 'shared/components/AutoCompleteFio';
import { InitialValues, SetFieldValue } from '../../useGenFormik';
import { useParams } from 'react-router';

import styles from './SelectFullName.module.less';

type SelectFullNameProps = Readonly<{
  value?: string;
  values?: InitialValues;
  setFieldValue: SetFieldValue;
  configBlur: any;
  touched?: boolean;
  error?: string;
  onBlur?: () => void;
}>;

const SelectFullName: React.FC<SelectFullNameProps> = ({ value, error, touched, setFieldValue, onBlur }) => {
  const { id } = useParams<{ id: string }>();

  const setDelivery = useSetDelivery();

  const onBlurFullName = React.useCallback(() => {
    onBlur?.();

    const [lastName, firstName, patronymic] = value?.split(' ') || [];

    (firstName || lastName) &&
      setDelivery({
        data: {
          lastName: lastName?.trim() || null,
          firstName: firstName?.trim() || null,
          patronymic: patronymic?.trim() || null,
          id,
        },
        updateMessage: 'ФИО',
      });
  }, [id, setDelivery, value, onBlur]);

  return (
    <div className={styles.SelectFullName}>
      <AutoCompleteFio
        value={value}
        onChange={(value) => {
          const [lastName, firstName, patronymic] = value?.split(' ') || [];

          setFieldValue('last_name', lastName, true);
          setFieldValue('first_name', firstName, true);
          setFieldValue('patronymic', patronymic, true);
          setFieldValue('fulName', value, true);
        }}
        onBlur={onBlurFullName}
        className={styles.Select}
      />
      {touched && error && <Typography.Text type="danger">{error}</Typography.Text>}
    </div>
  );
};

export default SelectFullName;
