import Axios from 'axios';
import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as actions from './actions';

import { endEffectsStatuses, errorEffectsStatuses, startEffectsStatuses } from 'store/utils';

function* getUserRoles() {
  const name = 'getUserRoles';

  try {
    yield startEffectsStatuses({ name });

    const {
      data: { data: userRoles },
    } = yield call(Axios.get, '/user_roles');

    yield put(actions.getUserRolesSuccess(userRoles));

    yield endEffectsStatuses({ name });
  } catch (error) {
    yield put(actions.getUserRolesFailure());

    errorEffectsStatuses({ name, error });
  }
}

export default function* watchUserRoles() {
  yield all([takeLatest(actions.getUserRoles, getUserRoles)]);
}
