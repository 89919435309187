import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

export type City = Readonly<{
  id: string;
  code: string;
  name: string;
  where_name: string;
  where_go_name: string;
  lat: number;
  lon: number;
  work_start: number;
  work_end: number;
  region?: null | string;
}>;

export type CitiesState = Readonly<{
  byId: { [key: string]: City };
  allIds: string[];
}>;

const initialState: CitiesState = {
  byId: {},
  allIds: [],
};

interface Action {
  payload: {
    attributes: Omit<City, 'id'>;
    id: string;
    type: string;
  }[];
}

const byId = handleActions(
  {
    [actions.getCitiesSuccess.toString()]: (state, { payload }: Action) => ({
      ...state,
      ...payload.reduce(
        (acc, city) => ({
          ...acc,
          [city.id]: { id: city.id, ...city.attributes },
        }),
        {},
      ),
    }),
  },
  initialState.byId,
);

const allIds = handleActions(
  {
    [actions.getCitiesSuccess.toString()]: (state, { payload }: any) => [
      ...new Set([...state, ...payload.map(({ id }: any) => id)]),
    ],
  },
  initialState.allIds,
);

export default combineReducers({
  byId,
  allIds,
});
