import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';
import * as actions from './actions';

interface ListOfChanges {
  date: string;
  version: string;
  change?: string[];
  add?: string[];
}

export interface ListOfChangesState {
  data: ListOfChanges[];
}

const initialState: ListOfChangesState = {
  data: [],
};

interface Action {
  payload: ListOfChanges[];
}

const data = handleActions(
  {
    [combineActions(actions.getListOfChangesSuccess).toString()]: (state: ListOfChanges[], { payload }: Action) =>
      payload,
  },
  initialState.data,
);

export default combineReducers({
  data,
});
