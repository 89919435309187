import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import { Button } from 'antd';
import { SettingOutlined } from '@ant-design/icons';

import EditModal from './components/EditModal';

import styles from './Password.module.less';

const Password = () => {
  const [isModalVisible, setVisible] = useState(false);

  const handlerEdit = useCallback(() => setVisible(true), []);

  return (
    <div className={cn(styles.Password)}>
      <Button className={styles.Button} onClick={handlerEdit}>
        <SettingOutlined className={styles.Icon} /> Изменить пароль
      </Button>
      <EditModal {...{ isModalVisible, setVisible }} />
    </div>
  );
};

export default Password;
