import React, { FC, memo, useCallback, useMemo, useState, useEffect } from 'react';
import { SaveOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Input, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import cn from 'classnames';
import * as Yup from 'yup';

import WrapperField from 'shared/components/WrapperField';
import { getUser, updateUser } from 'store/users/actions';
import useCheckModal from 'shared/utils/checkModal';
import { User } from 'store/users/reducers';
import { State } from 'store';

import styles from './Email.module.less';

const { Text } = Typography;

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required('Обязательное поле'),
});

interface EmailProps {
  user: User;
}

const Email: FC<EmailProps> = ({ user }) => {
  const dispatch = useDispatch();
  const [isModalVisible, setVisible] = useState(false);

  const handlerEdit = useCallback(() => setVisible(true), []);

  const loading = useSelector((state: State) => state.loading.updateUser);
  const error = useSelector((state: State) => state.error.updateUser);

  useCheckModal({
    isModalVisible,
    loading,
    error,
    onClose: () => {
      setVisible(false);
      dispatch(getUser({ id: user.id }));
    },
  });

  const initialValues = useMemo(
    () => ({
      email: user?.email || '',
    }),
    [user],
  );

  const { touched, errors, values, handleChange, handleBlur, setValues, handleSubmit, resetForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      dispatch(
        updateUser({
          id: user.id,
          ...values,
        }),
      );
    },
  });

  const value = useMemo(() => {
    if (isModalVisible)
      return (
        <div className={styles.EditPanel}>
          <Input
            name="email"
            placeholder="Email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            autoComplete="off"
          />
          <Button type="link" onClick={() => handleSubmit()} loading={loading}>
            {!loading && <SaveOutlined style={{ fontSize: '24px' }} />}
          </Button>
          <Button type="link" onClick={() => setVisible(false)} loading={loading}>
            {!loading && <CloseOutlined style={{ fontSize: '24px', color: 'rgb(140 140 140)' }} />}
          </Button>
          {touched.email && errors.email && <Text type="danger">{errors.email}</Text>}
        </div>
      );

    return user.email;
  }, [
    isModalVisible,
    errors.email,
    handleBlur,
    handleChange,
    handleSubmit,
    touched.email,
    user.email,
    values.email,
    loading,
  ]);

  useEffect(() => {
    isModalVisible && resetForm();
    isModalVisible && setValues(initialValues);
  }, [isModalVisible, setValues, initialValues, resetForm]);

  return (
    <div className={cn(styles.Email)}>
      <WrapperField value={value} title="Email" handlerEdit={isModalVisible ? undefined : handlerEdit} />
    </div>
  );
};

export default memo(Email);
