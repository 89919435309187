import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useSetDelivery } from 'pages/DeliveryRequest/utils';
import { Radio, Spin, Typography, Switch, Select } from 'antd';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import cn from 'classnames';
import _ from 'lodash';
import { format as formatDateFns } from 'date-fns';

import { getTimeIntervals } from 'store/timeIntervals/actions';
import { State } from 'store';

import styles from './SelectTimeInterval.module.less';
import { selectDeliveryRequest } from 'store/deliveryRequests/selectors';

const { Text } = Typography;

interface SelectTimeIntervalProps {
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  values: any;
  touched?: boolean;
  error?: string;
  product?: string;
}

const SelectTimeInterval: FC<SelectTimeIntervalProps> = ({ values, setFieldValue, touched, error, product }) => {
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();

  const state = useSelector((state: State) => state);
  const deliveryRequest = selectDeliveryRequest(id)(state);
  const uuid = deliveryRequest?.uuid;
  const timeIntervals = state.timeIntervals.byId;
  const loading = state.loading.timeIntervals || state.loading.deliveryRequests;
  const currentUser = state.profile.data;

  const setDelivery = useSetDelivery();

  const switchExactInterval = React.useMemo(() => {
    const isExactInterval =
      currentUser?.partner_settings?.precise_delivery &&
      currentUser?.partner_settings?.schedules_self &&
      currentUser?.partner_settings?.selects_time;

    if (isExactInterval) {
      const onChange = () => {
        setDelivery({
          data: {
            precise: !values?.precise,
            time_interval_id: null,
            id,
          },
          updateMessage: 'Время доставки',
        });
        setFieldValue?.('precise', !values?.precise, true);
        setFieldValue?.('time_interval_id', undefined, true);
      };

      return (
        <div className={styles.SwitchExactInterval}>
          <Switch
            disabled={!deliveryRequest?.address || !deliveryRequest?.date || !!deliveryRequest?.zone_weight}
            checked={values?.precise}
            onChange={onChange}
          />{' '}
          К точному времени
        </div>
      );
    }

    return null;
  }, [
    currentUser?.partner_settings?.precise_delivery,
    currentUser?.partner_settings?.schedules_self,
    currentUser?.partner_settings?.selects_time,
    deliveryRequest?.zone_weight,
    deliveryRequest?.address,
    deliveryRequest?.date,
    values?.precise,
    setDelivery,
    id,
    setFieldValue,
  ]);

  const memoTimeIntervals = useMemo(() => _.values(timeIntervals), [timeIntervals]);

  const options = useMemo(
    () =>
      memoTimeIntervals?.map(({ attributes: { name, from, to }, id }) => ({
        label: name,
        value: id,
        timeText: formatDateFns(
          new Date(new Date('1900-01-01 00:00:00').valueOf() + Math.ceil((from + to) / 2) * 60 * 1000),
          'HH:mm',
        ),
      })),
    [memoTimeIntervals],
  );

  useEffect(() => {
    if (deliveryRequest?.address && deliveryRequest.date && uuid) {
      dispatch(getTimeIntervals({ uuid }));
    }

    // eslint-disable-next-line
  }, [
    deliveryRequest?.address,
    deliveryRequest?.date,
    uuid,
    dispatch,
    deliveryRequest?.precise,
    deliveryRequest?.product,
  ]);

  const handlerTimeIntervalIdChange = useCallback(
    (v: string) => {
      setFieldValue?.('time_interval_id', v, true);
      setFieldValue?.('time', null, true);
      setDelivery({
        data: {
          time_interval_id: v,
          id,
        },
        updateMessage: 'Время доставки',
      });
    },
    [setFieldValue, id, setDelivery],
  );
  const handlerTimeChange = useCallback(
    (v: string) => {
      setFieldValue?.('time_interval_id', v, true);
      setFieldValue?.('time', v, true);
      setDelivery({
        data: {
          time_interval_id: v,
          id,
        },
        updateMessage: 'Время доставки',
      });
    },
    [setFieldValue, id, setDelivery],
  );

  const field = useMemo(() => {
    if (deliveryRequest?.precise === true && options.length && !deliveryRequest?.zone_weight)
      return (
        <Select
          placeholder="Выберите доступное время"
          value={values?.time_interval_id?.toString()}
          onChange={handlerTimeChange}
          className={styles.Select}
        >
          {options.map(({ value, timeText }) => (
            <Select.Option key={value} value={value?.toString()}>
              {timeText}
            </Select.Option>
          ))}
        </Select>
      );

    if (!deliveryRequest?.address || !deliveryRequest?.date)
      return <Text type="secondary">📇 Для выбора времени необходимо выбрать Дату и Адрес</Text>;

    if (memoTimeIntervals.length === 0)
      return <Text type="danger">🙅‍♂️ К сожалению, в этот день доставка невозможна</Text>;

    return (
      <div className={cn(styles.SelectTimeInterval)}>
        <Radio.Group
          options={options}
          value={values?.time_interval_id?.toString()}
          optionType="button"
          buttonStyle="solid"
          onChange={(e) => handlerTimeIntervalIdChange(e.target.value)}
        />
      </div>
    );
  }, [deliveryRequest, memoTimeIntervals, options, values, handlerTimeChange, handlerTimeIntervalIdChange]);

  useEffect(() => {
    !!values.time_interval_id && handlerTimeChange('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, handlerTimeChange]);

  if (currentUser?.partner_settings?.schedules_self && currentUser?.partner_settings?.selects_time) {
    return (
      <>
        {switchExactInterval}
        {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
        {!loading && field}
        {!loading && <div>{touched && error && <Text type="danger">{error}</Text>}</div>}
      </>
    );
  }

  return null;
};

export default memo(SelectTimeInterval);
