import React, { useEffect, useMemo, useState } from 'react';
import { Map as YandexMap, Placemark, YMaps, Polygon } from 'react-yandex-maps';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { State } from 'store';
import cn from 'classnames';

import { getCitieAreas } from 'store/citieAreas/actions';

import styles from './Map.module.less';

const Map = () => {
  const dispatch = useDispatch();

  const { id }: { id: string } = useParams();

  const [mapRef, setMapRef] = useState<any>(null);

  const { destination } = useSelector((state: State) => state.deliveries.byId[state.deliveries.data?.activeId] || {});
  const { delivery_city, coordination } = useSelector((state: State) => state.deliveryRequests.byId[id] || {});
  const cityAreas = useSelector((state: State) => state.cityAreas.byId[delivery_city]);
  const city = useSelector((state: State) => state.cities.byId[delivery_city]);

  const { lat, lon } = destination || {};

  // Выставляем центр карты
  const cityCenter = useMemo(() => [city?.lat || 55.76, city?.lon || 37.64], [city]);

  // Выставляем маркер
  const mark = useMemo(() => {
    if (!(lat && lon) && !(coordination?.lat && coordination?.lon)) return;

    return [lat || coordination.lat, lon || coordination.lon];
  }, [lat, lon, coordination]);

  // Выставляем полигоны
  const geometry = useMemo(
    () => cityAreas?.border?.map((str) => JSON.parse(str.replace(/\(/g, '[').replace(/\)/g, ']'))) || [],
    [cityAreas],
  );

  useEffect(() => {
    if (mapRef && cityCenter && !mark) {
      mapRef
        .panTo([cityCenter], {
          duration: 0,
        })
        .then(() => {
          mapRef.setZoom(10, { duration: 0 });
        });
    }

    // eslint-disable-next-line
  }, [cityCenter, mapRef]);

  useEffect(() => {
    if (mapRef && mark) {
      mapRef
        .panTo([mark], {
          duration: 0,
        })
        .then(() => {
          mapRef.setZoom(16, { duration: 0 });
        });
    }
  }, [mark, mapRef]);

  // получаем полигоны с сервера
  useEffect(() => {
    delivery_city && dispatch(getCitieAreas({ city_code: delivery_city }));
  }, [delivery_city, dispatch]);

  return (
    <div className={cn(styles.Map)}>
      <YMaps>
        <YandexMap
          defaultState={{ center: cityCenter || [55.76, 37.64], zoom: 10 }}
          width="100%"
          height="500px"
          instanceRef={setMapRef}
          options={{
            suppressMapOpenBlock: true,
            suppressObsoleteBrowserNotifier: true,
          }}
        >
          <Polygon
            geometry={geometry}
            options={{
              fillColor: styles.primaryColor,
              opacity: 0.2,
              strokeColor: styles.primaryColor,
              strokeOpacity: 0.2,
            }}
          />
          {mark && (
            <Placemark
              geometry={mark}
              options={{
                iconLayout: 'default#image',
                iconImageHref: 'https://files.fastriver.ru/ui/pin.svg',
                // Размеры метки.
                iconImageSize: [35, 35],
                iconImageOffset: [-17.5, -35],
              }}
            />
          )}
        </YandexMap>
      </YMaps>
    </div>
  );
};

export default Map;
