import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';
import { Typography } from 'antd';
import cn from 'classnames';
import Axios from 'axios';
import _ from 'lodash';

import { twoFaCheck } from 'store/twoFa/actions';
import { SetNotifications } from 'store/notifications/action';
import ReturnCard from 'shared/components/ReturnCard';
import { useQuery } from 'shared/hooks';
import { useFormik } from 'formik';

import { initialValues, validationSchema } from './utils';
import QrCode from './components/QrCode';

import styles from './CheckTwoFa.module.less';
import Sms from './components/Sms';

const { Text } = Typography;

const CheckTwoFa = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useQuery();
  const location = useLocation();

  const token = queryParams.get('token');
  const type = queryParams.get('type');
  const twoFaActivated = queryParams.get('two_fa_activated');

  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const { values, touched, errors, setFieldTouched, setFieldValue, resetForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ code }) => {
      dispatch(twoFaCheck({ code: `${code}`, token }));
    },
  });

  React.useEffect(() => {
    if (values.code.length === 6 && !errors.code && !loading) {
      setFieldValue('code', '', false);
      setLoading(true);

      Axios.request({
        url: '/authentications/check_otp',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        data: { code: values.code },
      })
        .then(({ data: { token, expires_at } }) => {
          localStorage.setItem('token', `Bearer ${token}`);
          localStorage.setItem('expiresAt', expires_at);

          history.push(_.get(location, 'state.from.pathname', '/'));

          twoFaActivated === 'true' &&
            dispatch(
              SetNotifications({
                variant: 'success',
                message: 'Двухфакторная аутентификация активирована',
              }),
            );
        })
        .catch((err) => setError(err?.response?.data?.error))
        .finally(() => setLoading(false));
    }

    // eslint-disable-next-line
  }, [values.code.length]);

  React.useEffect(() => {
    (!token || !type) &&
      location.pathname === '/authentication/check_two_fa' &&
      history.push('/authentication', { from: '/authentication/check_two_fa' });
  }, [token, location.pathname, history, type]);

  React.useEffect(() => resetForm(), [location.pathname, resetForm]);

  return (
    <div className={cn({ [styles.Hiden]: location.pathname !== '/authentication/check_two_fa' }, styles.Show)}>
      <ReturnCard
        title={'Активируйте двухфакторную аутентификацию'}
        backUrl={twoFaActivated === 'true' ? `/authentication/choice_two_fa${location.search}` : '/authentication'}
        subTitle={
          <>
            <QrCode />
            <Sms />
          </>
        }
      >
        <div className={cn(styles.CheckTwoFa)}>
          <NumberFormat
            className={cn(styles.Input)}
            value={values.code.toString()}
            allowEmptyFormatting
            onBlur={() => setFieldTouched('code', true)}
            onValueChange={(v) => {
              setFieldValue('code', v.value, true);
              setError('');
            }}
            format={'# # # # # #'}
            mask={'_'}
            placeholder="Код  # # # # # #"
            disabled={loading}
          />
          <div>{((touched.code && errors.code) || error) && <Text type="danger">{error || errors.code}</Text>}</div>
        </div>
      </ReturnCard>
    </div>
  );
};

export default CheckTwoFa;
