import { createAction } from 'redux-actions';

export const twoFaType = createAction('TWO_FA_TYPE');
export const twoFaTypeSuccess = createAction('TWO_FA_TYPE_SUCCESS');
export const twoFaTypeFailure = createAction('TWO_FA_TYPE_FAILURE');

export const twoFaCheck = createAction('TWO_FA_CHECK');
export const twoFaCheckSuccess = createAction('TWO_FA_CHECK_SUCCESS');
export const twoFaCheckFailure = createAction('TWO_FA_CHECK_FAILURE');

export const twoFaSendOtp = createAction('TWO_FA_SEND_OTP');
export const twoFaSendOtpSuccess = createAction('TWO_FA_SEND_OTP_SUCCESS');
export const twoFaSendOtpFailure = createAction('TWO_FA_SEND_OTP_FAILURE');
