import React from 'react';
import styles from './SelectEmail.module.less';
import { Input, Typography } from 'antd';
import { SetFieldValue } from '../../useGenFormik';
import { useInitialUpdateField } from 'pages/DeliveryRequest/utils';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { State } from 'store';
import { selectDeliveryRequest } from 'store/deliveryRequests/selectors';

type SelectPhoneProps = Readonly<{
  value?: string;
  setFieldValue: SetFieldValue;
  configBlur: any;
  touched?: boolean;
  error?: string;
}>;

const SelectEmail: React.FC<SelectPhoneProps> = ({ value, setFieldValue, configBlur, touched, error }) => {
  const { id } = useParams<{ id: string }>();

  const state = useSelector((state: State) => state);

  const deliveryRequest = selectDeliveryRequest(id)(state);

  const onBlurEmail = useInitialUpdateField({
    name: 'email',
    updateMessage: 'Email',
    ...configBlur,
  });

  return (
    <div className={styles.SelectEmail}>
      <Input
        placeholder="Email"
        name="email"
        value={value}
        onChange={(e) => setFieldValue('email', e.target.value, true)}
        onBlur={onBlurEmail}
      />
      {deliveryRequest?.destination?.email && (
        <div>
          <Typography.Text type="secondary" className={styles.Hint}>
            {deliveryRequest?.destination?.email}
          </Typography.Text>
        </div>
      )}
      {touched && error && <Typography.Text type="danger">{error}</Typography.Text>}
    </div>
  );
};

export default SelectEmail;
