import React, { useState } from 'react';
import { MessageOutlined } from '@ant-design/icons';
import { Button, Card, Tooltip } from 'antd';
import cn from 'classnames';

import FeedBackShared from 'shared/components/FeedBack';

import styles from './FeedBack.module.less';

const FeedBack = () => {
  const [isVisibleFeedBack, setVisibleFeedBack] = useState(false);

  return (
    <Tooltip title="Обратная связь">
      <FeedBackShared visible={isVisibleFeedBack} onCancel={() => setVisibleFeedBack(false)} />
      <Card className={cn(styles.FeedBack)} onClick={() => setVisibleFeedBack(true)}>
        <Button type="link" className={cn(styles.Button)}>
          <MessageOutlined />
        </Button>
      </Card>
    </Tooltip>
  );
};

export default FeedBack;
