import { call, put, all, takeLeading } from 'redux-saga/effects';
import { get, del } from 'shared/libs/request';
import filter from 'shared/utils/qsFilter';
import qs from 'qs';

import * as actions from './actions';

interface IGetDocumentsDeliveryRequest {
  payload: {
    id: string;
  };
}

function* getDocumentsDeliveryRequest({ payload: { id } }: IGetDocumentsDeliveryRequest) {
  try {
    const query = qs.stringify({ filter: { delivery_request_uuid: id } }, { filter });

    const { data, meta } = yield call(get, `/package_documents?${query}`);

    yield put(actions.getDocumentsDeliveryRequestSuccess({ data, meta }));
  } catch (error) {
    yield put(actions.getDocumentsDeliveryRequestFailure({ id }));
  }
}

function* deleteDocumentsDeliveryRequest({ payload: { id } }: IGetDocumentsDeliveryRequest) {
  try {
    yield call(del, `/package_documents/${id}`);

    yield put(actions.deleteDocumentsDeliveryRequestSuccess({ id }));
  } catch (error) {
    yield put(actions.deleteDocumentsDeliveryRequestFailure({ id }));
  }
}

export default function* watchDeliveryRequests() {
  yield all([takeLeading(actions.getDocumentsDeliveryRequest, getDocumentsDeliveryRequest)]);
  yield all([takeLeading(actions.deleteDocumentsDeliveryRequest, deleteDocumentsDeliveryRequest)]);
}
