import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'antd';
import cn from 'classnames';

import { State } from 'store';
import makeFullName from 'shared/utils/makeFullName';

import Demo from './components/Demo';
import Email from './components/Email';
import FullName from './components/FullName';
import Password from './components/Password';
import MobilePhone from './components/MobilePhone';

import styles from './PersonalInformation.module.less';

const PersonalInformation = () => {
  const loading = useSelector((state: State) => state.loading.user_current);
  const user = useSelector((state: State) => state.profile.data);

  const fullName = useMemo(() => user && makeFullName(user), [user]);

  return (
    <Card title="Личные данные" loading={loading}>
      <div className={cn(styles.PersonalInformation)}>
        <div className={cn(styles.BlockOne)}>
          <FullName {...{ fullName }} />
          <Email {...{ email: user?.email }} />
          <MobilePhone {...{ mobile_phone: user?.mobile_phone }} />
          <Demo {...{ demo: user?.partner_settings?.demo }} />
        </div>
        <div className={cn(styles.BlockTwo)}>
          <Password />
        </div>
      </div>
    </Card>
  );
};

export default PersonalInformation;
