import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';

export const useDebounceValue = <T>(v: T, ms: number): T => {
  const [value, setValue] = useState(v);

  // eslint-disable-next-line
  const handler = useCallback(
    _.debounce((v) => setValue(v), ms),

    [ms, setValue],
  );

  useEffect(() => {
    handler(v);
  }, [v, handler]);

  return value;
};
