import {
  FileDoneOutlined,
  LineChartOutlined,
  NodeIndexOutlined,
  TeamOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { FC } from 'react';
import { Dashboard, DeliveryRequest, DeliveryRequestsList, UserList } from './pages';

interface Route {
  id: number;
  path: string;
  children: () => JSX.Element;
  useWith: string[];
  title?: string;
  icon?: FC;
  roles?: string[];
}

const routes: Route[] = [
  {
    id: 0,
    path: '',
    children: Dashboard,
    useWith: ['sidebar', 'header'],
    title: 'Главная',
    icon: LineChartOutlined,
    roles: ['robot', 'billing', 'operator'],
  },
  {
    id: 1,
    path: 'delivery/request/create',
    children: DeliveryRequest,
    useWith: ['sidebar', 'header'],
    title: 'Заказать доставку',
    icon: NodeIndexOutlined,
    roles: ['operator'],
  },
  {
    id: 2,
    path: 'delivery/requests/list',
    children: DeliveryRequestsList,
    useWith: ['sidebar', 'header'],
    title: 'Мои доставки',
    icon: UnorderedListOutlined,
    roles: ['operator'],
  },
  {
    id: 3,
    path: 'user/list',
    children: UserList,
    useWith: ['sidebar', 'header'],
    title: 'Управление пользователями',
    icon: TeamOutlined,
    roles: ['admin'],
  },
  {
    id: 4,
    path: 'billing/documents',
    children: UserList,
    useWith: ['sidebar', 'header'],
    title: 'Бухгалтерские документы',
    icon: FileDoneOutlined,
    roles: ['billing'],
  },
];

export default routes;
