import React from 'react';

import { format as formatDateFns } from 'date-fns';
import ru from 'date-fns/locale/ru';

import styles from './ShowDate.module.less';

const NOT_FOUND_TEXT = '-';

type ShowDateProps = Readonly<{
  date?: number | Date | null | false | string;
  time?: number | null;
  format?: string;
  options?:
    | {
        locale?: Locale | undefined;
        weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;
        firstWeekContainsDate?: number | undefined;
        useAdditionalWeekYearTokens?: boolean | undefined;
        useAdditionalDayOfYearTokens?: boolean | undefined;
      }
    | undefined;
}>;

export const ShowDate: React.FC<ShowDateProps> = ({ date, format = 'dd.MM.yyyy HH:mm:ss', options, time }) => {
  if (!['[object Date]', '[object Number]'].includes(Object.prototype.toString.call(date)) && typeof time !== 'number')
    return <>{NOT_FOUND_TEXT}</>;
  if (typeof time === 'number') {
    return (
      <span className={styles.ShowDate}>
        {formatDateFns(new Date(new Date('1900-01-01 00:00:00').valueOf() + time * 1000), format, options)}
        {formatDateFns(new Date(new Date('1900-01-01 00:00:00').valueOf() + time * 1000), format, {
          locale: ru,
          ...options,
        })}
      </span>
    );
  }

  // может зайти ссылка на object Date которая потом изменится
  return (
    <span className={styles.ShowDate}>
      {date ? formatDateFns(date as number | Date, format, options) : NOT_FOUND_TEXT}
      {date ? formatDateFns(date as number | Date, format, { locale: ru, ...options }) : NOT_FOUND_TEXT}
    </span>
  );
};

export default ShowDate;
