import { Select, SelectProps, Typography } from 'antd';
import _ from 'lodash';
import { useInitialUpdateField } from 'pages/DeliveryRequest/utils';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { getCities } from 'store/cities/actions';
import { selectCities } from 'store/cities/selectors';
import { selectLoadedFor, selectLoadingFor } from 'store/common/selectors';
import { SetFieldValue } from '../../useGenFormik';
import styles from './SelectCity.module.less';

type SelectCityProps = Readonly<{
  setFieldValue: SetFieldValue;
  configBlur: any;
  touched?: boolean;
  error?: string;
}> &
  SelectProps;

const SelectCity: React.FC<SelectCityProps> = ({ value, setFieldValue, configBlur, touched, error, ...rest }) => {
  const dispatch = useDispatch();

  const state = useSelector((state: State) => state);

  const isCityLoaded = selectLoadedFor('city')(state);
  const isCityLoading = selectLoadingFor('city')(state);
  const cities = _.values(selectCities(state));

  const onBlurCity = useInitialUpdateField({
    name: 'delivery_city',
    updateMessage: 'Город доставки',
    ...configBlur,
  });

  React.useEffect(() => {
    if (!isCityLoaded) {
      dispatch(getCities());
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.SelectCity}>
      <Select
        placeholder="Город доставки"
        value={value}
        onChange={(v) => setFieldValue('delivery_city', v, true)}
        loading={isCityLoading}
        className={styles.Select}
        optionLabelProp="label"
        onBlur={onBlurCity}
        {...rest}
      >
        {cities?.map(({ name, id }) => (
          <Select.Option key={id} value={id} label={name}>
            {name}
          </Select.Option>
        ))}
      </Select>
      {touched && error && <Typography.Text type="danger">{error}</Typography.Text>}
    </div>
  );
};

export default SelectCity;
