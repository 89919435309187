import { SearchOutlined } from '@ant-design/icons';
import React from 'react';
import styles from './FilterIconSearch.module.less';

type FilterIconSearchProps = Readonly<{
  active?: boolean;
}>;

const FilterIconSearch: React.FC<FilterIconSearchProps> = ({ active }) => {
  return <SearchOutlined style={{ color: active ? `${styles.primaryColor}` : `` }} />;
};

export default FilterIconSearch;
