import React from 'react';
import styles from './ReturnCard.module.less';
import cn from 'classnames';
import { Card } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';

type ReturnCardProps = Readonly<{
  title?: string;
  subTitle?: string | JSX.Element;
  backUrl?: string;
}>;

const ReturnCard: React.FC<ReturnCardProps> = ({ children, title, backUrl, subTitle }) => {
  const history = useHistory();

  return (
    <Card className={cn(styles.ReturnCard)}>
      <div className={styles.Header}>
        <ArrowLeftOutlined
          className={styles.Icon}
          onClick={() => (backUrl ? history.push(backUrl) : history.goBack())}
        />
        <span className={styles.Title}>{title}</span>
      </div>
      {!!subTitle && <div className={styles.SubTitle}>{subTitle}</div>}

      <div className={styles.Body}>{children}</div>
    </Card>
  );
};

export default ReturnCard;
