import _ from 'lodash';
import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as actions from './actions';
import { Document } from 'shared/types/document';

export interface Attributes {
  id: number;
  token: string;
  url: string;
  thumb_url: string;
  created_at: number;
  updated_at: number;
  delete_allowed: boolean;
}

export interface Datum {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Meta {
  total: number | null;
}

export interface Documents {
  data: Datum[];
  meta: Meta;
}

export interface DocumentsState {
  byId: { [key: string]: Document };
  allIds: string[];
  data: Meta;
}

const initialState: DocumentsState = {
  byId: {},
  allIds: [],
  data: { total: null },
};

// TODO: надо нормально описать State
const byId = handleActions<any>(
  {
    [actions.getDocumentsDeliveryRequestSuccess?.toString()]: (
      state,
      { payload: { data } }: { payload: { data: Datum[] } },
    ) => ({
      ...data.reduce(
        (acc, document) => ({
          ...acc,
          [document.id]: { id: document.id, ...document.attributes },
        }),
        {},
      ),
    }),
    [actions.deleteDocumentsDeliveryRequestSuccess?.toString()]: (
      state,
      { payload: { id } }: { payload: { id: string } },
    ) => ({
      ..._.omit(state, [id]),
    }),
    [actions.addDocumentsDeliveryRequestSuccess?.toString()]: (
      state,
      { payload: { data: document } }: { payload: { data: Datum } },
    ) => ({ ...state, [document.id]: { id: document.id, ...document.attributes } }),
  },
  initialState.byId,
);

// TODO: надо нормально описать State
const allIds = handleActions<any>(
  {
    [actions.getDocumentsDeliveryRequestSuccess?.toString()]: (
      state,
      { payload: { data } }: { payload: { data: Datum[] } },
    ) => [...Array.from(new Set([...data.map(({ id }) => id)]))],
    [actions.deleteDocumentsDeliveryRequestSuccess?.toString()]: (
      state,
      { payload: { id } }: { payload: { id: string } },
    ) => state.filter((item: string) => item?.toString() !== id?.toString()),
    [actions.addDocumentsDeliveryRequestSuccess?.toString()]: (
      state,
      { payload: { id } }: { payload: { id: string } },
    ) => [...state, id],
  },
  initialState.allIds,
);

// TODO: надо нормально описать State
const data = handleActions<any>(
  {
    [actions.getDocumentsDeliveryRequestSuccess?.toString()]: (
      state,
      { payload: { data, meta } }: { payload: { data: Datum[]; meta: Meta } },
    ) => ({
      ...state,
      data,
      total: meta?.total,
    }),
    [actions.deleteDocumentsDeliveryRequestSuccess?.toString()]: (state) => ({
      total: state.total - 1,
    }),
    [actions.addDocumentsDeliveryRequestSuccess?.toString()]: (state) => ({
      total: state.total + 1,
    }),
  },
  initialState.data,
);

export default combineReducers({
  byId,
  allIds,
  data,
});
