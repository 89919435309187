import React, { FC } from 'react';

import Footer from 'shared/components/Footer';

import Header from './components/Header';
import Menu from './components/Menu';

import styles from './MainLayout.module.less';

const MainLayout: FC<unknown> = ({ children }) => {
  return (
    <>
      <Header />
      <div className={styles.Container}>
        <Menu className={styles.SideBar} />
        <div className={styles.Content}>{children}</div>
        <Footer />
      </div>
    </>
  );
};

export default MainLayout;
