import * as Yup from 'yup';

export const validationSchema = Yup.object({
  type: Yup.string().required('Выберите способ получения одноразового кода '),
  mobile_phone: Yup.string().when('type', {
    is: (v: string) => {
      const params = new URL(window.location.href).searchParams;
      const two_fa_mobile_phone = params.get('two_fa_mobile_phone');

      return v === 'sms' && !two_fa_mobile_phone ? false : true;
    },
    otherwise: Yup.string().required('Обязательное поле'),
  }),
});

export type InitialValuesType = Readonly<{ type?: string; mobile_phone?: string }>;

export const initialValues: InitialValuesType = {};
