/* eslint-disable object-curly-newline */
/* eslint-disable camelcase */
import Axios from 'axios';
import { push } from 'connected-react-router';
import { put, all, takeLatest, call } from 'redux-saga/effects';
import _ from 'lodash';

import { errorEffectsStatuses, startEffectsStatuses, endEffectsStatuses } from 'store/utils';

import * as actionsNotification from '../notifications/action';
import * as actions from './actions';

interface TwoFaTypeProps {
  payload: { type: string; token: string; two_fa_uri: string; mobile_phone?: string };
}

function* twoFaType({ payload: { type, token, two_fa_uri, mobile_phone } }: TwoFaTypeProps) {
  const name = 'twoFaType';
  try {
    yield startEffectsStatuses({ name });

    yield call(Axios.request, {
      url: '/authentications/two_fa_type',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      data: { type, mobile_phone },
    });

    yield put(
      push(
        `/authentication/check_two_fa?token=${token}&two_fa_activated=true&type=${type}&two_fa_uri=${two_fa_uri}&two_fa_mobile_phone=${mobile_phone}`,
      ),
    );

    if (type === 'sms') {
      yield put(
        actionsNotification.SetNotifications({
          variant: 'success',
          message: 'Мы отправили вам код по смс для активации двухфакторной аутентификации',
        }),
      );
    } else if (type === 'authenticator') {
      yield put(
        actionsNotification.SetNotifications({
          variant: 'success',
          message: 'Для активации второго фактора установите приложение Google Authenticator и отсканируйте QR-код',
        }),
      );
    }
    yield put(actions.twoFaTypeSuccess());
    yield endEffectsStatuses({ name });
  } catch (error) {
    yield put(actions.twoFaTypeFailure());
    yield errorEffectsStatuses({ name, error });
  }
}

interface TwoFaCheckProps {
  payload: { code: string; token: string };
}

function* twoFaCheck({ payload: { code, token } }: TwoFaCheckProps) {
  const name = 'twoFaCheck';
  try {
    yield startEffectsStatuses({ name });

    const {
      data: { token: newToken, expires_at },
    } = yield call(Axios.request, {
      url: '/authentications/check_otp',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      data: { code },
    });

    yield localStorage.setItem('token', `Bearer ${newToken}`);
    yield localStorage.setItem('expiresAt', expires_at);

    yield put(push(_.get(location, 'state.from.pathname', '/')));
    yield put(actions.twoFaCheckSuccess());
    endEffectsStatuses({ name });
  } catch (error) {
    yield put(actions.twoFaCheckFailure());
    yield errorEffectsStatuses({ name, error });
  }
}

interface TwoFaSendOtpProps {
  payload: { code: string; token: string };
}

function* twoFaSendOtp({ payload: { token } }: TwoFaSendOtpProps) {
  const name = 'twoFaSendOtp';
  try {
    yield startEffectsStatuses({ name });

    yield call(Axios.request, {
      url: '/authentications/send_otp',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
    });

    yield endEffectsStatuses({ name });
    yield put(actions.twoFaSendOtpSuccess());
  } catch (error) {
    yield put(actions.twoFaSendOtpFailure());
    yield errorEffectsStatuses({ name, error });
  }
}

export default function* watchUsers() {
  yield all([takeLatest(actions.twoFaType, twoFaType)]);
  yield all([takeLatest(actions.twoFaCheck, twoFaCheck)]);
  yield all([takeLatest(actions.twoFaSendOtp, twoFaSendOtp)]);
}
