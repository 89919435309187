import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Result } from 'antd';
import { State } from 'store';

const Stup = () => {
  const { id }: { id?: string } = useParams();

  const error = useSelector((state: State) => state.error[`deliveryRequest_${id}`]);

  if (!error) return null;

  return (
    <Result
      status={error.status}
      title={error.status}
      subTitle={error.title}
      extra={
        <Link to="/delivery/requests/list">
          <Button type="primary">Перейти к списку заявок</Button>
        </Link>
      }
    />
  );
};

export default Stup;
