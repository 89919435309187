import Button from 'antd/es/button';
import cn from 'classnames';
import React, { FC, memo, useMemo, useState } from 'react';
import WrapperField from 'shared/components/WrapperField';
import { User } from 'store/users/reducers';
import EditModal from './components/EditModal';
import styles from './UnlockIntrusion.module.less';

type UnlockIntrusionProps = Readonly<{
  user: User;
}>;

const UnlockIntrusion: FC<UnlockIntrusionProps> = ({ user }) => {
  const [isModalVisible, setVisible] = useState(false);
  const { two_fa_attempts: attemptsTwoFa, failed_attempts: failedAttempts } = user;

  const block = useMemo(
    () => (
      <Button type="link" className={styles.Button} onClick={() => setVisible(true)}>
        Сбросить неудачные попытки Авторизации
      </Button>
    ),
    [setVisible],
  );

  return (
    <div className={cn(styles.UnlockIntrusion)}>
      {(attemptsTwoFa > 0 || failedAttempts > 0) && <WrapperField value={block} />}
      <EditModal {...{ isModalVisible, setVisible, user }} />
    </div>
  );
};

export default memo(UnlockIntrusion);
