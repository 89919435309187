import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import { Button } from 'antd';

import { version } from '../../../../package.json';

import styles from './Footer.module.less';
import { useHistory } from 'react-router';

const Footer = () => {
  const history = useHistory();

  return (
    <div className={cn(styles.Footer)}>
      © ООО «ФАСТ РИВЕР» {moment().format('YYYY')}{' '}
      <Button className={styles.Bytton} onClick={() => history.push('/list-of-changes')} type="link">
        Версия: {version}
      </Button>
    </div>
  );
};

export default Footer;
