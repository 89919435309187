import cn from 'classnames';
import { NotFound } from 'pages';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { DashboardActions } from 'store/dashboard/actions';
import AddDeliveris from './components/AddDeliveris';
import BillingDocuments from './components/BillingDocuments';
import FeedBack from './components/FeedBack';
import ListDeliveris from './components/ListDeliveris';
import Profile from './components/Profile';
import Reporting from './components/Reporting';
import UsersMenu from './components/UsersMenu';
import styles from './Dashboard.module.less';
import { DashboardWidgets } from './DashboardWidgets';

const Dashboard = () => {
  const dispatch = useDispatch();

  const user = useSelector((state: State) => state.profile.data);
  const error = useSelector((state: State) => state.error[`dashboard`]);

  useEffect(() => {
    if (user?.roles?.includes('admin') && user?.roles?.length === 1) return;
    dispatch(DashboardActions.getDashboar());
  }, [dispatch, user?.roles]);

  if (error) return <NotFound status={error.status} title={error.status} subTitle={error.title} />;

  return (
    <div className={styles.Dashboard}>
      <Helmet title="Главная" />
      <div className={styles.Menu}>
        <div className={cn(styles.AddDeliveris, { [styles.Disabled]: !user?.roles?.includes('operator') })}>
          <AddDeliveris />
        </div>
        <div className={cn(styles.ListDeliveris, { [styles.Disabled]: !user?.roles?.includes('operator') })}>
          <ListDeliveris />
        </div>
        <div className={cn(styles.ListDeliveris, { [styles.Disabled]: !user?.roles?.includes('admin') })}>
          <UsersMenu />
        </div>
        <div className={cn(styles.BillingDocuments, { [styles.Disabled]: !user?.roles?.includes('billing') })}>
          <BillingDocuments />
        </div>
        <div className={cn(styles.Reporting, { [styles.Disabled]: !user?.roles?.includes('billing') })}>
          <Reporting />
        </div>

        <div className={cn(styles.Profile)}>
          <Profile />
        </div>
        <div className={cn(styles.FeedBack)}>
          <FeedBack />
        </div>
      </div>
      <div className={styles.Tiles}>
        <DashboardWidgets />
      </div>
    </div>
  );
};

export default Dashboard;
