import React from 'react';
import { useHistory } from 'react-router';
import { PageHeader } from 'antd';
import cn from 'classnames';

import CreateUser from 'shared/components/CreateUser';

import styles from './Header.module.less';

const Header = () => {
  const history = useHistory();

  return (
    <PageHeader
      onBack={() => history.push('/')}
      className={cn(styles.Header)}
      title="Управление пользователями"
      extra={<CreateUser />}
    />
  );
};

export default Header;
