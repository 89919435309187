import { createAction } from 'redux-actions';

export const getDocumentsDeliveryRequest = createAction('GET_DOCUMENTS_DELIVERY_REQUEST');
export const getDocumentsDeliveryRequestSuccess = createAction('GET_DOCUMENTS_DELIVERY_REQUEST_SUCCESS');
export const getDocumentsDeliveryRequestFailure = createAction('GET_DOCUMENTS_DELIVERY_REQUEST_FAILURE');

export const addDocumentsDeliveryRequestSuccess = createAction('ADD_DOCUMENTS_DELIVERY_REQUEST_SUCCESS');

export const deleteDocumentsDeliveryRequest = createAction('DELETE_DOCUMENTS_DELIVERY_REQUEST');
export const deleteDocumentsDeliveryRequestSuccess = createAction('DELETE_DOCUMENTS_DELIVERY_REQUEST_SUCCESS');
export const deleteDocumentsDeliveryRequestFailure = createAction('DELETE_DOCUMENTS_DELIVERY_REQUEST_FAILURE');
