import Axios from 'axios';
import { call, put, all, takeLatest } from 'redux-saga/effects';

import { endEffectsStatuses, errorEffectsStatuses, startEffectsStatuses } from 'store/utils';

import * as actions from './actions';

interface PhoneCallHistoryParams {
  payload: {
    uuid?: string;
  };
}

function* getPhoneCallHistory({ payload: { uuid } }: PhoneCallHistoryParams) {
  const name = 'phoneCallHistory';

  const params: any = {};

  uuid && (params['filter[delivery_request_uuid]'] = uuid);

  try {
    yield startEffectsStatuses({ name });

    const {
      data: { data: phoneCallHistory },
    } = yield call(Axios.request, {
      method: 'get',
      url: '/phone_call_history',
      params,
    });

    yield put(actions.getPhoneCallHistorySuccess(phoneCallHistory));

    yield endEffectsStatuses({ name });
  } catch (error) {
    yield put(actions.getPhoneCallHistoryFailure());

    yield errorEffectsStatuses({ name, error });
  }
}

export default function* watchUsers() {
  yield all([takeLatest(actions.getPhoneCallHistory, getPhoneCallHistory)]);
}
