import { Select, SelectProps } from 'antd';
import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { useProduct } from 'shared/utils/hooks';
import { State } from 'store';
import styles from './SelectProduct.module.less';

type SelectProductProps<T = any> = SelectProps<T>;

const SelectProduct: React.FC<SelectProductProps> = (popss) => {
  useProduct();
  const {
    products: { byId },
  } = useSelector((state: State) => state);

  return (
    <Select showSearch placeholder="Продукт" className={cn(styles.SelectProduct)} size="large" allowClear {...popss}>
      {Object.values(byId)?.map(({ name, id }) => (
        <Select.Option value={id} key={id}>
          {name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectProduct;
