import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Input, Modal, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RadioChangeEvent } from 'antd/lib/radio';
import { ModalProps } from 'antd/lib/modal';
import { usePrevious } from 'react-use';
import cn from 'classnames';
import _ from 'lodash';

import { selectLoadedFor, selectLoadingFor } from 'store/common/selectors';
import { selectRejectReasons } from 'store/rejectReasons/selectors';

import { rejectDeliveryRequest } from 'store/deliveryRequests/actions';
import { getRejectReasons } from 'store/rejectReasons/actions';

import styles from './RejectModal.module.less';

interface IRejectModal extends ModalProps {
  id: string;
}

const RejectModal = ({ visible, onCancel, id, ...rest }: IRejectModal): JSX.Element => {
  const dispatch = useDispatch();

  const [radioData, setRadioData] = useState('');
  const [comment, setComment] = useState('');

  const state = useSelector((state) => state);

  const isRejectReasonsLoaded = useMemo(() => selectLoadedFor('rejectReasons')(state), [state]);

  const isDeliveryRequestLoading = useMemo(() => selectLoadingFor(`deliveryRequest_${id}`)(state), [id, state]);
  const prevIsDeliveryRequestLoading = usePrevious(isDeliveryRequestLoading);

  const rejectReasons = useMemo(() => selectRejectReasons(state), [state]);

  const onCommentChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => setComment(value),
    [],
  );
  const onRadioDataChange = useCallback(({ target: { value } }: RadioChangeEvent) => setRadioData(value), []);

  const onSubmit = () => {
    dispatch(
      rejectDeliveryRequest({
        id,
        rejectReasonCode: radioData,
        rejectReasonComment: comment,
      }),
    );
  };

  useEffect(() => {
    if (prevIsDeliveryRequestLoading === true && isDeliveryRequestLoading === false) {
      (onCancel as any)();
    }
  }, [prevIsDeliveryRequestLoading, isDeliveryRequestLoading, onCancel]);

  useEffect(() => {
    if (!isRejectReasonsLoaded) {
      dispatch(getRejectReasons());
    }

    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      {...rest}
      visible={visible}
      title="Укажите проблему"
      cancelButtonProps={{ style: { display: 'none' } }}
      onCancel={onCancel}
      footer={[
        null,
        <Button
          key="submit"
          type="primary"
          className={styles.WarningButton}
          disabled={radioData === ''}
          onClick={onSubmit}
          loading={isDeliveryRequestLoading}
        >
          Отклонить исполнение
        </Button>,
      ]}
      maskTransitionName=""
    >
      <div className={cn(styles.RejectModal)}>
        <Radio.Group onChange={onRadioDataChange} className={styles.RadioGroup} value={radioData}>
          {_.values(rejectReasons)?.map(({ code, name }) => (
            <Radio key={code} value={code}>
              {name}
            </Radio>
          ))}
        </Radio.Group>
        <div className={styles.Comment}>
          <label htmlFor="comment">Комментарий</label>
          <Input.TextArea
            value={comment}
            rows={4}
            id="comment"
            placeholder="Расскажи, что не так, сделаем нормально"
            onChange={onCommentChange}
          />
        </div>
      </div>
    </Modal>
  );
};

export default RejectModal;
