import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Modal, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { ModalProps } from 'antd/lib/modal';
import { usePrevious } from 'react-use';
import cn from 'classnames';
import _ from 'lodash';

import { deleteDeliveryRequest } from 'store/deliveryRequests/actions';
import { getCancelReasons } from 'store/cancelReasons/actions';

import { selectCancelReasons } from 'store/cancelReasons/selectors';
import { selectLoadedFor, selectLoadingFor } from 'store/common/selectors';

import styles from './CancelModal.module.less';

interface ICancelModal extends ModalProps {
  id: string;
}

const CancelModal = ({ visible, onCancel, id, ...rest }: ICancelModal): JSX.Element => {
  const dispatch = useDispatch();

  const [cancelReasons, setCancelReasons] = useState();
  const [radioData, setRadioData] = useState('');
  const [comment, setComment] = useState('');

  const state = useSelector((state) => state);

  const isCancelReasonsLoaded = useMemo(() => selectLoadedFor('cancelReasons')(state), [state]);

  const isDeliveryRequestLoading = useMemo(() => selectLoadingFor(`deliveryRequest_${id}`)(state), [id, state]);
  const prevIsDeliveryRequestLoading = usePrevious(isDeliveryRequestLoading);

  useEffect(() => {
    if (prevIsDeliveryRequestLoading === true && isDeliveryRequestLoading === false) {
      (onCancel as any)();
    }
  }, [prevIsDeliveryRequestLoading, isDeliveryRequestLoading, onCancel]);

  const onCommentChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => setComment(value),
    [],
  );

  const onRadioDataChange = useCallback(({ target: { value } }: RadioChangeEvent) => setRadioData(value), []);

  const onSubmit = useCallback(() => {
    dispatch(
      deleteDeliveryRequest({
        id,
        cancelReasonCode: radioData,
        cancelReasonComment: comment,
      }),
    );
  }, [dispatch, id, radioData, comment]);

  useEffect(() => {
    if (!isCancelReasonsLoaded) {
      dispatch(getCancelReasons());
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const cancelReasons = selectCancelReasons(state);

    setCancelReasons(cancelReasons);
  }, [state, setCancelReasons]);

  useEffect(() => {
    if (visible) {
      setRadioData('');
      setComment('');
    }
  }, [visible]);

  return useMemo(
    () => (
      <Modal
        {...rest}
        visible={visible}
        title="Укажите причину отмены"
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={onCancel}
        footer={[
          null,
          <Button
            key="submit"
            type="primary"
            className={styles.DangerButton}
            disabled={radioData === ''}
            onClick={onSubmit}
            loading={isDeliveryRequestLoading}
          >
            Отменить заявку
          </Button>,
        ]}
        maskTransitionName=""
      >
        <div className={cn(styles.CancelModal)}>
          <Radio.Group onChange={onRadioDataChange} className={styles.RadioGroup} value={radioData}>
            {_.values(cancelReasons)?.map(({ code, name }: any) => (
              <Radio key={code} value={code}>
                {name}
              </Radio>
            ))}
          </Radio.Group>
          <div className={styles.Comment}>
            <label htmlFor="comment">Комментарий</label>
            <Input.TextArea
              value={comment}
              rows={4}
              id="comment"
              placeholder="Возможно здесь будет ответ на все наши вопросы"
              onChange={onCommentChange}
            />
          </div>
        </div>
      </Modal>
    ),
    [
      isDeliveryRequestLoading,
      onRadioDataChange,
      onCommentChange,
      cancelReasons,
      radioData,
      onSubmit,
      onCancel,
      visible,
      comment,
      rest,
    ],
  );
};

export default memo(CancelModal);
