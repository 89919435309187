import { call, put, all, takeLatest } from 'redux-saga/effects';
import { get } from 'shared/libs/request';
import * as actions from './actions';

function* getRejectReasons() {
  try {
    const { data } = yield call(get, '/reject_reasons');

    yield put(actions.getRejectReasonsSuccess(data));
  } catch (error) {
    yield put(actions.getRejectReasonsFailure());
  }
}

export default function* watchRejectReasons() {
  yield all([takeLatest(actions.getRejectReasons, getRejectReasons)]);
}
