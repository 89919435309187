import React, { FC, memo } from 'react';
import { Button, Input, Typography, Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import cn from 'classnames';

import { State } from 'store';
import { createAuthentication } from 'store/auth/actions';

import { initialValues, validationSchema } from './utils';

import styles from './Auth.module.less';

const { Text } = Typography;

const Auth: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const loading = useSelector((state: State) => state.loading.createAuthentication);

  const { values, touched, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ password, email: login }) => {
      dispatch(
        createAuthentication({
          login,
          password,
        }),
      );
    },
  });

  return (
    <div className={cn({ [styles.Hiden]: location.pathname !== '/authentication' }, styles.Show)}>
      <Card className={cn(styles.Auth)}>
        <form onSubmit={handleSubmit}>
          <Input
            value={values.email}
            onBlur={handleBlur}
            onChange={handleChange}
            name="email"
            placeholder="Email"
            type="email"
            size="large"
          />
          {touched.email && errors.email && <Text type="danger">{errors.email}</Text>}
          <Input
            value={values.password}
            onBlur={handleBlur}
            onChange={handleChange}
            name="password"
            placeholder="Пароль"
            type="password"
            size="large"
          />
          {touched.password && errors.password && <Text type="danger">{errors.password}</Text>}

          <div className={styles.ControlPanel}>
            <Button type="primary" loading={loading} htmlType="submit" size="large">
              Войти
            </Button>
            <Button
              size="large"
              type="link"
              className={cn(styles.ResetPassword)}
              onClick={() => history.push('/authentication/reset_recovery')}
            >
              Забыли пароль?
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default memo(Auth);
