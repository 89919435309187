import cn from 'classnames';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { State } from 'store';
import CreatedData from './components/CreatedData';
import DeliveryDate from './components/DeliveryDate';
import Payments from './components/Payments';
import Products from './components/Products';
import StatusBar from './components/StatusBar';
import Users from './components/Users';
import styles from './Dashboard.module.less';

export const DashboardWidgets = () => {
  const history = useHistory();

  const user = useSelector((state: State) => state.profile.data);

  useEffect(() => {
    user?.roles?.includes('admin') && user?.roles?.length === 1 && history.push('/user/list');
  }, [history, user?.roles]);

  return (
    <>
      <div className={cn(styles.DeliveryDate)}>
        <DeliveryDate />
      </div>
      <div className={cn(styles.StatusBar)}>
        <StatusBar />
      </div>
      <div className={cn(styles.CreatedData)}>
        <CreatedData />
      </div>
      <div className={cn(styles.Products)}>
        <Products />
      </div>
      <div className={cn(styles.Users)}>
        <Users />
      </div>
      <div className={cn(styles.Payments)}>
        <Payments />
      </div>
    </>
  );
};
