import { Alert, Card, Tag, Typography } from 'antd';
import cn from 'classnames';
import moment from 'moment';
import { deliveryRequestStatusesLoadedCounter } from 'pages/DeliveryRequestsShow/DeliveryRequestsShow';
import React, { FC, memo, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import CancelModal from 'shared/components/DeliveryRequest/components/CancelModal';
import OkModal from 'shared/components/DeliveryRequest/components/OkModal';
import RejectModal from 'shared/components/DeliveryRequest/components/RejectModal';
import { IStatusColors, STATUS_COLORS } from 'shared/constants';
import { selectDeliveryRequest } from 'store/deliveryRequests/selectors';
import { selectDeliveryRequestStatuses } from 'store/deliveryRequestStatuses/selectors';
import DataCanceled from './components/DataCanceled';
import DataRejected from './components/DataRejected';
import List from './components/DataRequest';
import useActions from './components/useActions';
import styles from './Request.module.less';

const { Text } = Typography;
interface IRequest {
  className?: string;
}

const Request: FC<IRequest> = ({ className }) => {
  const { id }: { id: string } = useParams();

  const { isEverythigReady } = deliveryRequestStatusesLoadedCounter.useContainer();

  const [isCancelModal, setIsCancelModal] = useState(false);
  const [isRejectModal, setIsRejectModal] = useState(false);
  const [isOkModal, setIsOkModal] = useState(false);

  const state = useSelector((state) => state);

  const deliveryRequest = useMemo(() => selectDeliveryRequest(id)(state), [state, id]);
  const deliveryRequestStatuses = useMemo(() => selectDeliveryRequestStatuses(state), [state]);
  const { hasValidationErrors } = useMemo(
    () => ({
      hasValidationErrors: deliveryRequest?.status === 'created',
    }),
    [deliveryRequest],
  );

  const actions = useActions({ setIsCancelModal, setIsRejectModal, setIsOkModal });

  const extra = useMemo(
    () =>
      isEverythigReady && (
        <div className={styles.Status} id="request">
          <Text type="secondary">{moment(deliveryRequest?.created_at * 1000).format('DD.MM.YYYY HH:mm:ss')}</Text>
          <Tag color={STATUS_COLORS[deliveryRequest?.status as keyof IStatusColors]}>
            {deliveryRequestStatuses?.[deliveryRequest?.status]?.name ||
              deliveryRequest?.status ||
              'Статус не известен'}
          </Tag>
        </div>
      ),
    [isEverythigReady, deliveryRequest, deliveryRequestStatuses],
  );

  return (
    <div className={cn(styles.Request, className)}>
      {hasValidationErrors && !isEverythigReady && (
        <div>
          <Alert message="Заявку необходимо откорректировать" type="error" showIcon />
        </div>
      )}

      <Card
        title={
          <div className={styles.Title}>
            Заявка{' '}
            <div className={styles.SubTilte}>
              <Text type="secondary">{deliveryRequest?.external_id}</Text>
            </div>
          </div>
        }
        actions={actions}
        loading={!isEverythigReady}
        extra={extra}
      >
        <DataRejected />
        <DataCanceled />
        <List />
      </Card>
      <CancelModal visible={isCancelModal} onCancel={() => setIsCancelModal(false)} id={id} />
      <RejectModal visible={isRejectModal} onCancel={() => setIsRejectModal(false)} id={id} />
      <OkModal visible={isOkModal} onCancel={() => setIsOkModal(false)} id={id} />
    </div>
  );
};

export default memo(Request);
