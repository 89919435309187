import React from 'react';

import cn from 'classnames';

export const Arrow: React.FC<any> = ({ className, fill, ...rest }) => {
  return (
    <svg className={cn(className)} {...rest}>
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          strokeWidth="3"
          stroke={fill}
          id="svg_5"
          d="m0.6324,6.96184l5.82298,5.82298"
          opacity="undefined"
          fill="none"
        />
        <line
          strokeWidth="3"
          stroke={fill}
          id="svg_8"
          y2="0.62627"
          x2="18.24402"
          y1="13.10339"
          x1="5.36286"
          fill="none"
        />
      </g>
    </svg>
  );
};
