import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { State } from 'store';

import { createDeliveryRequest, updateDeliveryRequest } from 'store/deliveryRequests/actions';
import { selectDeliveryRequest } from 'store/deliveryRequests/selectors';
import { SetNotifications } from 'store/notifications/action';

import { DeliveryRequest } from './interface';

interface InitialUpdateField {
  name: keyof DeliveryRequest;
  id?: string;
  updateMessage: string;
  setField: ({ data: { id, ...data }, updateMessage }: any) => void;
  errors: { [key: string]: any };
  values: { [key: string]: any };
  setFieldTouched: (arg1: string, arg2: boolean, arg3: boolean) => void;
  modify?: (arg: string) => string;
}

const dataName: any = {
  delivery_city: 'deliveryCity',
  mobile_phone: 'mobilePhone',
  company_name: 'companyName',
};

export const useInitialUpdateField = ({
  name,
  id,
  updateMessage,
  setField,
  values,
  setFieldTouched,
  modify,
}: InitialUpdateField) => {
  const state = useSelector((state: State) => state);

  const deliveryRequest = selectDeliveryRequest(id)(state);

  const value = deliveryRequest?.[name];

  return useCallback(() => {
    setFieldTouched(name, true, true);

    values[name] &&
      values[name] !== value &&
      setField({
        data: { [dataName[name] ? dataName[name] : name]: modify ? modify(values[name]!) : values[name], id },
        updateMessage,
      });
  }, [value, values, id, name, setField, setFieldTouched, updateMessage, modify]);
};

// Основаная логика создания и обновление заявок
export const useSetDelivery = () => {
  const dispatch = useDispatch();
  const [isCreated, setIsCreated] = useState(false);
  const [updateMessage, setUpdateMessage] = useState<null | string>(null);

  const { id }: { id: string } = useParams();

  const { uuid } = useSelector((state: State) => state.deliveryRequests.byId[id] || {});

  // Если мы начали создавать заявку и у нас появился UUID, значит заявка успешна создана, надо об этом оповестить пользователя
  useEffect(() => {
    if (!!uuid && isCreated) {
      dispatch(
        SetNotifications({
          variant: 'success',
          message: 'Заявка создана',
          description: 'Поздравляем, заявка была успешно создана и добавлена в черновик',
        }),
      );
    }
  }, [uuid, isCreated, dispatch]);

  useEffect(() => {
    if (updateMessage) {
      dispatch(
        SetNotifications({
          variant: 'success',
          message: 'Заявка обновлена',
          description: `Поле "${updateMessage}" было обновлено`,
        }),
      );
      setUpdateMessage(null);
    }
  }, [updateMessage, dispatch]);

  // Возврощаем функцию которая решает обновить или создать заявку
  return useCallback(
    ({ data: { id, ...data }, updateMessage }) => {
      // если у нас есть id  то мы обновляем
      if (id) {
        dispatch(updateDeliveryRequest({ ...data, id }));
        setUpdateMessage(updateMessage);

        // если у нас нету id то мы создаем заявку
      } else {
        dispatch(createDeliveryRequest({ ...data, id }));
        setIsCreated(true);
      }
    },
    [dispatch],
  );
};
