import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { State } from 'store';
import { NotFound } from 'pages';
import { getProfile } from 'store/profile/actions';

import Header from './components/Header';
import ApplicationForms from './components/ApplicationForms';
import PersonalInformation from './components/PersonalInformation';

import styles from './Profile.module.less';

const Profile = () => {
  const dispatch = useDispatch();

  const error = useSelector((state: State) => state.error.profile);

  useEffect(() => {
    dispatch(getProfile());

    // eslint-disable-next-line
  }, []);

  if (error) return <NotFound status={error.status} title={error.status} subTitle={error.title} />;

  return (
    <>
      <Helmet title="Профиль" />
      <Header />
      <div className={styles.Cards}>
        <PersonalInformation />
        <ApplicationForms />
      </div>
    </>
  );
};

export default Profile;
