import { createAction } from 'redux-actions';

export const passwordRecovery = createAction('PASSWORD_RECOVERY');

export const passwordRecoverySuccess = createAction('PASSWORD_RECOVERY_SUCCESS');

export const passwordRecoveryFailure = createAction('PASSWORD_RECOVERY_FAILURE');

export const sendRecoveryEmail = createAction('SEND_RECOVERY_EMAIL');

export const sendRecoveryEmailSuccess = createAction('SEND_RECOVERY_EMAIL_SUCCESS');

export const sendRecoveryEmailFailure = createAction('SEND_RECOVERY_EMAIL_FAILURE');
