import React, { FC, memo, useCallback, useState } from 'react';
import cn from 'classnames';

import WrapperField from 'shared/components/WrapperField';
import EditModal from './components/EditModal';

import styles from './Email.module.less';

interface EmailProps {
  email?: string;
}

const Email: FC<EmailProps> = ({ email }) => {
  const [isModalVisible, setVisible] = useState(false);

  const handlerEdit = useCallback(() => setVisible(true), []);

  return (
    <div className={cn(styles.Email)}>
      <WrapperField value={email} title="Email" handlerEdit={handlerEdit} />
      <EditModal {...{ isModalVisible, setVisible }} />
    </div>
  );
};

export default memo(Email);
