import React from 'react';
import { Card } from 'antd';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { State } from 'store';

import styles from './CreatedData.module.less';

const CreatedData = () => {
  const by_created_date = useSelector(
    (state: State) => state.dashboard.data?.attributes.delivery_requests.by_created_date,
  );

  return (
    <Card className={cn(styles.CreatedData)} title="Доставлено за период">
      <div className={styles.Body}>
        <div className={styles.Item}>
          <div>За неделю</div>
          <div>{by_created_date?.week || 0}</div>
        </div>

        <div className={styles.Item}>
          <div>За месяц</div>
          <div>{by_created_date?.month || 0}</div>
        </div>

        <div className={styles.Item}>
          <div>Всего</div>
          <div>{by_created_date?.total || 0}</div>
        </div>
      </div>
    </Card>
  );
};

export default CreatedData;
