import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { getProducts } from 'store/products/actions';

export const useProduct = () => {
  const dispatch = useDispatch();
  const {
    products: { byId },
    loading: { cities: loading },
  } = useSelector((state: State) => state);

  React.useEffect(() => {
    if (!loading && Object.keys(byId).length === 0) {
      dispatch(getProducts());
    }

    // eslint-disable-next-line
  }, []);
};
