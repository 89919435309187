import React from 'react';
import { useHistory } from 'react-router';
import { PageHeader } from 'antd';
import cn from 'classnames';

import styles from './Header.module.less';

const Header = () => {
  const history = useHistory();

  return <PageHeader onBack={history.goBack} className={cn(styles.Header)} title="Профиль" />;
};

export default Header;
