import cn from 'classnames';
import React from 'react';
import WrapperField from 'shared/components/WrapperField';
import { User } from 'store/users/reducers';
import styles from './AttemptsTwoFa.module.less';

type AttemptsTwoFaFAProps = Readonly<{
  user: User;
}>;

const AttemptsTwoFa: React.FC<AttemptsTwoFaFAProps> = ({ user: { two_fa_attempts: AttemptsTwoFa } }) => {
  return (
    <div className={cn(styles.TwoFAStatus)}>
      <WrapperField
        value={
          <div
            className={cn(AttemptsTwoFa > 0 && AttemptsTwoFa < 5 && styles.Yellow, AttemptsTwoFa === 5 && styles.Red)}
          >
            {AttemptsTwoFa}
          </div>
        }
        title="Неудачные попытки ввода 2FA"
      />
    </div>
  );
};

export default AttemptsTwoFa;
