import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Card, Divider } from 'antd';
import cn from 'classnames';

import { selectDeliveryRequest } from 'store/deliveryRequests/selectors';
import { selectCancelReasons } from 'store/cancelReasons/selectors';

import styles from './DataCanceled.module.less';

const DataCanceled = () => {
  const { id }: { id: string } = useParams();

  const state = useSelector((state) => state);

  const deliveryRequest = useMemo(() => selectDeliveryRequest(id)(state), [state, id]);

  const cancelReasons = useMemo(() => selectCancelReasons(state), [state]);

  if (deliveryRequest?.status !== 'canceled') return null;

  return (
    <div className={cn(styles.DataCanceled)}>
      <Card.Meta
        title={cancelReasons?.[deliveryRequest?.cancel_reason_code]?.name ?? ''}
        description={<>{deliveryRequest?.cancel_reason_comment ?? ''}</>}
      />
      <Divider />
    </div>
  );
};

export default DataCanceled;
