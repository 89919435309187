import React, { FC, memo, useCallback, useState } from 'react';
import NumberFormat from 'react-number-format';
import cn from 'classnames';

import WrapperField from 'shared/components/WrapperField';
import EditModal from './components/EditModal';

import styles from './MobilePhone.module.less';

interface MobilePhoneProps {
  mobile_phone?: string;
}

const MobilePhone: FC<MobilePhoneProps> = ({ mobile_phone }) => {
  const [isModalVisible, setVisible] = useState(false);

  const handlerEdit = useCallback(() => setVisible(true), []);

  return (
    <div className={cn(styles.MobilePhone)}>
      <WrapperField
        value={
          <NumberFormat value={mobile_phone} allowEmptyFormatting displayType="text" format="+# (###) ###-##-##" />
        }
        title="Телефон"
        handlerEdit={handlerEdit}
      />
      <EditModal {...{ isModalVisible, setVisible }} />
    </div>
  );
};

export default memo(MobilePhone);
