import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { State } from 'store';
import cn from 'classnames';

import { NotFound } from 'pages';
import { getUser } from 'store/users/actions';

import { getUserRoles } from 'store/userRoles/actions';
import Header from './components/Header';
import Body from './components/Body';

import styles from './User.module.less';

const User = () => {
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();

  const error = useSelector((state: State) => state.error.getUser);

  useEffect(() => {
    dispatch(getUser({ id }));
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(getUserRoles());

    // eslint-disable-next-line
  }, []);

  if (error) return <NotFound status={error.status} title={error.status} subTitle={error.title} />;

  return (
    <div className={cn(styles.User)}>
      <Header />
      <Body />
    </div>
  );
};

export default User;
