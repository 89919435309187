import React from 'react';
import styles from './SelectExternalIdRequired.module.less';
import { Input, InputProps, Typography } from 'antd';
import { SetFieldValue } from '../../useGenFormik';
import { useInitialUpdateField } from 'pages/DeliveryRequest/utils';

type SelectExternalIdRequiredProps = Readonly<{
  setFieldValue: SetFieldValue;
  configBlur: any;
  touched?: boolean;
  error?: string;
}> &
  InputProps;

const SelectExternalIdRequired: React.FC<SelectExternalIdRequiredProps> = ({
  value,
  setFieldValue,
  configBlur,
  touched,
  error,
  ...rest
}) => {
  const onBlurCompanyName = useInitialUpdateField({
    name: 'external_id',
    updateMessage: 'Номер заявки',
    ...configBlur,
  });

  return (
    <div className={styles.SelectExternalIdRequired}>
      <Input
        placeholder="Номер заявки"
        name="external_id"
        value={value}
        onChange={(e) => setFieldValue('external_id', e.target.value, true)}
        onBlur={onBlurCompanyName}
        className={styles.Select}
        {...rest}
      />
      {touched && error && <Typography.Text type="danger">{error}</Typography.Text>}
    </div>
  );
};

export default SelectExternalIdRequired;
