import Axios from 'axios';
import { call, put, all, takeLatest } from 'redux-saga/effects';

import { endEffectsStatuses, errorEffectsStatuses, startEffectsStatuses } from 'store/utils';

import * as actions from './actions';

interface IGetTaskCommentsRequest {
  payload: {
    uuid: string;
  };
}

function* getTaskComments({ payload: { uuid } }: IGetTaskCommentsRequest) {
  const name = 'taskComments';

  try {
    yield startEffectsStatuses({ name });
    const {
      data: { data: taskComments },
    } = yield call(Axios.get, `/delivery_requests/${uuid}/task_comments`);

    yield endEffectsStatuses({ name });
    yield put(actions.getTaskCommentsSuccess(taskComments));
  } catch (error) {
    yield errorEffectsStatuses({ name, error });
    yield put(actions.getTaskCommentsFailure());
  }
}

export default function* watchTaskCommentsState() {
  yield all([takeLatest(actions.getTaskComments, getTaskComments)]);
}
