/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
import axios from 'axios';
import { API_ENDPOINT, HEADERS } from 'shared/constants/';

const makeRequest = async ({ axiosInstance, verb, url, data, conf = {} }) => {
  try {
    const { confHeaders = {}, ...confRest } = conf;

    const token = localStorage.getItem('token');

    const headers = token ? { Authorization: `${token}`, ...confHeaders } : confHeaders;

    if (['post', 'patch', 'put'].includes(verb)) {
      const { data: res } = await axiosInstance[verb](url, data, {
        headers,
        ...confRest,
      });

      return res;
    }

    const { data: res } = await axiosInstance[verb](url, {
      headers,
      ...confRest,
    });

    return res;
  } catch (e) {
    if (e.response?.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('expires_at');

      if (window.location.pathname !== '/authentication') {
        window.location.pathname = '/authentication';
      }
    }

    throw e;
  }
};

const makeApi = (axiosInstance) => {
  const verbs = ['get', 'post', 'patch', 'put', 'delete'];

  const api = verbs.reduce((acc, verb) => {
    if (['post', 'patch', 'put'].includes(verb)) {
      return {
        ...acc,
        [verb]: (url, data, conf) => makeRequest({ axiosInstance, verb, url, data, conf }),
      };
    }

    return {
      ...acc,
      [verb]: (url, conf) => makeRequest({ axiosInstance, verb, url, conf }),
    };
  }, {});

  return api;
};

export const makeCustomRequest = ({ endPoint = API_ENDPOINT }) => {
  const axiosInstance = axios.create({
    baseURL: endPoint,
    headers: HEADERS,
  });

  return makeApi(axiosInstance);
};

const axiosInstance = axios.create({
  baseURL: API_ENDPOINT,
  headers: HEADERS,
});

const { get, post, patch, put, delete: del } = makeApi(axiosInstance);

export { get, post, patch, put, del, axiosInstance };
