interface MakeFullNameProps {
  first_name?: string;
  last_name?: string;
  patronymic?: string;
}

export default ({ first_name, last_name, patronymic }: MakeFullNameProps) => {
  if (!last_name && !first_name) {
    return '-';
  }

  const firstName = first_name ? `${first_name}` : '';
  const secondName = first_name && patronymic ? `${patronymic} ` : '';
  const lastName = last_name ? `${last_name} ` : '';

  return [firstName, secondName, lastName].filter((v) => v).join(' ');
};
