import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, 'Минимум 8 символов')
    .matches(/^.*[A-ZА-ЯЁ]+.*$/, 'Пароль должен иметь хотя бы одну заглавную букву')
    .matches(/^.*[a-zа-яё]+.*$/, 'Пароль должен иметь хотя бы одну строчную букву')
    .matches(/^.*\d+.*$/, 'Пароль должен иметь хотя бы одну цифру')
    .required('Обязательное поле'),
  confirmPassword: Yup.string()
    .min(8, 'Минимум 8 символов')
    .matches(/^.*[A-ZА-ЯЁ]+.*$/, 'Пароль должен иметь хотя бы одну заглавную букву')
    .matches(/^.*[a-zа-яё]+.*$/, 'Пароль должен иметь хотя бы одну строчную букву')
    .matches(/^.*\d+.*$/, 'Пароль должен иметь хотя бы одну цифру')
    .oneOf([Yup.ref('newPassword')], 'Новые пароли должен совпадать')
    .required('Обязательное поле'),
});

export const initialValues = {
  newPassword: '',
  confirmPassword: '',
};
