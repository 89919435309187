import React, { useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { Card } from 'antd';
import _ from 'lodash';

import { State } from 'store';

import styles from './DeliveryDate.module.less';
import { useHistory } from 'react-router';

const DeliveryDate = () => {
  const history = useHistory();

  const deliveryDate = useSelector(
    (state: State) => state.dashboard.data?.attributes.delivery_requests.by_delivery_date,
  );

  const deliveryDateCompleted = useSelector(
    (state: State) => state.dashboard.data?.attributes.delivery_requests.by_delivery_date_completed,
  );

  const data = useMemo(() => {
    if (!deliveryDate) return [];

    return _(deliveryDate)
      .entries()
      .map(([key, value]) => ({
        name: key,
        counter: value,
        counterCompleted: deliveryDateCompleted?.[key] ?? 0,
      }))
      .value();
  }, [deliveryDate, deliveryDateCompleted]);

  return (
    <div>
      <Card className={cn(styles.DeliveryDate)} title="По датам">
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            {/* <Legend /> */}
            <Line
              type="monotone"
              dataKey="counter"
              stroke="rgb(28, 88, 232)"
              name="Всего доставок"
              activeDot={{
                onClick: (_: any, { payload: { name } }: any) => {
                  history.push(`/delivery/requests/list?date_gte=${name}&date_lte=${name}`);
                },
              }}
            />
            <Line
              type="monotone"
              dataKey="counterCompleted"
              stroke="rgb(8, 199, 18)"
              name="Доставлено"
              activeDot={{
                onClick: (_: any, { payload: { name } }: any) => {
                  history.push(`/delivery/requests/list?date_gte=${name}&date_lte=${name}&delivery_status=completed`);
                },
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </Card>
    </div>
  );
};

export default DeliveryDate;
