import { DatePicker, DatePickerProps } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import cn from 'classnames';
import 'moment/locale/ru';
import React from 'react';
import styles from './SelectAroundDate.module.less';

type SelectAroundDateProps = Readonly<{ props: [DatePickerProps, DatePickerProps] }>;

const SelectAroundDate: React.FC<SelectAroundDateProps> = ({ props: [fromDatePricker, toDatePricker] }) => {
  return (
    <>
      <DatePicker
        placeholder="C даты"
        className={cn(styles.Filter, styles.Gte)}
        id="date-gte"
        disabledDate={(current) =>
          !!(current && toDatePricker.value && current.endOf('day') > toDatePricker.value.endOf('day'))
        }
        format={'DD.MM.YYYY'}
        size="large"
        allowClear
        locale={locale}
        {...fromDatePricker}
      />
      {/* <label htmlFor="date-lte">По дату</label> */}
      <DatePicker
        placeholder="По дату"
        className={cn(styles.Filter, styles.Lte)}
        id="date-lte"
        disabledDate={(current) =>
          !!(current && fromDatePricker.value && current.endOf('day') < fromDatePricker.value.endOf('day'))
        }
        format={'DD.MM.YYYY'}
        size="large"
        allowClear
        locale={locale}
        {...toDatePricker}
      />
    </>
  );
};

export default SelectAroundDate;
