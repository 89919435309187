import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectDeliveryRequest } from 'store/deliveryRequests/selectors';

export const useStatusActions = ({ id }: { id: string }) => {
  const state = useSelector((state) => state);

  const deliveryRequest = selectDeliveryRequest(id)(state);

  return useMemo(
    () => ({
      canBeEdited: ['validated', 'created'].includes(deliveryRequest?.status),
      canBeCanceled: ['validated', 'created'].includes(deliveryRequest?.status),
      canBeRejected: ['executed'].includes(deliveryRequest?.status),
      canBeOk: ['executed'].includes(deliveryRequest?.status),
    }),
    [deliveryRequest],
  );
};
