import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { State } from 'store';
import { useEffect } from 'react';
import { SetNotifications } from 'store/notifications/action';

const openNotificationWithIcon = (
  variant: 'success' | 'error' | 'warning' | 'info',
  message: string,
  description?: string | null,
) => {
  notification[variant]({
    message,
    description,
  });
};

export default () => {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state: State) => state);

  useEffect(() => {
    const { variant, message, description } = notifications;

    if (variant && message) {
      openNotificationWithIcon(variant, message, description);
      dispatch(SetNotifications({ variant: null, message: null, description: null }));
    }
  }, [notifications, dispatch]);
};
