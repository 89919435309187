import React, { useEffect, useMemo } from 'react';
import { Button, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ModalProps } from 'antd/lib/modal';
import { usePrevious } from 'react-use';
import cn from 'classnames';

import { selectLoadingFor } from 'store/common/selectors';

import { confirmDeliveryRequest } from 'store/deliveryRequests/actions';

import styles from './OkModal.module.less';

interface IOkModal extends ModalProps {
  id: string;
}

const OkModal = ({ visible, onCancel, id, ...rest }: IOkModal): JSX.Element => {
  const dispatch = useDispatch();

  const state = useSelector((state) => state);

  const isDeliveryRequestLoading = useMemo(() => selectLoadingFor(`deliveryRequest_${id}`)(state), [id, state]);
  const prevIsDeliveryRequestLoading = usePrevious(isDeliveryRequestLoading);

  const onSubmit = () => {
    dispatch(confirmDeliveryRequest({ id }));
  };

  useEffect(() => {
    if (prevIsDeliveryRequestLoading === true && isDeliveryRequestLoading === false) {
      (onCancel as any)();
    }
  }, [prevIsDeliveryRequestLoading, isDeliveryRequestLoading, onCancel]);

  return (
    <Modal
      {...rest}
      visible={visible}
      title="Подтвердить"
      cancelButtonProps={{ style: { display: 'none' } }}
      onCancel={onCancel}
      footer={[
        null,
        <Button
          key="submit"
          type="primary"
          className={styles.WarningButton}
          onClick={onSubmit}
          loading={isDeliveryRequestLoading}
        >
          Подтвердить заявку
        </Button>,
      ]}
      maskTransitionName=""
    >
      <div className={cn(styles.RejectModal)}>Вы уверены, что хотите подтвердить?</div>
    </Modal>
  );
};

export default OkModal;
