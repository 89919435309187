import { call, put, all, takeLatest } from 'redux-saga/effects';
import { endEffectsStatuses, startEffectsStatuses, errorEffectsStatuses } from 'store/utils';
import { get } from 'shared/libs/request';

import * as actions from './actions';

interface GetTimeIntervalsProps {
  payload: {
    uuid: string;
    precise?: boolean;
  };
}

function* getTimeIntervals({ payload: { uuid, precise } }: GetTimeIntervalsProps) {
  const name = 'timeIntervals';

  try {
    yield startEffectsStatuses({ name });

    const { data } = yield call(get, `/delivery_requests/${uuid}/time_intervals${precise ? `?precise=true` : ''}`);

    yield endEffectsStatuses({ name });
    yield put(actions.getTimeIntervalsSuccess(data));
  } catch (error) {
    yield errorEffectsStatuses({ name, error });
    yield put(actions.getTimeIntervalsFailure());
  }
}

function* getAllTimeIntervals() {
  const name = 'allTimeIntervals';

  try {
    yield startEffectsStatuses({ name });

    const { data } = yield call(get, `/time_intervals`);

    yield endEffectsStatuses({ name });
    yield put(actions.getTimeIntervalsSuccess(data));
  } catch (error) {
    yield errorEffectsStatuses({ name, error });
    yield put(actions.getTimeIntervalsFailure());
  }
}

export default function* watchPhotoTypes() {
  yield all([takeLatest(actions.getTimeIntervals, getTimeIntervals)]);
  yield all([takeLatest(actions.getAllTimeIntervals, getAllTimeIntervals)]);
}
