import { deserialize } from 'deserialize-json-api';
import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

export type LegalEntity = {
  id: string;
  type: string;
  account_number: string;
  address: string;
  bank: string;
  bik: string;
  company_name: string;
  corr_account: string;
  default: boolean;
  inn: string;
  kpp: string;
  uuid: string;
};

export interface BillingDocuments {
  id: string;
  kind: string;
  month: number;
  token: string;
  type: string;
  uuid: string;
  year: number;
  thumb_url: string;
  url: string;
  legal_entity: LegalEntity;
}

export interface BillingDocumentsState {
  byId: { [key: string]: BillingDocuments };
  allIds: string[];
}

const initialState: BillingDocumentsState = {
  byId: {},
  allIds: [],
};

interface Action {
  payload: {
    attributes: Omit<BillingDocuments, 'id'>;
    id: string;
    type: string;
  }[];
}

const byId = handleActions(
  {
    [actions.getBillingDocumentsSuccess.toString()]: (state, { payload }: Action) => {
      return {
        ...state,
        ...deserialize(payload).data.reduce(
          (acc: { [key: string]: BillingDocuments }, billtingDocument: BillingDocuments) => ({
            ...acc,
            [billtingDocument.id]: { ...billtingDocument },
          }),
          {},
        ),
      };
    },
  },
  initialState.byId,
);

const allIds = handleActions(
  {
    [actions.getBillingDocumentsSuccess.toString()]: (state, { payload }: any) => [
      ...new Set([...state, ...deserialize(payload).data.map(({ id }: BillingDocuments) => id)]),
    ],
  },
  initialState.allIds,
);

export default combineReducers({
  byId,
  allIds,
});
