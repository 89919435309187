import Axios from 'axios';
import { all, call, debounce, put, takeLatest, takeLeading } from 'redux-saga/effects';
import { endEffectsStatuses, errorEffectsStatuses, startEffectsStatuses } from 'store/utils';
import * as actions from './actions';
import { User } from './reducers';

interface GetUserProps {
  payload: {
    id: string;
  };
}

function* getUser({ payload: { id } }: GetUserProps) {
  const name = 'getUser';

  try {
    yield startEffectsStatuses({ name });

    const {
      data: {
        data: { attributes: user },
      },
    } = yield call(Axios.request, {
      url: `/users/${id}`,
      method: 'GET',
    });

    yield put(actions.getUserSuccess([user]));

    yield endEffectsStatuses({ name });
  } catch (error) {
    yield put(actions.getUserFailure());

    yield errorEffectsStatuses({ name, error });
  }
}

interface GetUsersProps {
  payload: {
    first_name?: string;
    last_name?: string;
    patronymic?: string;
    email?: string;
    mobile_phone?: string;
    active?: string;
    role?: string;
    q?: string;
  };
}

function* getUsers({ payload }: GetUsersProps) {
  const name = 'getUsers';

  try {
    yield startEffectsStatuses({ name });

    const {
      data: { data: user },
    } = yield call(Axios.request, {
      url: `/users`,
      method: 'GET',
      params: payload,
    });

    yield put(actions.getUsersSuccess(user.map(({ attributes }: { attributes: User }) => ({ ...attributes }))));

    yield endEffectsStatuses({ name });
  } catch (error) {
    yield put(actions.getUsersFailure());

    yield errorEffectsStatuses({ name, error });
  }
}

interface CreateUserProps {
  payload: {
    first_name?: string;
    last_name?: string;
    patronymic?: string;
    email?: string;
    password?: string;
    role?: string[];
    mobile_phone?: string;
  };
}

function* createUser({ payload }: CreateUserProps) {
  const name = 'createUser';

  try {
    yield startEffectsStatuses({ name });

    const {
      data: {
        data: { attributes: user },
      },
    } = yield call(Axios.request, {
      url: `/users`,
      method: 'POST',
      data: { data: { attributes: payload } },
    });

    yield put(actions.createUserSuccess([user]));

    yield endEffectsStatuses({ name });
  } catch (error) {
    yield put(actions.createUserFailure());

    yield errorEffectsStatuses({ name, error });
  }
}

interface UpdateUserProps {
  payload: {
    id: string;
    first_name: string;
    last_name: string;
    patronymoc: string;
    email: string;
    password: string;
    roles: string[];
    mobile_phone: string;
  };
}

function* updateUser({ payload: { id, ...payload } }: UpdateUserProps) {
  const name = 'updateUser';

  try {
    yield startEffectsStatuses({ name });

    const {
      data: {
        data: { attributes: user },
      },
    } = yield call(Axios.request, {
      url: `/users/${id}`,
      method: 'PATCH',
      data: { data: { attributes: payload } },
    });

    yield put(actions.updateUserSuccess([user]));

    yield endEffectsStatuses({ name });
  } catch (error) {
    yield put(actions.updateUserFailure());

    yield errorEffectsStatuses({ name, error });
  }
}

interface BlockUserProps {
  payload: {
    id: string;
  };
}

function* blockUser({ payload: { id, ...props } }: BlockUserProps) {
  const name = 'blockUser';

  try {
    yield startEffectsStatuses({ name });

    const {
      data: {
        data: { attributes: user },
      },
    } = yield call(Axios.request, {
      url: `/users/${id}/block`,
      method: 'PATCH',
      data: props,
    });

    yield put(actions.blockUserSuccess([user]));

    yield endEffectsStatuses({ name });
  } catch (error) {
    yield put(actions.blockUserFailure());

    yield errorEffectsStatuses({ name, error });
  }
}

interface UnblockUserProps {
  payload: {
    id: string;
  };
}

function* unblockUser({ payload: { id, ...props } }: UnblockUserProps) {
  const name = 'unblockUser';

  try {
    yield startEffectsStatuses({ name });

    const {
      data: {
        data: { attributes: user },
      },
    } = yield call(Axios.request, {
      url: `/users/${id}/unblock`,
      method: 'PATCH',
      data: props,
    });

    yield put(actions.unblockUserSuccess([user]));

    yield endEffectsStatuses({ name });
  } catch (error) {
    yield put(actions.unblockUserFailure());

    yield errorEffectsStatuses({ name, error });
  }
}

interface ResetPasswordUserProps {
  payload: {
    id: string;
  };
}

function* resetPasswordUser({ payload: { id } }: ResetPasswordUserProps) {
  const name = 'resetPassword';

  try {
    yield startEffectsStatuses({ name });

    yield call(Axios.request, {
      url: `/users/${id}/reset_password`,
      method: 'PATCH',
    });

    yield put(actions.resetPasswordUserSuccess());

    yield endEffectsStatuses({ name });
  } catch (error) {
    yield put(actions.resetPasswordUserFailure());

    yield errorEffectsStatuses({ name, error });
  }
}

function* activateTwoFaUser({ payload: { id } }: ResetPasswordUserProps) {
  const name = 'activateTwoFaUser';

  try {
    yield startEffectsStatuses({ name });

    yield call(Axios.request, {
      url: `/users/${id}/activate_two_fa`,
      method: 'PATCH',
    });

    yield put(actions.activateTwoFaUserSuccess());

    yield endEffectsStatuses({ name });
  } catch (error) {
    yield put(actions.activateTwoFaUserFailure());

    yield errorEffectsStatuses({ name, error });
  }
}

function* disableTwoFaUser({ payload: { id } }: ResetPasswordUserProps) {
  const name = 'disableTwoFaUser';

  try {
    yield startEffectsStatuses({ name });

    yield call(Axios.request, {
      url: `/users/${id}/disable_two_fa`,
      method: 'PATCH',
    });

    yield put(actions.disableTwoFaUserSuccess());

    yield endEffectsStatuses({ name });
  } catch (error) {
    yield put(actions.disableTwoFaUserFailure());

    yield errorEffectsStatuses({ name, error });
  }
}

function* unlockIntrusionTwoFaUser({ payload: { id } }: ResetPasswordUserProps) {
  const name = 'unlockIntrusionTwoFaUser';

  try {
    yield startEffectsStatuses({ name });

    yield call(Axios.request, {
      url: `/users/${id}/unlock_intrusion`,
      method: 'PATCH',
    });

    yield put(actions.unlockIntrusionTwoFaUserSuccess());

    yield endEffectsStatuses({ name });
  } catch (error) {
    yield put(actions.unlockIntrusionTwoFaUserFailure());

    yield errorEffectsStatuses({ name, error });
  }
}

export default function* watchUsers() {
  yield all([
    takeLatest(actions.getUser, getUser),
    debounce(300, actions.getUsers, getUsers),
    takeLeading(actions.createUser, createUser),
    takeLeading(actions.updateUser, updateUser),
    takeLeading(actions.blockUser, blockUser),
    takeLeading(actions.unblockUser, unblockUser),
    takeLeading(actions.resetPasswordUser, resetPasswordUser),
    takeLeading(actions.activateTwoFaUser, activateTwoFaUser),
    takeLeading(actions.disableTwoFaUser, disableTwoFaUser),
    takeLeading(actions.unlockIntrusionTwoFaUser, unlockIntrusionTwoFaUser),
  ]);
}
