import { CommentOutlined, GoogleOutlined, StopOutlined } from '@ant-design/icons';
import cn from 'classnames';
import React from 'react';
import { TwoFaType as TwoFaTypeType } from 'store/users/reducers';
import styles from './TwoFaType.module.less';

type TwoFaTypeProps = Readonly<{
  twoFaType: TwoFaTypeType;
  size?: number;
}>;

const TwoFaType: React.FC<TwoFaTypeProps> = ({ twoFaType, size }) => {
  const icon = React.useMemo(() => {
    if (twoFaType === 'sms') return <CommentOutlined style={{ fontSize: size ? `${size}px` : '22px' }} />;
    if (twoFaType === 'authenticator')
      return <GoogleOutlined style={{ fontSize: size ? `${size}px` : '22px', color: '#4285f4' }} />;
    return <StopOutlined style={{ fontSize: size ? `${size}px` : '22px', color: '#bfbfbf' }} />;
  }, [twoFaType, size]);

  return <div className={cn(styles.TwoFaType)}>{icon}</div>;
};

export default TwoFaType;
