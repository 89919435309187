import React from 'react';

import cn from 'classnames';

import styles from './MobilePhoneField.module.less';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { useQuery } from 'shared/hooks';
import { Button, Typography } from 'antd';

const { Text } = Typography;

type MobilePhoneFieldProps = Readonly<{
  value?: string;
  type?: string;
  touched?: boolean;
  error?: string;
  onValueChange?: (values: NumberFormatValues) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}>;

const MobilePhoneField: React.FC<MobilePhoneFieldProps> = ({ value, touched, type, error, onValueChange, onBlur }) => {
  const queryParams = useQuery();

  const two_fa_mobile_phone = queryParams.get('two_fa_mobile_phone');

  const [isEdit, setIsEdit] = React.useState(false);

  const setTrueIsEdit = () => setIsEdit(true);

  if (type === 'sms' && two_fa_mobile_phone && !isEdit) {
    return (
      <div className={styles.MobilePhoneField}>
        Смс будет отпрвлена на номер{' '}
        <NumberFormat format="+# (###) #**-**-##" displayType="text" value={two_fa_mobile_phone} />
        <Button type="link" onClick={setTrueIsEdit}>
          Изменить
        </Button>
      </div>
    );
  }

  if (type === 'sms' && (!two_fa_mobile_phone || isEdit)) {
    return (
      <>
        <NumberFormat
          className={cn(styles.MobilePhoneField, 'ant-input')}
          placeholder="+7 (___) ___-__-__"
          name="mobile_phone"
          format="+7 (###) ###-##-##"
          mask="_"
          onBlur={onBlur}
          value={value}
          onValueChange={onValueChange}
        />
        {touched && error && <Text type="danger">{error}</Text>}
      </>
    );
  }
  return null;
};

export default MobilePhoneField;
