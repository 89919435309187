import React, { FC } from 'react';
import { Button, Input, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import cn from 'classnames';

import { State } from 'store';
import { sendRecoveryEmail } from 'store/passwordRecovery/actions';

import { initialValues, validationSchema } from './utils';

import styles from './ResetPassword.module.less';
import ReturnCard from 'shared/components/ReturnCard';

const { Text } = Typography;

const ResetPassword: FC = () => {
  const dispatch = useDispatch();

  const loading = useSelector((state: State) => state.loading.sendRecoveryEmail);

  const { values, touched, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      dispatch(sendRecoveryEmail({ ...values }));
    },
  });

  return (
    <div className={cn({ [styles.Hiden]: location.pathname !== '/authentication/reset_recovery' }, styles.Show)}>
      <ReturnCard title={'Восстановить пароль'} backUrl={'/authentication'}>
        <form onSubmit={handleSubmit} className={styles.ResetPassword}>
          <Input
            value={values.email}
            onBlur={handleBlur}
            onChange={handleChange}
            name="email"
            placeholder="Email"
            type="email"
            size="large"
          />
          {touched.email && errors.email && <Text type="danger">{errors.email}</Text>}

          <div className={styles.ControlPanel}>
            <Button size="large" type="primary" htmlType="submit" loading={loading}>
              Восстановить
            </Button>
          </div>
        </form>
      </ReturnCard>
    </div>
  );
};

export default ResetPassword;
