import React, { useMemo } from 'react';
import cn from 'classnames';
import { Button, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { FileProtectOutlined } from '@ant-design/icons';

import { State } from 'store';

import styles from './DownloadCertificateButton.module.less';
import { selectActiveDeliveryId, selectDelivery } from 'store/deliveries/selectors';

type DownloadCertificateButtonType = Readonly<{ loading?: boolean }>;

const DownloadCertificateButton: React.FC<DownloadCertificateButtonType> = ({ loading }) => {
  const state = useSelector((state: State) => state);
  const activeDeliveryId = selectActiveDeliveryId(state);
  const delivery = useMemo(() => selectDelivery(activeDeliveryId)(state), [activeDeliveryId, state]);

  return (
    <Tooltip title="Скачать фотографии, подписанные ЭЦП представителя">
      <Button
        type="link"
        icon={<FileProtectOutlined className={styles.Icon} />}
        className={cn(styles.DownloadCertificateButton)}
        loading={loading}
        style={{ color: delivery?.signed_photos && '#46d485' }}
        disabled={!delivery?.signed_photos || loading}
        size="large"
        onClick={() => window.open(delivery?.signed_photos)}
      />
    </Tooltip>
  );
};

export default DownloadCertificateButton;
