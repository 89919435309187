import React, { FC } from 'react';
import { Button, Input, Typography } from 'antd';
import { Redirect, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import cn from 'classnames';

import { passwordRecovery } from 'store/passwordRecovery/actions';
import { useQuery } from 'shared/hooks';

import { initialValues, validationSchema } from './utils';

import styles from './RecoveryPassword.module.less';
import ReturnCard from 'shared/components/ReturnCard';

const { Text } = Typography;

const RecoveryPassword: FC = () => {
  const dispatch = useDispatch();
  const queryParams = useQuery();
  const location = useLocation();

  const token = queryParams.get('token');
  const type = queryParams.get('type');

  const { values, touched, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ newPassword, confirmPassword: newPasswordConfirmation }) => {
      dispatch(passwordRecovery({ token, type, newPassword, newPasswordConfirmation }));
    },
  });

  if ((!token || !type) && location.pathname === '/authentication/password_recovery')
    return <Redirect to="/authentication/reset_recovery" />;

  return (
    <div className={cn(location.pathname === '/authentication/password_recovery' ? styles.Show : styles.Hiden)}>
      <ReturnCard title={'Задать пароль'} backUrl={'/authentication'}>
        <div className={cn(styles.RecoveryPassword)}>
          <form onSubmit={handleSubmit}>
            <Input
              name="newPassword"
              type="password"
              placeholder="Новый пароль"
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="new-password"
              value={values.newPassword}
              size="large"
            />
            {touched.newPassword && errors.newPassword && <Text type="danger">{errors.newPassword}</Text>}
            <Input
              name="confirmPassword"
              type="password"
              placeholder="Повторите новый пароль"
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="new-password"
              value={values.confirmPassword}
              size="large"
            />
            {touched.confirmPassword && errors.confirmPassword && <Text type="danger">{errors.confirmPassword}</Text>}

            <div className={styles.ControlPanel}>
              <Button type="primary" htmlType="submit" size="large">
                Задать
              </Button>
            </div>
          </form>
        </div>
      </ReturnCard>
    </div>
  );
};

export default RecoveryPassword;
