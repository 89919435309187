import { Table as TableAntd } from 'antd';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useDebounce } from 'react-use';
import CancelModal from 'shared/components/DeliveryRequest/components/CancelModal';
import OkModal from 'shared/components/DeliveryRequest/components/OkModal';
import RejectModal from 'shared/components/DeliveryRequest/components/RejectModal';
import { useOnTableChange, useUpdateFilters } from 'shared/utils/hooks';
import { selectLoadedFor } from 'store/common/selectors';
import { getDeliveryRequests } from 'store/deliveryRequests/actions';
import { selectDeliveryRequests } from 'store/deliveryRequests/selectors';
import { IFilters } from '../../DeliveryRequestsList';
import { useColumns } from './columns';
import styles from './Table.module.less';

interface ITableProps {
  filters: IFilters;
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>;
}

const Table = ({ filters, setFilters }: ITableProps) => {
  const {
    city,
    status,
    date_lte,
    date_gte,
    last_name,
    external_id,
    page,
    product_id,
    demo,
    created_by_id,
    request_date_gte,
    request_date_lte,
    delivery_status,
    unread,
    was_rejected,
  } = filters;

  const history = useHistory();

  const [id, setId] = useState('');
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [isRejectModal, setIsRejectModal] = useState(false);
  const [isOKModal, setIsOKModal] = useState(false);

  const updateFilters = useUpdateFilters({ setFilters, path: 'list' });
  const onTableChange = useOnTableChange({ setFilters });

  const dispatch = useDispatch();

  const state: any = useSelector((state) => state);

  const {
    deliveryRequests: {
      data: { total },
    },
  } = state;

  const isDeliveryRequestsLoaded = useMemo(() => selectLoadedFor('deliveryRequests')(state), [state]);
  const deliveryRequests = useMemo(() => _.values(selectDeliveryRequests(state)), [state]);

  const columns = useColumns({
    setIsCancelModal,
    setIsRejectModal,
    setIsOKModal,
    setId,
    filters,
    setFilters: updateFilters,
  });

  useDebounce(
    () => {
      dispatch(
        getDeliveryRequests({
          filter: {
            city,
            status,
            date_lte,
            date_gte,
            external_id: external_id?.trim(),
            last_name: last_name?.trim(),
            product_id,
            demo,
            created_by_id,
            request_date_gte,
            request_date_lte,
            delivery_status,
            unread,
            was_rejected,
          },
          include: ['active_delivery'],
          page,
        }),
      );
    },
    500,
    [
      city,
      status,
      date_lte,
      date_gte,
      external_id,
      last_name,
      product_id,
      page,
      demo,
      dispatch,
      setFilters,
      created_by_id,
      request_date_gte,
      request_date_lte,
      delivery_status,
      unread,
      was_rejected,
    ],
  );

  return (
    <div className={styles.Table}>
      <TableAntd
        columns={columns}
        dataSource={deliveryRequests.map(({ id, ...rest }) => ({ id, key: id, ...rest }))}
        loading={isDeliveryRequestsLoaded === false}
        // onChange={onTableChange}
        pagination={{
          showSizeChanger: false,
          defaultPageSize: 20,
          current: +page!,
          total,
          onChange: (page) => updateFilters((prev) => ({ ...prev, page: page.toString() })),
        }}
        onRow={({ uuid }) => ({
          onClick: () => history.push(`/delivery/requests/${uuid}`),
        })}
        onChange={onTableChange}
      />
      <CancelModal visible={isCancelModal} onCancel={() => setIsCancelModal(false)} id={id} />
      <RejectModal visible={isRejectModal} onCancel={() => setIsRejectModal(false)} id={id} />
      <OkModal visible={isOKModal} onCancel={() => setIsOKModal(false)} id={id} />
    </div>
  );
};

export default Table;
