import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { Card } from 'antd';
import cn from 'classnames';
import _ from 'lodash';

import { getDeliveryRequestStatuses } from 'store/deliveryRequestStatuses/actions';
import { IStatusColors, STATUS_COLORS } from 'shared/constants';

import ItemWrapper, { ItemWrapperProps } from './components/ItemWrapper/ItemWrapper';

import styles from './StatusBar.module.less';

const StatusBar = () => {
  const dispatch = useDispatch();

  const statuses = useSelector((state: State) => state.dashboard.data?.attributes.delivery_requests.by_status);
  const deliveryRequestStatuses = useSelector((state: State) => state.deliveryRequestStatuses.byId);
  const isDeliveryRequestStatusesLoaded = useSelector((state: State) => state.loaded.deliveryRequestStatuses);

  useEffect(() => {
    if (!isDeliveryRequestStatusesLoaded) {
      dispatch(getDeliveryRequestStatuses());
    }

    // eslint-disable-next-line
  }, []);

  const data: {
    executed?: ItemWrapperProps;
    validated?: ItemWrapperProps;
    canceled?: ItemWrapperProps;
    rejected?: ItemWrapperProps;
    confirmed?: ItemWrapperProps;
    created?: ItemWrapperProps;
  } = useMemo(() => {
    if (!statuses) return {};

    return _(statuses)
      .mapValues((value, key) => {
        return {
          color: STATUS_COLORS[key as keyof IStatusColors],
          label: deliveryRequestStatuses?.[key]?.name || key || 'Статус не известен',
          value,
          key,
          status: key,
        };
      })
      .value();
  }, [statuses, deliveryRequestStatuses]);

  return (
    <Card className={cn(styles.StatusBar)} title="Статусы">
      <div className={styles.Body}>
        {data.executed && <ItemWrapper {...data.executed} />}
        {data.validated && <ItemWrapper {...data.validated} />}
        {data.created && <ItemWrapper {...data.created} />}
        {data.confirmed && <ItemWrapper {...data.confirmed} />}
        {data.rejected && <ItemWrapper {...data.rejected} />}
        {data.canceled && <ItemWrapper {...data.canceled} />}
      </div>
    </Card>
  );
};

export default StatusBar;
