import { SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';

type UseOnTableChangeProps<T> = Readonly<{
  setFilters: React.Dispatch<React.SetStateAction<Readonly<T>>>;
}>;

export const useOnTableChange = <T>({ setFilters }: UseOnTableChangeProps<T>) => {
  return <S>(
    pagination: TablePaginationConfig,
    filters: Record<string, S | null>,
    sorter: SorterResult<S> | SorterResult<S>[],
    // extra: TableCurrentDataSource<S>,
  ) => {
    if (!Array.isArray(sorter)) {
      if (sorter.order === 'ascend')
        setFilters((prev) => ({
          ...prev,
          sort: `${sorter.columnKey}`,
          number: pagination.current,
        }));
      else if (sorter.order === 'descend')
        setFilters((prev) => ({
          ...prev,
          sort: `-${sorter.columnKey}`,
          number: pagination.current,
        }));
      else
        setFilters((prev) => ({
          ...prev,
          sort: `-id`,
          number: pagination.current,
        }));
    }
  };
};
