import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { Button, Select, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import ReturnCard from 'shared/components/ReturnCard';
import { twoFaType } from 'store/twoFa/actions';
import { useQuery } from 'shared/hooks';
import Os from 'shared/components/Os';
import { useFormik } from 'formik';
import { State } from 'store';

import { initialValues, validationSchema } from './utils';

import styles from './ChoiceTwoFa.module.less';
import { MobilePhoneField } from './components';

const { Text } = Typography;

const ChoiceTwoFa = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useQuery();
  const location = useLocation();

  const token = queryParams.get('token');
  const two_fa_uri = queryParams.get('two_fa_uri');
  const two_fa_mobile_phone = queryParams.get('two_fa_mobile_phone');

  const loading = useSelector((state: State) => state.loading.twoFaType);

  const { values, touched, errors, setFieldValue, handleBlur, handleSubmit, setFieldTouched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ type, mobile_phone }) => {
      dispatch(
        twoFaType({ type, token, two_fa_uri, mobile_phone: mobile_phone ? `+7${mobile_phone}` : two_fa_mobile_phone }),
      );
    },
  });

  React.useEffect(() => {
    !token &&
      location.pathname === '/authentication/choice_two_fa' &&
      history.push('/authentication', { from: '/authentication/choice_two_fa' });
  }, [token, location.pathname, history]);

  const description = React.useMemo(() => {
    if (values.type === 'sms') {
      return <div className={styles.Description}>Мы будем присылать вам код для входа по смс</div>;
    }
    if (values.type === 'authenticator') {
      return (
        <div className={styles.Description}>
          <span>Код для входа будет генерироваться в приложении Google Authenticator</span>
          <Os />
        </div>
      );
    }
    return null;
  }, [values]);

  return (
    <div className={cn({ [styles.Hiden]: location.pathname !== '/authentication/choice_two_fa' }, styles.Show)}>
      <ReturnCard
        title={'Настройка двухфакторной аутентификации'}
        backUrl={'/authentication'}
        subTitle="Выберите способ получения одноразового кода"
      >
        <div className={cn(styles.ChoiceTwoFa)}>
          <form onSubmit={handleSubmit}>
            <Select
              className={styles.SelectChoiceTwoFa}
              onChange={(v) => setFieldValue('type', v, true)}
              onBlur={() => setFieldTouched('type', true)}
              placeholder="Cпособ получения одноразового кода"
              value={values.type}
            >
              <Select.Option value={'sms'}>СМС</Select.Option>
              <Select.Option value={'authenticator'}>Google authenticator</Select.Option>
            </Select>
            {touched.type && errors.type && <Text type="danger">{errors.type}</Text>}
            {description}
            <MobilePhoneField
              value={values?.mobile_phone}
              touched={touched?.mobile_phone}
              type={values?.type}
              error={errors?.mobile_phone}
              onValueChange={({ value }) => setFieldValue('mobile_phone', value, true)}
              onBlur={handleBlur}
            />
            <div className={styles.ControlPanel}>
              <Button type="primary" htmlType="submit" loading={loading} size="large">
                Выбрать
              </Button>
            </div>
          </form>
        </div>
      </ReturnCard>
    </div>
  );
};

export default ChoiceTwoFa;
