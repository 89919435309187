import React from 'react';
import { DatePicker, DatePickerProps, Typography } from 'antd';
import moment from 'moment';

import { useInitialUpdateField } from 'pages/DeliveryRequest/utils';
import { SetFieldValue } from '../../useGenFormik';
import styles from './SelectDate.module.less';
import { State } from 'store';
import { useSelector } from 'react-redux';

type SelectDateProps = Readonly<{
  value?: string;
  setFieldValue: SetFieldValue;
  configBlur: any;
  touched?: boolean;
  error?: string;
}> &
  Omit<DatePickerProps, 'picker' | 'value'>;

const SelectDate: React.FC<SelectDateProps> = ({ value, setFieldValue, configBlur, touched, error, ...reset }) => {
  const currentUser = useSelector((state: State) => state.profile.data);

  const onBlurDate = useInitialUpdateField({
    name: 'date',
    updateMessage: 'Дата',
    ...configBlur,
  });

  if (!currentUser?.partner_settings?.schedules_self) return null;

  return (
    <div className={styles.SelectDate}>
      <DatePicker
        placeholder="Дата"
        value={value ? moment(value) : undefined}
        style={{ width: '100%' }}
        onChange={(m) => setFieldValue('date', m?.format('YYYY-MM-DD'), true)}
        onBlur={onBlurDate}
        disabledDate={(current) => current && current < moment().add(-1, 'day')}
        format="DD.MM.YYYY"
        className={styles.Select}
        {...reset}
      />
      {touched && error && <Typography.Text type="danger">{error}</Typography.Text>}
    </div>
  );
};

export default SelectDate;
