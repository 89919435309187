import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AutoComplete } from 'antd';
import cn from 'classnames';
import _ from 'lodash';

import { State } from 'store';
import { DaDataCityActions } from 'store/dadataCity/action';

import styles from './AutoCompleteAddress.module.less';

interface AutoCompleteAddressProps {
  city?: string;
  value?: string;
  onChange?: (value: any) => void;
  disabled?: boolean;
  className?: string;
  setLocation?: ([lat, lon]: [number, number]) => void;
  onBlur: (event: React.FocusEvent<HTMLElement>) => void;
  region?: string | null;
}

const AutoCompleteAddress = ({
  value,
  onChange,
  disabled,
  city,
  className,
  setLocation,
  onBlur,
  region,
}: AutoCompleteAddressProps) => {
  const dispatch = useDispatch();
  const [searchField, setSearchField] = useState(value);
  const daData = useSelector((state: State) => state.daDataCity.byId);
  const loading = useSelector((state: State) => state.loading.dadataCity);
  const options = useMemo(() => _.values(daData), [daData]);

  const locations = React.useMemo(() => {
    const locations: { region?: string; city?: string }[] = [];

    region && locations.push({ region });
    if (city) {
      locations.push({ region: city }, { city });
    }

    return locations;
  }, [city, region]);

  const handleSearch = useCallback(
    async (query) => {
      setSearchField(query);
      dispatch(DaDataCityActions.getDaDataCity({ locations, query }));
    },
    [dispatch, locations],
  );

  // Если ничего не введено то по дефолту ищем на букву "А" - крутите барабан
  useEffect(() => {
    dispatch(DaDataCityActions.getDaDataCity({ locations, query: 'а' }));
  }, [dispatch, locations]);

  useEffect(() => {
    value && setSearchField(value);
  }, [value]);

  const spiner = useMemo(() => {
    if (loading)
      return (
        <span role="img" aria-label="loading" className={cn('anticon anticon-loading anticon-spin', styles.Spiner)}>
          <svg
            viewBox="0 0 1024 1024"
            focusable="false"
            data-icon="loading"
            width="1em"
            height="1em"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill="#cacaca"
              d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"
            ></path>
          </svg>
        </span>
      );
    return null;
  }, [loading]);

  return (
    <div className={cn(styles.AutoCompleteAddress, className)}>
      <AutoComplete
        className={styles.AutoComplete}
        value={searchField}
        placeholder="Адрес"
        onSelect={(value, option) => {
          onChange?.({ value, city, option });
          setLocation?.([option.data.geo_lat, option.data.geo_lon]);
        }}
        onBlur={(e) => {
          onBlur(e);
        }}
        onChange={handleSearch}
        disabled={disabled}
        options={options}
        notFoundContent="Пусто 😧"
      />
      {spiner}
    </div>
  );
};

export default AutoCompleteAddress;
