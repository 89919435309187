/* eslint-disable object-curly-newline */
/* eslint-disable camelcase */
import { call, put, all, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import Axios from 'axios';

import { endEffectsStatuses, errorEffectsStatuses, startEffectsStatuses } from 'store/utils';

import * as authActions from '../auth/actions';
import * as actions from './actions';

interface PasswordRecoveryProps {
  payload: { token: string; newPassword: string; newPasswordConfirmation: string; type: string };
}

function* passwordRecovery({ payload: { token, type, newPassword, newPasswordConfirmation } }: PasswordRecoveryProps) {
  const name = 'passwordRecovery';

  try {
    yield startEffectsStatuses({ name });

    const {
      data: { token: authToken, expires_at },
    } = yield call(Axios.request, {
      url: 'password_recovery',
      method: 'PATCH',
      data: {
        data: {
          attributes: {
            type,
            token,
            password: newPassword,
            password_confirmation: newPasswordConfirmation,
          },
        },
      },
    });

    yield localStorage.setItem('token', `Bearer ${authToken}`);

    yield localStorage.setItem('expiresAt', expires_at);

    yield put(actions.passwordRecoverySuccess());

    yield put(authActions.createAuthenticationSuccess());

    yield put(push('/'));

    yield endEffectsStatuses({ name });
  } catch (error) {
    yield put(actions.passwordRecoveryFailure());

    yield errorEffectsStatuses({ name, error });
  }
}

interface SendRecoveryEmailProps {
  payload: {
    email: string;
  };
}

function* sendRecoveryEmail({ payload: { email } }: SendRecoveryEmailProps) {
  const name = 'sendRecoveryEmail';

  try {
    yield startEffectsStatuses({ name });

    yield call(Axios.request, {
      url: 'password_recovery',
      method: 'POST',
      data: {
        data: {
          attributes: {
            email,
          },
        },
      },
    });

    yield put(actions.sendRecoveryEmailSuccess());
    yield endEffectsStatuses({ name, message: 'Успех!', description: 'Проверьте почту' });
  } catch (error) {
    yield put(actions.sendRecoveryEmailFailure());

    yield errorEffectsStatuses({ name, error });
  }
}

export default function* watchUsers() {
  yield all([
    takeLatest(actions.passwordRecovery, passwordRecovery),
    takeLatest(actions.sendRecoveryEmail, sendRecoveryEmail),
  ]);
}
