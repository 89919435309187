import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

const initialState = {
  byId: {},
  allIds: [],
};

const byId = handleActions(
  {
    [actions.getDeliveryRequestStatusesSuccess]: (state, { payload }) => ({
      ...state,
      ...payload.reduce(
        (acc, status) => ({
          ...acc,
          [status.id]: { id: status.id, ...status.attributes },
        }),
        {},
      ),
    }),
  },
  initialState.byId,
);

const allIds = handleActions(
  {
    [actions.getDeliveryRequestStatusesSuccess]: (state, { payload }) => [
      ...new Set([...state, ...payload.map(({ id }) => id)]),
    ],
  },
  initialState.allIds,
);

export default combineReducers({
  byId,
  allIds,
});
