import React from 'react';
import styles from './SelectPhone.module.less';
import NumberFormat from 'react-number-format';
import { useInitialUpdateField } from 'pages/DeliveryRequest/utils';
import { SetFieldValue } from '../../useGenFormik';
import { Input, Typography } from 'antd';
import { selectDeliveryRequest } from 'store/deliveryRequests/selectors';
import { useSelector } from 'react-redux';
import { State } from 'store';
import { useParams } from 'react-router-dom';

type SelectPhoneProps = Readonly<{
  value?: string;
  setFieldValue: SetFieldValue;
  configBlur: any;
  touched?: boolean;
  error?: string;
}>;

const SelectPhone: React.FC<SelectPhoneProps> = ({ value, configBlur, touched, error, setFieldValue }) => {
  const { id } = useParams<{ id: string }>();

  const state = useSelector((state: State) => state);

  const deliveryRequest = selectDeliveryRequest(id)(state);

  const onBlurMobilePhone = useInitialUpdateField({
    name: 'mobile_phone',
    updateMessage: 'Телефон',
    modify: (v) => `+7${v}`,
    ...configBlur,
  });

  return (
    <div className={styles.SelectPhone}>
      <NumberFormat
        placeholder="+7 (___) ___-__-__"
        name="mobile_phone"
        format="+7 (###) ###-##-##"
        mask="_"
        className={styles.Select}
        onBlur={onBlurMobilePhone}
        value={value}
        onValueChange={({ value }) => setFieldValue('mobile_phone', value, true)}
        customInput={Input}
      />
      {deliveryRequest?.destination?.mobile_phone && (
        <div>
          <Typography.Text type="secondary" className={styles.Hint}>
            <NumberFormat
              value={deliveryRequest?.destination?.mobile_phone}
              allowEmptyFormatting
              displayType="text"
              format={
                /\+\d{5}\*\*\*\*\d{2}$/.test(deliveryRequest?.destination?.mobile_phone)
                  ? '+# (###) #**-**-##'
                  : '+# (###) ###-##-##'
              }
            />
          </Typography.Text>
        </div>
      )}
      {touched && error && <Typography.Text type="danger">{error}</Typography.Text>}
    </div>
  );
};

export default SelectPhone;
