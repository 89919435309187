import { Select, Typography } from 'antd';
import _ from 'lodash';
import { useInitialUpdateField } from 'pages/DeliveryRequest/utils';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { selectLoadedFor, selectLoadingFor } from 'store/common/selectors';
import { getProducts } from 'store/products/actions';
import { selectProducts } from 'store/products/selectors';
import { SetFieldValue } from '../../useGenFormik';
import styles from './SelectProduct.module.less';

type SelectProductProps = Readonly<{
  value?: string;
  setFieldValue: SetFieldValue;
  configBlur: any;
  touched?: boolean;
  error?: string;
}>;

const SelectProduct: React.FC<SelectProductProps> = ({ value, setFieldValue, configBlur, touched, error }) => {
  const dispatch = useDispatch();

  const state = useSelector((state: State) => state);

  const isProductsLoaded = selectLoadedFor('products')(state);
  const isProductsLoading = selectLoadingFor('products')(state);
  const products = selectProducts(state);

  const onBlurProduct = useInitialUpdateField({
    name: 'product',
    updateMessage: 'Продукт',
    ...configBlur,
  });

  React.useEffect(() => {
    if (!isProductsLoaded) {
      dispatch(getProducts());
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Select
        placeholder="Продукт"
        value={value}
        onChange={(v) => setFieldValue('product', v, true)}
        loading={isProductsLoading}
        className={styles.SelectProduct}
        optionLabelProp="label"
        onBlur={onBlurProduct}
      >
        {_(products)
          .values()
          .value()
          ?.map(({ id, code, name, description }) => (
            <Select.Option key={id} value={code} label={name}>
              <div>
                <div>
                  <Typography.Text>{name}</Typography.Text>
                </div>
                <div>
                  <Typography.Text type="secondary">{description}</Typography.Text>
                </div>
              </div>
            </Select.Option>
          ))}
      </Select>
      {touched && error && <Typography.Text type="danger">{error}</Typography.Text>}
    </div>
  );
};

export default SelectProduct;
