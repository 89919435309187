import { Checkbox, PageHeader, Switch } from 'antd';
import { NotFound } from 'pages';
import qs from 'qs';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useUpdateFilters } from 'shared/utils/hooks';
import { State } from 'store';
import Download from './components/Download';
import Table from './components/Table';
import Upload from './components/Upload';
import styles from './DeliveryRequestsList.module.less';

export interface IFilters {
  city?: string;
  status?: string;
  date_lte?: string;
  date_gte?: string;
  request_date_gte?: string;
  request_date_lte?: string;
  last_name?: string;
  external_id?: string;
  page?: string;
  product_id?: string;
  demo?: boolean;
  created_by_id?: number;
  delivery_status?: string;
  unread?: boolean;
  was_rejected?: boolean;
}

const DeliveryRequestsList = () => {
  const history = useHistory();

  const [filters, setFilters] = useState<IFilters>({ page: '1' });

  const error = useSelector((state: State) => state.error[`deliveryRequests`]);

  const updateFilters = useUpdateFilters({ setFilters, path: 'list' });

  React.useEffect(() => {
    const params: any = qs.parse(location.search?.split('?')[1]);

    setFilters((prev) => ({
      ...prev,
      ...params,
      demo: params?.demo === 'true' ? true : false,
    }));
  }, []);

  if (error) return <NotFound status={error.status} title={error.status} subTitle={error.title} />;

  return (
    <div className={styles.DeliveryRequestsList}>
      <Helmet title="Мои доставки" />
      <PageHeader
        onBack={history.goBack}
        title="Мои доставки"
        className={styles.PageHeader}
        extra={
          <div className={styles.Extra}>
            <Checkbox
              onChange={() => {
                setFilters((prev) => {
                  if (prev.unread === true) {
                    return {
                      ...prev,
                      unread: undefined,
                    };
                  }

                  return {
                    ...prev,
                    unread: true,
                  };
                });
              }}
              checked={filters.unread}
            >
              Непрочитанные комментарии
            </Checkbox>
            <Checkbox
              onChange={() => {
                setFilters((prev) => {
                  if (prev.was_rejected === true) {
                    return {
                      ...prev,
                      was_rejected: undefined,
                    };
                  }

                  return {
                    ...prev,
                    was_rejected: true,
                  };
                });
              }}
              checked={filters.was_rejected}
            >
              Было отклонение
            </Checkbox>
            <Switch
              checkedChildren="Demo"
              unCheckedChildren="Demo"
              checked={filters.demo}
              onChange={(v) => updateFilters((prev) => ({ ...prev, page: '1', demo: v }))}
            />
            <Upload />
            <Download filters={filters} />
          </div>
        }
      >
        {/* <Filters {...{ filters, setFilters }} /> */}
      </PageHeader>
      <Table {...{ filters, setFilters }} />
    </div>
  );
};

export default DeliveryRequestsList;
