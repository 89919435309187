import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';

import { authentication, resetRecovery, passwordRecovery, choiceTwoFa, checkTwoFa } from '../../Authentication.const';

const TitleHelmet = () => {
  const location = useLocation();

  if (location.pathname === authentication) return <Helmet title="Аутентификация" />;
  if (location.pathname === resetRecovery) return <Helmet title="Восстановить пароль" />;
  if (location.pathname === passwordRecovery) return <Helmet title="Задать пароль" />;
  if (location.pathname === choiceTwoFa) return <Helmet title="Настройка двухфакторной аутентификации" />;
  if (location.pathname === checkTwoFa) return <Helmet title="Активируйте двухфакторную аутентификацию" />;

  return null;
};

export default TitleHelmet;
