import { createSelector } from 'reselect';

import { State } from '..';

export const selectDocumentsState = (store: State) => store.documents;

export const selectDocuments = createSelector(selectDocumentsState, (documentsState) => documentsState.byId);

export const selectDocument = (id: string) => createSelector(selectDocuments, (documents) => documents[id]);

export const selectTotaldocuments = createSelector(selectDocumentsState, (documentsState) => documentsState.data.total);
