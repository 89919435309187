import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { SaveOutlined, CloseOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography } from 'antd';
import { useFormik } from 'formik';
import cn from 'classnames';
import * as Yup from 'yup';

import AutoCompleteFio from 'shared/components/AutoCompleteFio';
import WrapperField from 'shared/components/WrapperField';
import { getUser, updateUser } from 'store/users/actions';
import makeFullName from 'shared/utils/makeFullName';
import useCheckModal from 'shared/utils/checkModal';
import { User } from 'store/users/reducers';
import { State } from 'store';

import styles from './FullName.module.less';

const { Text } = Typography;

const validationSchema = Yup.object({
  fullName: Yup.string()
    .test('cyrillicAlphabet', 'Только русские буквы', (v) => !/[^а-яё .]/iu.test(v || ''))
    .test('required FirstName', 'Фамилия и Имя обязательные поля', (v) =>
      /^([а-яё.]{1,})( +)([а-яё.]{1,})( +)?([а-яё.]{1,})?$/i.test(v || ''),
    )
    .required('Обязательное поле'),
});

interface FullNameProps {
  user: User;
}

const FullName: FC<FullNameProps> = ({ user }) => {
  const dispatch = useDispatch();
  const [isModalVisible, setVisible] = useState(false);

  const handlerEdit = useCallback(() => setVisible(true), []);

  const loading = useSelector((state: State) => state.loading.updateUser);
  const error = useSelector((state: State) => state.error.updateUser);

  useCheckModal({
    isModalVisible,
    loading,
    error,
    onClose: () => {
      setVisible(false);
      dispatch(getUser({ id: user.id }));
    },
  });

  const initialValues = useMemo(
    () => ({
      fullName: makeFullName(user).trim() || '',
    }),
    [user],
  );

  const { touched, errors, values, setFieldTouched, setValues, handleSubmit, resetForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const [last_name = '', first_name = '', patronymic = ''] = values.fullName.split(' ');

      dispatch(
        updateUser({
          id: user.id,
          last_name,
          first_name,
          patronymic,
        }),
      );
    },
  });

  const value = useMemo(() => {
    if (isModalVisible)
      return (
        <div className={styles.EditPanel}>
          <AutoCompleteFio
            value={values.fullName}
            onChange={(value) => setValues({ fullName: value })}
            onBlur={() => setFieldTouched('fullName', true, true)}
          />
          <Button type="link" onClick={() => handleSubmit()} loading={loading}>
            {!loading && <SaveOutlined style={{ fontSize: '24px' }} />}
          </Button>
          <Button type="link" onClick={() => setVisible(false)} loading={loading}>
            {!loading && <CloseOutlined style={{ fontSize: '24px', color: 'rgb(140 140 140)' }} />}
          </Button>
          {touched.fullName && errors.fullName && <Text type="danger">{errors.fullName}</Text>}
        </div>
      );

    return makeFullName(user);
  }, [
    isModalVisible,
    errors.fullName,
    setFieldTouched,
    handleSubmit,
    touched.fullName,
    user,
    values.fullName,
    loading,
    setValues,
  ]);

  useEffect(() => {
    isModalVisible && resetForm();
    isModalVisible && setValues(initialValues);
  }, [isModalVisible, setValues, initialValues, resetForm]);

  return (
    <div className={cn(styles.FullName)}>
      <WrapperField value={value} title="ФИО" handlerEdit={isModalVisible ? undefined : handlerEdit} />
    </div>
  );
};

export default memo(FullName);
