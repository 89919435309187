import { put, call, takeLeading, all } from 'redux-saga/effects';
import { endEffectsStatuses, errorEffectsStatuses, getError, startEffectsStatuses } from 'store/utils';
import Axios from 'axios';

import * as actions from './actions';

function* createReports({ payload: { type, from, to } }: { payload: { type: string; from: string; to: string } }) {
  const name = 'reports';

  try {
    yield startEffectsStatuses({ name });

    const response = yield call(Axios.request, {
      responseType: 'blob',
      url: '/reports',
      method: 'POST',
      data: {
        data: {
          attributes: {
            type,
            from,
            to,
          },
        },
      },
    });

    if (response.status === 201) {
      const message = yield response.data?.text();
      yield put(actions.createReportsSuccess());
      yield endEffectsStatuses({ name, message });
      return;
    }

    const nameFile: string = response.headers['content-disposition'].split('filename=')[1].split(';')[0].split('.')[0];
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(response.data);
    link.setAttribute('download', nameFile);

    link.click();

    yield put(actions.createReportsSuccess());
    yield endEffectsStatuses({ name });
  } catch (error) {
    yield put(actions.createReportsFailure());

    const readyError = yield getError(error);

    yield errorEffectsStatuses({
      name,
      error: readyError,
    });
  }
}

export default function* watchReports() {
  yield all([takeLeading(actions.createReports, createReports)]);
}
