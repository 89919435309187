/* eslint-disable camelcase */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { createContainer } from 'unstated-next';
import { Helmet } from 'react-helmet';

import { getDeliveryRequestStatuses } from 'store/deliveryRequestStatuses/actions';
import { getDeliveryStatuses } from 'store/deliveryStatuses/actions';
import { getDeliveryRequest } from 'store/deliveryRequests/actions';
import { getFailureReasons } from 'store/failureReasons/actions';
import { getPhotoTypes, getPhotoTypesSuccess } from 'store/photoTypes/actions';
import { getProducts } from 'store/products/actions';
import { getCities } from 'store/cities/actions';

import { selectLoadedFor, selectLoadingFor } from 'store/common/selectors';
import { getDocumentsDeliveryRequest } from 'store/documents/actions';

import NotFoundRequest from './components/NotFoundRequest';
import ContentRequest from './components/Content';

import styles from './DeliveryRequestsShow.module.less';
import { NotFound } from 'pages';
import { State } from 'store';
import { getPhoneCallHistory } from 'store/phoneCallHistory/actions';
import { selectActiveDeliveryId, selectDelivery } from 'store/deliveries/selectors';

function useDeliveryRequestStatusesLoadedCounter() {
  const { id = '' }: { id: string } = useParams();

  const state = useSelector((state) => state);

  const isDeliveryRequestStatusesLoaded = React.useMemo(() => selectLoadedFor('deliveryRequestStatuses')(state), [
    state,
  ]);
  const isDeliveryRequestLoaded = React.useMemo(() => selectLoadedFor(`deliveryRequest_${id}`)(state), [id, state]);
  const isDeliveryStatusesLoaded = React.useMemo(() => selectLoadedFor('deliveryStatuses')(state), [state]);
  const isPhotoTypesLoaded = React.useMemo(() => selectLoadedFor('photoTypes')(state), [state]);
  const isProductsLoaded = React.useMemo(() => selectLoadedFor('products')(state), [state]);

  const isDeliveryRequestLoading = React.useMemo(() => selectLoadingFor(`deliveryRequest_${id}`)(state), [id, state]);

  const isEverythigReady = React.useMemo(
    () => isProductsLoaded && isPhotoTypesLoaded && isDeliveryStatusesLoaded && isDeliveryRequestStatusesLoaded,
    [isProductsLoaded, isPhotoTypesLoaded, isDeliveryStatusesLoaded, isDeliveryRequestStatusesLoaded],
  );

  const isNotFound = React.useMemo(() => isDeliveryRequestLoading === false && isDeliveryRequestLoaded === false, [
    isDeliveryRequestLoading,
    isDeliveryRequestLoaded,
  ]);

  return { isNotFound, isEverythigReady };
}

export const deliveryRequestStatusesLoadedCounter = createContainer(useDeliveryRequestStatusesLoadedCounter);

const DeliveryRequestsShow = () => {
  const dispatch = useDispatch();

  const { id = '' }: { id: string } = useParams();

  const state = useSelector((state) => state);

  const error = useSelector((state: State) => state.error[`deliveryRequest_${id}`]);

  const isDeliveryRequestStatusesLoaded = React.useMemo(() => selectLoadedFor('deliveryRequestStatuses')(state), [
    state,
  ]);
  const isDeliveryStatusesLoaded = React.useMemo(() => selectLoadedFor('deliveryStatuses')(state), [state]);
  const isFailureReasons = React.useMemo(() => selectLoadedFor('failureReasons')(state), [state]);
  const isProductsLoaded = React.useMemo(() => selectLoadedFor('products')(state), [state]);
  const isCitiesLoaded = React.useMemo(() => selectLoadedFor('cities')(state), [state]);
  const activeDeliveryId = selectActiveDeliveryId(state);
  const delivery = React.useMemo(() => selectDelivery(activeDeliveryId)(state), [activeDeliveryId, state]);

  React.useEffect(() => {
    dispatch(getDocumentsDeliveryRequest({ id }));
    dispatch(getDeliveryRequest({ id }));

    dispatch(getPhoneCallHistory({ uuid: id }));

    if (!isFailureReasons) {
      dispatch(getFailureReasons());
    }

    if (!isCitiesLoaded) {
      dispatch(getCities());
    }

    if (!isProductsLoaded) {
      dispatch(getProducts());
    }

    if (!isDeliveryStatusesLoaded) {
      dispatch(getDeliveryStatuses());
    }

    if (!isDeliveryRequestStatusesLoaded) {
      dispatch(getDeliveryRequestStatuses());
    }

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (delivery?.photos) {
      dispatch(getPhotoTypes({ params: { filter: { codes: delivery?.photos?.map(({ type }: any) => type) } } }));
    } else {
      dispatch(getPhotoTypesSuccess([]));
    }
  }, [delivery?.photos, dispatch]);

  if (error) return <NotFound status={error.status} title={error.status} subTitle={error.title} />;

  return (
    <div className={styles.DeliveryRequestsShow}>
      <Helmet title={'Заявка'} />

      <deliveryRequestStatusesLoadedCounter.Provider>
        <NotFoundRequest />
        <ContentRequest />
      </deliveryRequestStatusesLoadedCounter.Provider>
    </div>
  );
};

export default React.memo(DeliveryRequestsShow);
