import Button from 'antd/es/button';
import cn from 'classnames';
import React, { FC, memo, useMemo, useState } from 'react';
import WrapperField from 'shared/components/WrapperField';
import { User } from 'store/users/reducers';
import EditModal from './components/EditModal';
import styles from './ResetPassword.module.less';

interface ResetPasswordProps {
  user: User;
}

const ResetPassword: FC<ResetPasswordProps> = ({ user }) => {
  const [isModalVisible, setVisible] = useState(false);

  const block = useMemo(
    () => (
      <Button type="link" className={styles.Button} onClick={() => setVisible(true)}>
        Сбросить пароль
      </Button>
    ),
    [setVisible],
  );

  return (
    <div className={cn(styles.ResetPassword)}>
      <WrapperField value={block} />
      <EditModal {...{ isModalVisible, setVisible, user }} />
    </div>
  );
};

export default memo(ResetPassword);
