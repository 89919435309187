import React, { FC } from 'react';

interface IIConProps {
  size?: string | number;
  width?: string | number;
  height?: string | number;
  fill?: string;
  mask?: string | number;
  className?: string;
  viewBox?: string;
  colors?: [string, string];
  style?: React.CSSProperties;
}

const defaultIconsProps = {
  size: 14,
  fill: 'black',
};

export const Logo: FC<IIConProps> = ({
  size,
  fill,
  className,
  width,
  height,
  viewBox = '0 0 46 22',
  colors = [null, null],
  style,
}) => {
  return (
    <svg
      width={width || size}
      height={height || size}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <metadata id="metadata8">image/svg+xml</metadata>
      <defs>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath18">
          <path d="m-81.012796,116.691104l166.904,0l0,-100.662l-166.904,0l0,100.662z" id="path16" fill={fill} />
        </clipPath>
      </defs>
      <g>
        <title>background</title>
        <rect fill="none" id="canvas_background" height={height || size} width={width || size} y="-1" x="-1" />
        <title>Layer 1</title>
        <g id="g10" transform="matrix(1.3333333,0,0,-1.3333333,0,134.216) ">
          <g id="g12">
            <g id="g14" clipPath="url(#clipPath18)">
              <g id="g20">
                <path
                  d="m21.682501,94.367804c-0.106,0 -0.213,0.006 -0.321,0.019c-1.129,0.132 -2.175,0.972 -3.199,2.566l0.842,0.541c0.84,-1.309 1.672,-2.02 2.474,-2.114c1.173,-0.148 2.44,1.058 3.648,2.218c1.381,1.326 2.799,2.692 4.483,2.5c1.142,-0.134 2.203,-0.987 3.242,-2.604l-0.842,-0.541c-0.854,1.332 -1.702,2.056 -2.517,2.152c-1.206,0.154 -2.459,-1.062 -3.672,-2.228c-1.286,-1.235 -2.614,-2.509 -4.138,-2.509"
                  id="path22"
                  fill={colors[1] || fill}
                />
              </g>
              <g id="g24">
                <path
                  d="m2.995001,86.664701c-0.087,0 -0.175,0.005 -0.264,0.015c-0.971,0.113 -1.865,0.859 -2.731,2.278l0.853,0.521c0.686,-1.124 1.357,-1.731 1.995,-1.806c0.912,-0.097 1.949,0.918 2.931,1.91c1.155,1.167 2.344,2.381 3.778,2.207c0.983,-0.114 1.887,-0.87 2.766,-2.311l-0.853,-0.521c-0.699,1.145 -1.381,1.764 -2.03,1.839c-0.947,0.13 -1.964,-0.921 -2.95,-1.918c-1.079,-1.09 -2.191,-2.214 -3.495,-2.214"
                  id="path26"
                  fill={colors[0] || fill}
                />
              </g>
              <g id="g84">
                <path
                  d="m33.764601,92.532301l0,0.114l0.465,0l0,-0.114l-0.164,0l0,-0.387l-0.136,0l0,0.387l-0.165,0z"
                  id="path86"
                  fill={colors[1] || fill}
                />
              </g>
              <g id="g88">
                <path
                  d="m34.271406,92.145605l0,0.5l0.182,0l0.101,-0.21l0.026,0l0.1,0.21l0.181,0l0,-0.5l-0.133,0l0,0.319l-0.025,0l-0.08,-0.161l-0.114,0l-0.08,0.161l-0.025,0l0,-0.319l-0.133,0z"
                  id="path90"
                  fill={colors[1] || fill}
                />
              </g>
              <g id="g174">
                <path
                  d="m3.395407,97.691008l-1.161,0l0,-3.574l-1.26,0l0,3.574l-0.676,0l0,1.053l0.676,0l0,0.334c0,1.052 0.612,1.584 1.827,1.584l0.594,0l0,-1.054l-0.576,0c-0.387,0 -0.585,-0.197 -0.585,-0.594l0,-0.27l1.161,0l0,-1.053z"
                  id="path176"
                  fill={colors[0] || fill}
                />
              </g>
              <g id="g178">
                <path
                  d="m6.951101,95.971304l0,0.288l-0.99,0c-0.576,0 -0.864,-0.207 -0.864,-0.621c0,-0.423 0.269,-0.64 0.819,-0.64c0.602,0 1.035,0.406 1.035,0.973m0.054,-1.854l0,0.396l-0.234,0c-0.288,-0.343 -0.72,-0.514 -1.297,-0.514c-0.503,0 -0.9,0.145 -1.197,0.442c-0.297,0.297 -0.441,0.666 -0.441,1.098c0,0.414 0.153,0.774 0.469,1.08c0.314,0.306 0.783,0.459 1.413,0.459l1.233,0l0,0.118c0,0.476 -0.271,0.719 -0.819,0.719c-0.487,0 -0.747,-0.162 -0.784,-0.495l-1.251,0c0.018,0.442 0.208,0.783 0.567,1.045c0.369,0.261 0.838,0.396 1.405,0.396c1.323,0 2.098,-0.549 2.098,-1.738l0,-3.006l-1.162,0z"
                  fill={colors[0] || fill}
                  id="path180"
                />
              </g>
              <g id="g182">
                <path
                  d="m11.585806,97.475203c-0.045,0.278 -0.297,0.414 -0.747,0.414c-0.45,0 -0.675,-0.136 -0.675,-0.396c0,-0.244 0.144,-0.316 0.495,-0.378l0.837,-0.126c0.982,-0.154 1.477,-0.649 1.477,-1.486c0,-0.414 -0.18,-0.765 -0.54,-1.062c-0.361,-0.297 -0.865,-0.442 -1.513,-0.442c-1.359,0 -2.079,0.595 -2.179,1.576l1.252,0c0.063,-0.405 0.368,-0.603 0.918,-0.603c0.531,0 0.801,0.171 0.801,0.504c0,0.297 -0.188,0.433 -0.666,0.514l-0.9,0.143c-0.829,0.144 -1.243,0.568 -1.243,1.279c0,0.855 0.675,1.449 1.972,1.449c1.125,0 1.863,-0.549 1.963,-1.386l-1.252,0z"
                  id="path184"
                  fill={colors[0] || fill}
                />
              </g>
              <g id="g186">
                <path
                  d="m15.293905,98.743803l1.018,0l0,-1.053l-1.018,0l0,-1.99c0,-0.342 0.198,-0.513 0.595,-0.513l0.423,0l0,-1.071l-0.442,0c-1.161,0 -1.836,0.378 -1.836,1.432l0,2.142l-0.676,0l0,1.053l0.676,0l0,1.297l1.26,0l0,-1.297z"
                  id="path188"
                  fill={colors[0] || fill}
                />
              </g>
              <g id="g190">
                <path
                  d="m16.505804,92.000601l0,-1.17l-0.45,0c-0.946,0 -1.413,-0.477 -1.413,-1.432l0,-2.025l-1.261,0l0,4.627l1.216,0l0,-0.521l0.233,0c0.243,0.35 0.657,0.521 1.243,0.521l0.432,0z"
                  id="path192"
                  fill={colors[0] || fill}
                />
              </g>
              <path
                d="m18.504204,87.374104l-1.261,0l0,4.627l1.261,0l0,-4.627zm0.008,5.419l-1.278,0l0,1.125l1.278,0l0,-1.125z"
                id="path194"
                fill={colors[1] || fill}
              />
              <g id="g196">
                <path
                  d="m20.492102,87.373602l-1.314,4.627l1.288,0l0.954,-3.502l0.386,0l0.919,3.502l1.288,0l-1.333,-4.627l-2.188,0z"
                  id="path198"
                  fill={colors[1] || fill}
                />
              </g>
              <g id="g200">
                <path
                  d="m25.541906,90.380507l1.98,0c-0.071,0.495 -0.404,0.792 -0.954,0.792c-0.548,0 -0.89,-0.261 -1.026,-0.792m3.269,-0.837l-3.314,0c0.036,-0.828 0.414,-1.242 1.126,-1.242c0.45,0 0.738,0.161 0.864,0.485l1.279,0c-0.19,-0.945 -0.946,-1.53 -2.162,-1.53c-0.729,0 -1.305,0.226 -1.728,0.666c-0.423,0.441 -0.63,1.035 -0.63,1.783c0,0.738 0.207,1.323 0.63,1.755c0.423,0.441 0.99,0.657 1.701,0.657c0.685,0 1.225,-0.207 1.621,-0.612c0.397,-0.404 0.604,-0.936 0.613,-1.584l0,-0.378z"
                  id="path202"
                  fill={colors[1] || fill}
                />
              </g>
              <g id="g204">
                <path
                  d="m32.643507,92.000601l0,-1.17l-0.45,0c-0.946,0 -1.413,-0.477 -1.413,-1.432l0,-2.025l-1.261,0l0,4.627l1.216,0l0,-0.521l0.233,0c0.243,0.35 0.657,0.521 1.243,0.521l0.432,0z"
                  id="path206"
                  fill={colors[1] || fill}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Logo.defaultProps = defaultIconsProps;
