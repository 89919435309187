import React from 'react';

import { QueryClient, DefaultOptions, QueryClientProvider } from 'react-query';

export const queryConfig: DefaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
    retry: 2,
  },
  mutations: {
    useErrorBoundary: false,
  },
};

export const queryClient = new QueryClient({
  defaultOptions: queryConfig,
});

export const ReactQuery: React.FC = ({ children }) => {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
