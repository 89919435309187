import React, { FC, memo, useCallback, useMemo, useState, useEffect } from 'react';
import { SaveOutlined, CloseOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { Button, Typography } from 'antd';
import { useFormik } from 'formik';
import cn from 'classnames';
import * as Yup from 'yup';

import WrapperField from 'shared/components/WrapperField';
import { getUser, updateUser } from 'store/users/actions';
import useCheckModal from 'shared/utils/checkModal';
import { User } from 'store/users/reducers';
import { State } from 'store';

import styles from './MobilePhone.module.less';

const { Text } = Typography;

const validationSchema = Yup.object({});

interface MobilePhoneProps {
  user: User;
}

const MobilePhone: FC<MobilePhoneProps> = ({ user }) => {
  const dispatch = useDispatch();
  const [isModalVisible, setVisible] = useState(false);

  const handlerEdit = useCallback(() => setVisible(true), []);

  const loading = useSelector((state: State) => state.loading.updateUser);
  const error = useSelector((state: State) => state.error.updateUser);

  useCheckModal({
    isModalVisible,
    loading,
    error,
    onClose: () => {
      setVisible(false);
      dispatch(getUser({ id: user.id }));
    },
  });

  const initialValues = useMemo(
    () => ({
      mobile_phone: user.mobile_phone || '',
    }),
    [user],
  );

  const { touched, errors, values, handleBlur, setFieldValue, setValues, handleSubmit, resetForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      dispatch(
        updateUser({
          id: user.id,
          ...values,
        }),
      );
    },
  });

  const value = useMemo(() => {
    if (isModalVisible)
      return (
        <div className={styles.EditPanel}>
          <NumberFormat
            placeholder="+7 (___) ___-__-__"
            name="mobile_phone"
            format="+7 (###) ###-##-##"
            className="ant-input"
            mask="_"
            onBlur={handleBlur}
            value={values.mobile_phone}
            onValueChange={({ value }) => setFieldValue('mobile_phone', value, true)}
          />
          <Button type="link" onClick={() => handleSubmit()} loading={loading}>
            {!loading && <SaveOutlined style={{ fontSize: '24px' }} />}
          </Button>
          <Button type="link" onClick={() => setVisible(false)} loading={loading}>
            {!loading && <CloseOutlined style={{ fontSize: '24px', color: 'rgb(140 140 140)' }} />}
          </Button>
          {touched.mobile_phone && errors.mobile_phone && <Text type="danger">{errors.mobile_phone}</Text>}
        </div>
      );

    return user.mobile_phone;
  }, [
    touched.mobile_phone,
    errors.mobile_phone,
    values.mobile_phone,
    isModalVisible,
    setFieldValue,
    handleSubmit,
    handleBlur,
    loading,
    user,
  ]);

  useEffect(() => {
    isModalVisible && resetForm();
    isModalVisible && setValues(initialValues);
  }, [isModalVisible, setValues, initialValues, resetForm]);

  return (
    <div className={cn(styles.MobilePhone)}>
      <WrapperField value={value} title="Телефон" handlerEdit={isModalVisible ? undefined : handlerEdit} />
    </div>
  );
};

export default memo(MobilePhone);
