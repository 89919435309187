import React, { FC, memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button, Result } from 'antd';

import { deliveryRequestStatusesLoadedCounter } from '../../DeliveryRequestsShow';

const NotFoundRequest: FC<unknown> = () => {
  const { isEverythigReady, isNotFound } = deliveryRequestStatusesLoadedCounter.useContainer();

  const isShow = useMemo(() => !!(isEverythigReady && isNotFound), [isEverythigReady, isNotFound]);

  return useMemo(() => {
    if (isShow)
      return (
        <Result
          status="404"
          title="404"
          subTitle="Заявка не найдена"
          extra={
            <Link to="/delivery/requests/list">
              <Button type="primary">Перейти к списку заявок</Button>
            </Link>
          }
        />
      );

    return null;
  }, [isShow]);
};

export default memo(NotFoundRequest);
