import { all } from 'redux-saga/effects';
import watchAuth from './auth/sagas';
import billingDocuments from './billingDocuments/sagas';
import watchCanselReasons from './cancelReasons/sagas';
import watchCitieAreas from './citieAreas/sagas';
import watchCities from './cities/sagas';
import watchDadataCity from './dadataCity/sagas';
import watchDadataFio from './dadataFio/sagas';
import watchDashboard from './dashboard/sagas';
import watchDeliveryRequsets from './deliveryRequests/sagas';
import watchDeliveryRequestStatuses from './deliveryRequestStatuses/sagas';
import watchDeliveryStatuses from './deliveryStatuses/sagas';
import watchDocuments from './documents/sagas';
import watchFailureReasons from './failureReasons/sagas';
import watchFeedbackReasons from './feedbackReasons/sagas';
import watchFeedbacks from './feedbacks/sagas';
import watchListOfChanges from './listOfChanges/sagas';
import watchPasswordRecovery from './passwordRecovery/sagas';
import phoneCallHistory from './phoneCallHistory/sagas';
import watchPhotoTypes from './photoTypes/sagas';
import watchProducts from './products/sagas';
import watchProfile from './profile/sagas';
import watchRejectReasons from './rejectReasons/sagas';
import watchReports from './reports/sagas';
import watchTimeIntervals from './timeIntervals/sagas';
import twoFa from './twoFa/sagas';
import watchUserRoles from './userRoles/sagas';
import watchUsers from './users/sagas';
import watchTaskComment from './taskComments/sagas';

export default function* rootSaga() {
  yield all([
    watchDeliveryRequestStatuses(),
    watchDeliveryRequsets(),
    watchDeliveryStatuses(),
    watchPasswordRecovery(),
    watchFeedbackReasons(),
    watchFailureReasons(),
    watchCanselReasons(),
    watchRejectReasons(),
    watchTimeIntervals(),
    watchListOfChanges(),
    phoneCallHistory(),
    billingDocuments(),
    watchCitieAreas(),
    watchPhotoTypes(),
    watchDadataCity(),
    watchDadataFio(),
    watchDashboard(),
    watchUserRoles(),
    watchDocuments(),
    watchFeedbacks(),
    watchProducts(),
    watchProfile(),
    watchReports(),
    watchCities(),
    watchUsers(),
    watchAuth(),
    twoFa(),
    watchTaskComment(),
  ]);
}
