/* eslint-disable @typescript-eslint/ban-types */
import { AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from 'react-query';

type TData<
  T extends Promise<
    AxiosResponse<{
      body?: any;
    }>
  > = Promise<
    AxiosResponse<{
      body?: any;
    }>
  >
> = T extends Promise<
  AxiosResponse<{
    body?: infer R;
  }>
>
  ? R
  : never;
type TError<T extends Promise<AxiosResponse<any, any>> = Promise<AxiosResponse<any, any>>> = T extends Promise<
  AxiosResponse<any, infer R>
>
  ? R
  : never;
type Handler = (...args: any) => Promise<any>;
export type TQueryKey = [string] | [string, object];
type GetParameters<T extends (...args: any) => any = (...args: any) => any> = Parameters<T>[0];
export type OptionalPartial<T, K extends keyof T> = Partial<Pick<T, K>> & Omit<T, K>;

export const genMutation = <T extends Handler = Handler>(handler: T) => {
  type Params = GetParameters<typeof handler>;
  type Return = ReturnType<typeof handler>;

  return (options?: UseMutationOptions<TData<Return>, TError<Return>, Params>) =>
    useMutation({
      ...options,
      mutationFn: (v) => {
        return handler(v);
      },
    });
};

export const genQuery = <T extends Handler>({
  handler,
  genQueryKey,
}: {
  handler: T;
  genQueryKey: (arg: any) => TQueryKey;
}) => {
  type Params = GetParameters<T>;
  type Return = ReturnType<T>;

  return <TransformedData = TData<Return>>(
    params: Params,
    options?: UseQueryOptions<TData<Return>, TError<Return>, TransformedData, TQueryKey>,
  ) => {
    return useQuery({
      queryKey: genQueryKey(params),
      queryFn: () => {
        return handler(params);
      },
      ...options,
    });
  };
};

export const genInfiniteQuery = <T extends Handler>({
  handler,
  genQueryKey,
}: {
  handler: T;
  genQueryKey: (arg: any) => TQueryKey;
  debounce?: number;
}) => {
  type Params = GetParameters<typeof handler>;
  type Return = ReturnType<typeof handler>;
  type Options = OptionalPartial<
    UseInfiniteQueryOptions<TData<Return>, TError<Return>, TData<Return>, TData<Return>, TQueryKey>,
    'queryKey'
  >;

  return (params: Params, options?: Options) => {
    return useInfiniteQuery({
      queryKey: genQueryKey(params),
      getNextPageParam: (lastPage: any) => {
        return { filter: { to: lastPage?.data?.meta?.infinity_token } };
      },
      queryFn: ({ pageParam }) => {
        return handler({
          ...params,
          query: {
            ...params.query,
            ...pageParam,
          },
        });
      },
      ...options,
    });
  };
};
