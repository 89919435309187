import { combineReducers } from 'redux';
import { combineActions, handleActions } from 'redux-actions';
import * as actions from './actions';

interface Attributes {
  id: number;
  from: number;
  to: number;
  code: string;
  name: string;
}

interface Datum {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface TimeIntervalsState {
  byId: { [key: string]: Datum };
  allIds: string[];
}

const initialState: TimeIntervalsState = {
  byId: {},
  allIds: [],
};

interface TimeIntervalsAction {
  payload: Datum[];
}

const byId = handleActions(
  {
    [combineActions(actions.getTimeIntervalsSuccess, actions.getAllTimeIntervalsSuccess).toString()]: (
      state,
      { payload = [] }: TimeIntervalsAction,
    ) => ({
      ...payload?.reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: { ...item },
        }),
        {},
      ),
    }),
  },
  initialState.byId,
);

const allIds = handleActions(
  {
    [combineActions(actions.getTimeIntervalsSuccess, actions.getAllTimeIntervalsSuccess).toString()]: (
      state,
      { payload = [] }: TimeIntervalsAction,
    ) => {
      return [...new Set([...payload?.map(({ id }) => id)])];
    },
  },
  initialState.allIds,
);

export default combineReducers({
  byId,
  allIds,
});
