/* eslint-disable object-curly-newline */
/* eslint-disable camelcase */
import { push } from 'connected-react-router';
import { keys, omit } from 'lodash';
import qs from 'qs';
import { batchActions } from 'redux-batched-actions';
import { all, call, put, takeLatest, takeLeading } from 'redux-saga/effects';
import { del, get, patch, post } from 'shared/libs/request';
import { endEffectsStatuses, errorEffectsStatuses, startEffectsStatuses } from 'store/utils';
import * as deliveriesActions from '../deliveries/actions';
import * as actions from './actions';
import { deserialize } from 'deserialize-json-api';

function* getDeliveryRequest({ payload: { id } }) {
  try {
    const {
      data: { attributes: deliveryRequest, relationships },
      included,
    } = yield call(get, `/delivery_requests/${id}`);

    const activeDeliveyPayload = relationships?.active_delivery;

    const deliveries = included?.filter(({ type }) => type === 'delivery') || [];

    yield put(
      batchActions(
        [
          actions.getDeliveryRequestSuccess({ ...deliveryRequest, id: deliveryRequest.uuid }),
          deliveriesActions.getDeliveryIdSuccess(activeDeliveyPayload),
          deliveriesActions.getDeliveriesSuccess(deliveries),
        ],
        'BATCH_GET_DELIVERY_REQUEST',
      ),
    );
  } catch (error) {
    yield put(actions.getDeliveryRequestFailure({ id, response: error.response }));
    yield put(deliveriesActions.getDeliveriesFailure());
  }
}

function* getDeliveryRequests({ payload }) {
  try {
    const query = qs.stringify(payload, { encode: false, arrayFormat: 'brackets' });

    const response = yield call(get, `/delivery_requests?${query}`);

    const { data, meta } = deserialize(response);

    yield put(
      actions.getDeliveryRequestsSuccess({ meta, data: data.map((item) => ({ id: item.id, attributes: item })) }),
    );
  } catch (error) {
    yield put(actions.getDeliveryRequestsFailure());
  }
}

function* createDeliveryRequest({
  payload: { lastName, firstName, mobilePhone, companyName, time, deliveryCity, patronymic, email, ...rest },
}) {
  try {
    const {
      data: { attributes: deliveryRequest, relationships },
      included,
    } = yield call(post, '/delivery_requests', {
      data: {
        attributes: {
          ...rest,
          last_name: lastName,
          first_name: firstName,
          mobile_phone: mobilePhone,
          company_name: companyName,
          delivery_city: deliveryCity,
          email,
          patronymic,
          time,
        },
      },
    });

    const hasValidationErrors = keys(omit(deliveryRequest.validation_errors, 'process_errors')).length;

    const activeDeliveyPayload = relationships?.active_delivery;

    const deliveries = included?.filter(({ type }) => type === 'delivery') || [];

    if (deliveryRequest.status === 'created' || hasValidationErrors) {
      const deliveryRequestForCorrection = {
        ...deliveryRequest,
        destination: {
          ...deliveryRequest.destination,
          last_name: lastName,
          first_name: firstName,
          mobile_phone: mobilePhone,
          patronymic,
          email,
        },
        id: deliveryRequest.uuid,
      };

      yield put(
        batchActions(
          [
            deliveriesActions.getDeliveryIdSuccess(activeDeliveyPayload),
            deliveriesActions.getDeliveriesSuccess(deliveries),
            actions.createDeliveryRequestSuccess(deliveryRequestForCorrection),
          ],
          'BATCH_CREATE_DELIVERY_REQUEST_SUCCESS',
        ),
      );

      yield put(push(`/delivery/request/edit/${deliveryRequest.uuid}`));

      return;
    }

    yield put(
      batchActions(
        [
          deliveriesActions.getDeliveryIdSuccess(activeDeliveyPayload),
          deliveriesActions.getDeliveriesSuccess(deliveries),
          actions.createDeliveryRequestSuccess({ ...deliveryRequest, id: deliveryRequest.uuid }),
        ],
        'BATCH_CREATE_DELIVERY_REQUEST_SUCCESS',
      ),
    );

    yield put(push(`/delivery/requests/${deliveryRequest.uuid}`));
  } catch (error) {
    yield put(
      batchActions(
        [actions.createDeliveryRequestFailure(), deliveriesActions.getDeliveriesFailure()],
        'BATCH_CREATE_DELIVERY_REQUEST_FAULURE',
      ),
    );
  }
}

function* updateDeliveryRequest({
  payload: { id, lastName, firstName, mobilePhone, companyName, time, deliveryCity, patronymic, email, ...rest },
}) {
  try {
    const {
      data: { attributes: deliveryRequest, relationships },
      included,
    } = yield call(patch, `/delivery_requests/${id}`, {
      data: {
        attributes: {
          ...rest,
          email,
          patronymic,
          last_name: lastName,
          first_name: firstName,
          mobile_phone: mobilePhone,
          company_name: companyName,
          delivery_city: deliveryCity,
          time,
        },
      },
    });

    const hasValidationErrors = keys(omit(deliveryRequest.validation_errors, 'process_errors')).length;

    const activeDeliveyPayload = relationships?.active_delivery;

    const deliveries = included?.filter(({ type }) => type === 'delivery') || [];

    if (deliveryRequest.status === 'created' || hasValidationErrors) {
      const deliveryRequestForCorrection = {
        ...deliveryRequest,
        id: deliveryRequest.uuid,
      };

      yield put(
        batchActions(
          [
            actions.getDeliveryRequestSuccess({ ...deliveryRequest, id: deliveryRequest.uuid }),
            deliveriesActions.getDeliveryIdSuccess(activeDeliveyPayload),
            deliveriesActions.getDeliveriesSuccess(deliveries),
            actions.updateDeliveryRequestSuccess(deliveryRequestForCorrection),
          ],
          'BATCH_UPDATE_DELIVERY_REQUEST',
        ),
      );

      yield put(push(`/delivery/request/edit/${deliveryRequest.uuid}`));

      return;
    }

    yield put(
      batchActions(
        [
          deliveriesActions.getDeliveryIdSuccess(activeDeliveyPayload),
          deliveriesActions.getDeliveriesSuccess(deliveries),
          actions.updateDeliveryRequestSuccess({ ...deliveryRequest, id: deliveryRequest.uuid }),
        ],
        'BATCH_UPDATE_DELIVERY_REQUEST',
      ),
    );

    // yield put(push(`/delivery/requests/${deliveryRequest.uuid}`));
  } catch (error) {
    yield put(actions.updateDeliveryRequestFailure({ id }));
  }
}

function* confirmDeliveryRequest({ payload: { id } }) {
  try {
    const {
      data: { attributes: deliveryRequest, relationships },
      included,
    } = yield call(patch, `/delivery_requests/${id}/confirm`);

    const activeDeliveyPayload = relationships?.active_delivery;

    const deliveries = included?.filter(({ type }) => type === 'delivery') || [];

    yield put(
      batchActions(
        [
          deliveriesActions.getDeliveryIdSuccess(activeDeliveyPayload),
          deliveriesActions.getDeliveriesSuccess(deliveries),
          actions.updateDeliveryRequestSuccess({ ...deliveryRequest, id: deliveryRequest.uuid }),
        ],
        'BATCH_CONFIRM_DELIVERY_REQUEST',
      ),
    );
  } catch (error) {
    yield put(actions.updateDeliveryRequestFailure({ id }));
  }
}

function* rejectDeliveryRequest({ payload: { id, rejectReasonCode, rejectReasonComment } }) {
  try {
    const {
      data: { attributes: deliveryRequest, relationships },
      included,
    } = yield call(patch, `/delivery_requests/${id}/reject`, {
      data: {
        attributes: {
          reject_reason_code: rejectReasonCode || null,
          reject_reason_comment: rejectReasonComment || null,
        },
      },
    });

    const activeDeliveyPayload = relationships?.active_delivery;

    const deliveries = included?.filter(({ type }) => type === 'delivery') || [];

    yield put(
      batchActions(
        [
          deliveriesActions.getDeliveryIdSuccess(activeDeliveyPayload),
          deliveriesActions.getDeliveriesSuccess(deliveries),
          actions.updateDeliveryRequestSuccess({ ...deliveryRequest, id: deliveryRequest.uuid }),
        ],
        'BATCH_REJECT_DELIVERY_REQUEST',
      ),
    );
  } catch (error) {
    yield put(actions.updateDeliveryRequestFailure({ id }));
  }
}

function* deleteDeliveryRequest({ payload: { id, cancelReasonCode, cancelReasonComment } }) {
  try {
    const {
      data: { attributes: deliveryRequest, relationships },
      included,
    } = yield call(del, `/delivery_requests/${id}`, {
      data: {
        data: {
          attributes: {
            cancel_reason_code: cancelReasonCode || null,
            cancel_reason_comment: cancelReasonComment || null,
          },
        },
      },
    });

    const activeDeliveyPayload = relationships?.active_delivery;

    const deliveries = included?.filter(({ type }) => type === 'delivery') || [];

    yield put(
      batchActions(
        [
          deliveriesActions.getDeliveryIdSuccess(activeDeliveyPayload),
          deliveriesActions.getDeliveriesSuccess(deliveries),
          actions.deleteDeliveryRequestSuccess({ ...deliveryRequest, id: deliveryRequest.uuid }),
        ],
        'BATCH_DELETE_DELIVERY_REQUEST',
      ),
    );
  } catch (error) {
    yield put(actions.deleteDeliveryRequestFailure({ id }));
  }
}

function* callDeliveryRequest({ payload: { uuid } }) {
  const name = 'callDeliveryRequest';

  try {
    yield startEffectsStatuses({ name });

    yield call(post, `/delivery_requests/${uuid}/call_please`);

    yield endEffectsStatuses({ name, description: 'Курьер проинформирован' });
    yield put(actions.callDeliveryRequestSuccess());
  } catch (error) {
    yield errorEffectsStatuses({ name, error });
    yield put(actions.callDeliveryRequestFailure());
  }
}

export default function* watchDeliveryRequests() {
  yield all([
    takeLatest(actions.getDeliveryRequest, getDeliveryRequest),
    takeLatest(actions.getDeliveryRequests, getDeliveryRequests),
    takeLeading(actions.createDeliveryRequest, createDeliveryRequest),
    takeLeading(actions.updateDeliveryRequest, updateDeliveryRequest),
    takeLeading(actions.confirmDeliveryRequest, confirmDeliveryRequest),
    takeLeading(actions.rejectDeliveryRequest, rejectDeliveryRequest),
    takeLeading(actions.deleteDeliveryRequest, deleteDeliveryRequest),
    takeLeading(actions.callDeliveryRequest, callDeliveryRequest),
  ]);
}
