import cn from 'classnames';
import React from 'react';
import TwoFaType from 'shared/components/TwoFaType';
import WrapperField from 'shared/components/WrapperField';
import { User } from 'store/users/reducers';
import styles from './TypeTwoFA.module.less';

type TypeTwoFAFAProps = Readonly<{
  user: User;
}>;

const TypeTwoFA: React.FC<TypeTwoFAFAProps> = ({ user: { two_fa_type: twoFaType } }) => {
  const value = <TwoFaType twoFaType={twoFaType} size={18} />;
  return (
    <div className={cn(styles.TwoFAStatus)}>
      <WrapperField value={value} title="Тип 2FA" />
    </div>
  );
};

export default TypeTwoFA;
