import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

interface CityAreas {
  id: string;
  city_code: string;
  depot_id: string;
  border: string[];
}

export interface CityAreasState {
  byId: { [key: string]: CityAreas };
  allIds: string[];
}

const initialState: CityAreasState = {
  byId: {},
  allIds: [],
};

interface Action {
  payload: {
    attributes: Omit<CityAreas, 'id'>;
    id: string;
    type: string;
  }[];
}

const byId = handleActions(
  {
    [actions.getCitieAreasSuccess.toString()]: (state, { payload }: Action) => ({
      ...state,
      ...payload.reduce(
        (acc, cityAreas) => ({
          ...acc,
          [cityAreas.attributes.city_code]: { ...cityAreas.attributes },
        }),
        {},
      ),
    }),
  },
  initialState.byId,
);

const allIds = handleActions(
  {
    [actions.getCitieAreasSuccess.toString()]: (state, { payload }: any) => [
      ...new Set([...state, ...payload.map(({ id }: any) => id)]),
    ],
  },
  initialState.allIds,
);

export default combineReducers({
  byId,
  allIds,
});
