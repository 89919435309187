import React from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Button, Card, Tooltip } from 'antd';
import cn from 'classnames';

import styles from './AddDeliveris.module.less';

const AddDeliveris = () => {
  const history = useHistory();

  return (
    <Tooltip title="Заказать доставку">
      <Card className={cn(styles.AddDeliveris)}>
        <Button type="link" className={cn(styles.Button)} onClick={() => history.push('/delivery/request/create')}>
          <PlusCircleOutlined />
        </Button>
      </Card>
    </Tooltip>
  );
};

export default AddDeliveris;
