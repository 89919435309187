import { createSelector } from 'reselect';

export const selectDeliveryRequestsState = (store) => store.deliveryRequests;

export const selectDeliveryRequests = createSelector(
  selectDeliveryRequestsState,
  (deliveryRequestsState) => deliveryRequestsState.byId,
);

export const selectDeliveryRequest = (id) =>
  createSelector(selectDeliveryRequests, (deliveryRequests) => deliveryRequests[id]);

export const selectTotalDeliveryRequests = createSelector(
  selectDeliveryRequestsState,
  (deliveryRequestsState) => deliveryRequestsState.data.total,
);
