import Axios from 'axios';
import { endEffectsStatuses, errorEffectsStatuses, startEffectsStatuses } from 'store/utils';
import { put, call, debounce } from 'redux-saga/effects';
import { DaDataFioActions } from './action';

interface GetDaDataFioProps {
  payload: {
    query: string;
  };
}

function* getDaDataFio({ payload: params }: GetDaDataFioProps) {
  const name = 'dadataFio';

  try {
    yield startEffectsStatuses({ name });
    const {
      data: { suggestions: data },
    } = yield call(Axios.request, {
      method: 'GET',
      url: `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio`,
      headers: {
        authorization: 'Token 41faea520dd0bce140e27867be38f958e0316d2a',
      },
      params,
    });

    yield endEffectsStatuses({ name });
    yield put(DaDataFioActions.getDaDataFioSuccess(data));
  } catch (error) {
    yield errorEffectsStatuses({ name, error });
    yield put(DaDataFioActions.getDaDataFioFailure());
  }
}

export default function* watchFailureReasons() {
  yield debounce(1000, DaDataFioActions.getDaDataFio, getDaDataFio);
}
