import React from 'react';
import { useQuery } from 'shared/hooks';
import { Typography } from 'antd';
import QRCode from 'qrcode.react';

import Os from 'shared/components/Os';

import styles from './QrCode.module.less';

const { Text } = Typography;

const QrCode = () => {
  const queryParams = useQuery();

  const type = queryParams.get('type');
  const two_fa_uri = queryParams.get('two_fa_uri') || '';

  if (['authenticator', 'authenticatorAuth'].includes(type!)) {
    const text =
      type === 'authenticator'
        ? 'Для активации второго фактора установите приложение Google Authenticator и отсканируйте QR-код'
        : 'Посмотрите код в приложении Google Authenticator';

    return (
      <>
        <Text>{text}</Text>
        <div className={styles.Os}>{type === 'authenticator' && <Os />}</div>
        {two_fa_uri && (
          <>
            <div className={styles.QrCode}>
              <QRCode value={two_fa_uri} size={200} />
            </div>
            <div>
              <Text>И введите код, который сгенерировало приложение</Text>
            </div>
          </>
        )}
      </>
    );
  }
  return null;
};

export default QrCode;
