import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import { DashboardActions } from './actions';

export interface ByCreatedDate {
  total: number;
  month: number;
  week: number;
}

export interface ByDeliveryDate {
  [key: string]: number;
}

export interface ByStatus {
  canceled: number;
  confirmed: number;
  created: number;
  executed: number;
  rejected: number;
  validated: number;
}

export interface ByProduct {
  [key: string]: number;
}

export interface DeliveryRequests {
  by_created_date: ByCreatedDate;
  by_delivery_date: ByDeliveryDate;
  by_status: ByStatus;
  by_product: ByProduct;
}

export interface Cost {
  last_month: number;
  this_month: number;
}

export interface Attributes {
  delivery_requests: DeliveryRequests;
  costs: Cost;
  users: number;
}

export interface IDashboard {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface DashboardState {
  data: IDashboard | null;
}

const initialState: DashboardState = {
  data: null,
};

interface DashboardPayload {
  payload: IDashboard;
}

const data = handleActions(
  {
    [DashboardActions.getDashboarSuccess.toString()]: (state, { payload }: DashboardPayload) => ({
      ...payload,
    }),
  },
  initialState.data,
);

export default combineReducers({
  data,
});
