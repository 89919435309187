import React, { memo, useEffect, useMemo } from 'react';
import { PageHeader, Switch, Tag, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import cn from 'classnames';

import { getDeliveryRequestStatuses } from 'store/deliveryRequestStatuses/actions';
import { IStatusColors, STATUS_COLORS } from 'shared/constants';
import { useSetDelivery } from 'pages/DeliveryRequest/utils';
import { State } from 'store';

import styles from './Header.module.less';
import { selectDeliveryRequest } from 'store/deliveryRequests/selectors';

const { Text } = Typography;

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id }: { id: string } = useParams();

  const state = useSelector((state: State) => state);

  const { external_id, status } = state.deliveryRequests.byId[id] || {};
  const isDeliveryRequestStatuses = state.loaded.deliveryRequestStatuses;
  const deliveryRequestStatuses = state.deliveryRequestStatuses.byId;
  const profile = state.profile.data;
  const deliveryRequest = selectDeliveryRequest(id)(state);

  const action = useMemo(() => (id ? 'Изменить' : 'Заказать' + ''), [id]);

  const setDelivery = useSetDelivery();

  const statusBar = useMemo(() => {
    if (!status) return null;

    return (
      <div className={styles.Status} key="status">
        <Text type="secondary">{external_id}</Text>
        <Tag color={STATUS_COLORS[status as keyof IStatusColors]}>
          {deliveryRequestStatuses?.[status]?.name || status || 'Статус не известен'}
        </Tag>
      </div>
    );
  }, [external_id, status, deliveryRequestStatuses]);

  const demoSwitch = useMemo(
    () => (
      <Switch
        key="demo"
        onChange={(demo) => setDelivery({ data: { id, demo }, updateMessage: 'Демо' })}
        checked={!!deliveryRequest?.demo || profile?.partner_settings?.demo}
        disabled={profile?.partner_settings?.demo || !['created', 'validated'].includes(deliveryRequest?.status)}
        unCheckedChildren={'demo'}
        checkedChildren={'demo'}
        defaultChecked
      />
    ),
    [id, setDelivery, profile?.partner_settings?.demo, deliveryRequest],
  );

  useEffect(() => {
    if (!isDeliveryRequestStatuses) {
      dispatch(getDeliveryRequestStatuses());
    }

    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <div className={cn(styles.Header)}>
      <PageHeader onBack={history.goBack} title={`${action} доставку`} extra={[demoSwitch, statusBar]}>
        {/* <Body /> */}
      </PageHeader>
    </div>
  );
};

export default memo(Header);
