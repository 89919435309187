import { Badge, Tooltip } from 'antd';
import React from 'react';
import { TwoFaStaus } from 'store/users/reducers';

type TwoFAStatusPtops = Readonly<{
  twoFaStatus: TwoFaStaus;
}>;

const TwoFAStatus: React.FC<TwoFAStatusPtops> = ({ twoFaStatus, children }) => {
  const [color, title] = React.useMemo(() => {
    if (twoFaStatus === 'initiation') return ['yellow', 'Инициировано'];
    else if (twoFaStatus === 'enabled') return ['green', 'Включено'];

    return ['red', 'Выключено'];
  }, [twoFaStatus]);

  return (
    <Tooltip title={title}>
      <Badge size="default" color={color} count={5}>
        {children}
      </Badge>
    </Tooltip>
  );
};

export default TwoFAStatus;
