import { Modal } from 'antd';
import cn from 'classnames';
import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useCheckModal from 'shared/utils/checkModal';
import { State } from 'store';
import { getUser, unlockIntrusionTwoFaUser } from 'store/users/actions';
import { User } from 'store/users/reducers';
import styles from './EditModal.module.less';

interface EditModalProps {
  isModalVisible: boolean;
  setVisible: (arg: boolean) => void;
  user: User;
}

const EditModal: FC<EditModalProps> = ({ isModalVisible, setVisible, user }) => {
  const dispatch = useDispatch();

  const handleClosing = useCallback(
    () => setVisible(false),

    // eslint-disable-next-line
    [],
  );

  const loading = useSelector((state: State) => state.loading.unlockIntrusionTwoFaUser);
  const error = useSelector((state: State) => state.error.unlockIntrusionTwoFaUser);

  useCheckModal({
    isModalVisible,
    loading,
    error,
    onClose: () => {
      handleClosing();
      dispatch(getUser({ id: user.id }));
    },
  });

  const handlerSubmit = useCallback(() => {
    dispatch(
      unlockIntrusionTwoFaUser({
        id: user.id,
      }),
    );
  }, [dispatch, user.id]);

  return (
    <Modal
      title={'Сбросить неудачные попытки Авторизации'}
      width={460}
      visible={isModalVisible}
      onOk={() => handlerSubmit()}
      onCancel={handleClosing}
      okText={'Cбросить'}
      maskTransitionName=""
    >
      <div className={cn(styles.EditModal)}>Вы уверены, что хотите сбросить неудачные попытки ввода пароля и 2FA?</div>
    </Modal>
  );
};

export default EditModal;
