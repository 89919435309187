import { FileTextOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import Axios from 'axios';
import cn from 'classnames';
import React, { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { API_ENDPOINT } from 'shared/constants';
import { selectLoadedFor } from 'store/common/selectors';
import { IFilters } from '../../DeliveryRequestsList';
import styles from './Download.module.less';

interface IDownloadProps {
  filters: IFilters;
}

const Download: FC<IDownloadProps> = ({ filters }) => {
  const { city, status, date_lte, date_gte, external_id } = filters;

  const [isDownload, setIsDownload] = useState(false);

  const state: any = useSelector((state) => state);

  const {
    deliveryRequests: {
      data: { total },
    },
  } = state;

  const headers = useMemo(() => {
    const token = localStorage.getItem('token');
    return token ? { Authorization: `${token}` } : {};
  }, []);

  const downloadDeliveryRequests = async (type: 'csv' | 'xlsx') => {
    try {
      setIsDownload(true);
      const response = await Axios.request({
        method: 'POST',
        url: `${API_ENDPOINT}/delivery_requests/export.${type}`,
        headers,
        params: {
          'filter[city]': city,
          'filter[status]': status,
          'filter[date_gte]': date_gte,
          'filter[date_lte]': date_lte,
          'filter[external_id]': external_id,
        },
        responseType: 'blob',
      });

      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(response.data);
      // TODO: когда заголовки поправят, надо будет присваивать имя из заголовка (Тафтология (=) )
      // link.setAttribute('download', response.headers['content-disposition'].split('=')[1]);
      link.setAttribute('download', `document.${type}`);
      link.click();
      setIsDownload(false);
    } catch (err) {
      setIsDownload(false);
    }
  };

  const dropDownMenu = (
    <Menu>
      <Menu.Item className={styles.Item} onClick={() => downloadDeliveryRequests('csv')}>
        <FileTextOutlined /> Текстовый (CSV)
      </Menu.Item>
      <Menu.Item className={styles.Item} onClick={() => downloadDeliveryRequests('xlsx')}>
        <FileTextOutlined /> Excel (XLSX)
      </Menu.Item>
    </Menu>
  );

  const isDeliveryRequestsLoaded = useMemo(() => selectLoadedFor('deliveryRequests')(state), [state]);

  return (
    <div className={cn(styles.Download)}>
      <Dropdown
        disabled={!total || total === 0}
        overlay={dropDownMenu}
        placement="bottomRight"
        arrow
        trigger={['click']}
      >
        <Button size="large" loading={isDownload || isDeliveryRequestsLoaded === false}>
          Выгрузить <span className={styles.Count}>{total || 0}</span>
        </Button>
      </Dropdown>
    </div>
  );
};

export default Download;
