import Axios from 'axios';
import { call, put, all, takeLatest } from 'redux-saga/effects';

import { endEffectsStatuses, errorEffectsStatuses, startEffectsStatuses } from 'store/utils';

import * as actions from './actions';

function* getProfile() {
  const name = 'getProfile';

  try {
    yield startEffectsStatuses({ name });

    const {
      data: {
        data: { attributes: user },
      },
    } = yield call(Axios.get, '/profile');

    yield put(actions.getProfileSuccess(user));

    yield endEffectsStatuses({ name });
  } catch (error) {
    yield put(actions.getProfileFailure());

    yield errorEffectsStatuses({ name, error });
  }
}

interface UpdateProfileProps {
  payload: {
    id: string;
    last_name?: string;
    first_name?: string;
    new_password?: string;
    new_password_confirmation?: string;
    mobile_phone?: string;
  };
}

function* updateProfile({ payload }: UpdateProfileProps) {
  const name = 'updateProfile';

  try {
    yield startEffectsStatuses({ name });

    const {
      data: {
        data: { attributes: user },
      },
    } = yield call(Axios.request, {
      url: '/profile',
      method: 'PATCH',
      data: {
        data: {
          attributes: {
            ...payload,
          },
        },
      },
    });

    yield put(actions.updateProfileSuccess(user));

    yield endEffectsStatuses({ name });
  } catch (error) {
    yield put(actions.updateProfileFailure());

    yield errorEffectsStatuses({ name, error });
  }
}

export default function* watchUsers() {
  yield all([takeLatest(actions.getProfile, getProfile), takeLatest(actions.updateProfile, updateProfile)]);
}
