import React, { useMemo } from 'react';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
} from '@ant-design/icons/lib/icons';
import { useStatusActions } from 'shared/components/DeliveryRequest/utils';
import { useHistory } from 'react-router-dom';
import { Button, Dropdown, Menu } from 'antd';
import cn from 'classnames';

import styles from './MoreMenu.module.less';

interface IMoreMenu {
  setIsCancelModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsRejectModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOKModal: React.Dispatch<React.SetStateAction<boolean>>;
  setId: React.Dispatch<React.SetStateAction<string>>;
  uuid: string;
  id: string;
}

const MoreMenu = ({ setIsCancelModal, setIsRejectModal, setIsOKModal, setId, uuid, id }: IMoreMenu) => {
  const history = useHistory();

  const { canBeEdited, canBeCanceled, canBeRejected, canBeOk } = useStatusActions({ id });

  const menu = useMemo(
    () => (
      <Menu onMouseEnter={(e) => e.stopPropagation()}>
        <Menu.Item
          key="1"
          icon={<EyeOutlined />}
          className={styles.Item}
          onClick={(e) => {
            e.domEvent.stopPropagation();
            history.push(`/delivery/requests/${uuid}`);
          }}
        >
          Заявка
        </Menu.Item>
        <Menu.Item
          key="2"
          icon={<EditOutlined />}
          className={styles.Item}
          onClick={(e) => {
            e.domEvent.stopPropagation();
            history.push(`/delivery/request/edit/${uuid}`);
          }}
          disabled={!canBeEdited}
        >
          Изменить
        </Menu.Item>
        <Menu.Item
          key="3"
          icon={<CloseCircleOutlined />}
          className={styles.Item}
          disabled={!canBeCanceled}
          onClick={(e) => {
            e.domEvent.stopPropagation();
            setId(id);
            setIsCancelModal(true);
          }}
        >
          Отменить
        </Menu.Item>
        <Menu.Item
          key="4"
          icon={<ExclamationCircleOutlined />}
          className={styles.Item}
          disabled={!canBeRejected}
          onClick={(e) => {
            e.domEvent.stopPropagation();
            setId(id);
            setIsRejectModal(true);
          }}
        >
          Что-то не так
        </Menu.Item>
        <Menu.Item
          key="5"
          icon={<CheckCircleOutlined />}
          className={styles.Item}
          disabled={!canBeOk}
          onClick={(e) => {
            e.domEvent.stopPropagation();
            setId(id);
            setIsOKModal(true);
          }}
        >
          Подтвердить
        </Menu.Item>
      </Menu>
    ),
    [
      uuid,
      id,
      canBeCanceled,
      canBeEdited,
      canBeRejected,
      canBeOk,
      history,
      setId,
      setIsCancelModal,
      setIsOKModal,
      setIsRejectModal,
    ],
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} arrow placement="bottomRight">
      <Button
        className={cn(styles.Button)}
        shape="circle"
        icon={<EllipsisOutlined />}
        size={'large'}
        onClick={(e) => e.stopPropagation()}
      />
    </Dropdown>
  );
};

export default MoreMenu;
