import React from 'react';
import { TeamOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Button, Card, Tooltip } from 'antd';
import cn from 'classnames';

import styles from './UsersMenu.module.less';

const UsersMenu = () => {
  const history = useHistory();

  return (
    <Tooltip title="Управление пользователями">
      <Card className={cn(styles.UsersMenu)} onClick={() => history.push('/user/list')}>
        <Button type="link" className={cn(styles.Button)}>
          <TeamOutlined />
        </Button>
      </Card>
    </Tooltip>
  );
};

export default UsersMenu;
