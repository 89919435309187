import React from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { PageHeader } from 'antd';
import cn from 'classnames';

import makeFullName from 'shared/utils/makeFullName';
import { State } from 'store';

import styles from './Header.module.less';

const Header = () => {
  const history = useHistory();

  const { id }: { id: string } = useParams();

  const user = useSelector((state: State) => state.users.byId[id]);

  const fullName = user ? makeFullName(user).trim() : '';

  return <PageHeader onBack={history.goBack} className={cn(styles.Header)} title={fullName} />;
};

export default Header;
