import { call, put, all, takeLatest } from 'redux-saga/effects';
import { get } from 'shared/libs/request';
import * as actions from './actions';

import { endEffectsStatuses, errorEffectsStatuses, startEffectsStatuses } from 'store/utils';

function* getFeedbackReasons() {
  const name = 'feedback_reasons';
  try {
    yield startEffectsStatuses({ name });
    const { data } = yield call(get, '/feedback_reasons');

    yield endEffectsStatuses({ name });
    yield put(actions.getFeedbackReasonsSuccess(data));
  } catch (error) {
    yield errorEffectsStatuses({ name, error });
    yield put(actions.getFeedbackReasonsFailure());
  }
}

export default function* watchFeedbackReasons() {
  yield all([takeLatest(actions.getFeedbackReasons, getFeedbackReasons)]);
}
