import React, { FC } from 'react';
import cn from 'classnames';

import WrapperField from 'shared/components/WrapperField';

import styles from './SitePartner.module.less';
import Button from 'antd/es/button';

interface SitePartnerProps {
  url: string;
}

const SitePartner: FC<SitePartnerProps> = ({ url }) => {
  return (
    <div className={cn(styles.SitePartner)}>
      <WrapperField
        value={
          <Button type="link" className={styles.Button} onClick={() => window.open(url)}>
            {url}
          </Button>
        }
        title="Сайт"
      />
    </div>
  );
};

export default SitePartner;
