import React, { memo, useCallback, useMemo } from 'react';
import _ from 'lodash';
import JSZip from 'jszip';
import cn from 'classnames';
import { Tooltip } from 'antd';
import saveAs from 'file-saver';
import Button from 'antd/es/button';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FileImageOutlined } from '@ant-design/icons';
import moment from 'moment';

import { selectDeliveryRequest } from 'store/deliveryRequests/selectors';
import { selectPhotoTypes } from 'store/photoTypes/selectors';
import { selectActiveDeliveryId, selectDeliveries, selectDelivery } from 'store/deliveries/selectors';
import { selectLoadedFor } from 'store/common/selectors';
import makeFullName from 'shared/utils/makeFullName';

import styles from './DownloadAllPhotosButton.module.less';

interface DownloadAllPhotosButton {
  photos: any[];
  loading?: boolean;
}

const DownloadAllPhotosButton = ({ photos, loading }: DownloadAllPhotosButton) => {
  const { id }: { id: string } = useParams();

  const state = useSelector((state) => state);

  const isDeliveriesLoaded = useMemo(() => selectLoadedFor('deliveries')(state), [state]);

  const deliveries = useMemo(() => _(selectDeliveries(state)).values().value(), [state]);
  const deliveryRequest = useMemo(() => selectDeliveryRequest(id)(state), [state, id]);
  const photoTypes = useMemo(() => selectPhotoTypes(state), [state]);
  const activeDeliveryId = selectActiveDeliveryId(state);
  const delivery = useMemo(() => selectDelivery(activeDeliveryId)(state), [activeDeliveryId, state]);

  const onDownload = useCallback(async () => {
    if (deliveries?.length < 1) return;

    const pacakgesZip = await Promise.all(
      _(deliveries)
        .filter(({ photos }) => photos.length > 0)
        .map(async ({ date, ...item }) => {
          const zip = new JSZip();

          _.map(item.photos, ({ url, type }, index) => {
            zip.file(
              `${photoTypes[type]?.name || type || `Фото-${index}`}.jpg`,
              photos.find(({ config }) => config.url === url)?.data,
            );
          });

          const file = await zip.generateAsync({ type: 'blob' });

          return {
            file,
            name: `${date?.replace(/-/g, '')}_package.zip`,
          };
        })
        .value(),
    );

    const zip = new JSZip();

    pacakgesZip.map(({ file, name }) => {
      zip.file(name, file);
    });

    const content = await zip.generateAsync({ type: 'blob' });

    saveAs(
      content,
      `${(delivery.date || moment().format('DD-MM-YYYY')).replace(/-/g, '')}_${makeFullName({
        ...deliveryRequest?.destination,
      })}_delivery_photos.zip`,
    );
  }, [deliveries, photoTypes, deliveryRequest, photos, delivery]);

  return (
    <Tooltip title="Скачать все фотографии">
      <Button
        type="link"
        icon={<FileImageOutlined />}
        className={cn(styles.DownloadAllPhotosButton)}
        loading={!isDeliveriesLoaded || loading}
        disabled={!isDeliveriesLoaded || +delivery?.photos?.length === 0 || loading}
        size="large"
        onClick={onDownload}
      />
    </Tooltip>
  );
};

export default memo(DownloadAllPhotosButton);
