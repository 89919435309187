import Axios from 'axios';
import { call, put, all, takeLatest } from 'redux-saga/effects';

import { endEffectsStatuses, errorEffectsStatuses, startEffectsStatuses } from 'store/utils';

import * as actions from './actions';

function* getCities() {
  const name = 'cities';

  try {
    yield startEffectsStatuses({ name });
    const {
      data: { data: cities },
    } = yield call(Axios.get, '/cities');

    yield endEffectsStatuses({ name });
    yield put(actions.getCitiesSuccess(cities));
  } catch (error) {
    yield errorEffectsStatuses({ name, error });
    yield put(actions.getCitiesFailure());
  }
}

export default function* watchCities() {
  yield all([takeLatest(actions.getCities, getCities)]);
}
