import React from 'react';
import cn from 'classnames';

import Header from './components/Header';
import List from './components/List';

import styles from './ListOfChanges.module.less';

const ListOfChanges = () => {
  return (
    <div className={cn(styles.ListOfChanges)}>
      <Header />
      <List />
    </div>
  );
};

export default ListOfChanges;
