import React from 'react';
import { Result, Button } from 'antd';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

interface NotFoundProps {
  status?: 404 | 403 | 500 | '403' | '404' | '500' | 'success' | 'error' | 'info' | 'warning' | undefined;
  title?: string | number;
  subTitle?: string | number;
}

const NotFound = ({ status = 404, title = 404, subTitle = 'Что то пошло не так' }: NotFoundProps) => {
  const history = useHistory();

  const burronGoToGeneralPage = (
    <Button type="primary" onClick={() => history.push('/')}>
      На главную
    </Button>
  );

  return (
    <>
      <Helmet title={`${status} - ${subTitle}`} />

      <Result status={status} title={title} subTitle={subTitle} extra={burronGoToGeneralPage} />
    </>
  );
};

export default NotFound;
