import { InboxOutlined } from '@ant-design/icons';
import { Button, Checkbox, message, Modal, Progress, Tooltip, Upload as AntdUpload } from 'antd';
import { DraggerProps } from 'antd/lib/upload';
import axios, { AxiosRequestConfig } from 'axios';
import React from 'react';
import { useDispatch } from 'react-redux';
import ErrorLines from 'shared/components/ErrorLines';
import { API_ENDPOINT } from 'shared/constants';
import { addDocumentsDeliveryRequestSuccess } from 'store/documents/actions';
import styles from './Upload.module.less';

const { Dragger } = AntdUpload;

const Upload = () => {
  const dispatch = useDispatch();
  const [progress, setProgress] = React.useState(0);
  const [errorLines, setErrorLines] = React.useState<undefined | string>();
  const [visible, setVisible] = React.useState(false);
  const [cold, setCold] = React.useState(false);
  const [successLines, setSuccessLines] = React.useState<undefined | string>();

  const handlerOpen = () => setVisible(true);
  const handlerCancel = () => setVisible(false);

  const draggerProp: DraggerProps = React.useMemo(
    () => ({
      name: 'file',
      multiple: false,
      action: `${API_ENDPOINT}/delivery_requests/import.xlsx`,
      headers: {
        Authorization: `${localStorage.getItem('token')}`,
      },

      customRequest: async (options) => {
        setErrorLines(undefined);
        setSuccessLines(undefined);

        const { onProgress, file, onError } = options;

        const config: AxiosRequestConfig = {
          onUploadProgress: (event) => {
            const percent = Math.floor((event.loaded / event.total) * 100);

            setProgress(percent);

            if (percent === 100) {
              setTimeout(() => setProgress(0), 1000);
            }

            onProgress?.({ percent: (event.loaded / event.total) * 100 } as any);
          },
        };

        const fmData = new FormData();
        fmData.append('file', file);
        fmData.append('cold', cold.toString());

        try {
          const format = options.file.name.split('.')[1];

          const {
            data: { count },
          } = await axios.post(`${API_ENDPOINT}/delivery_requests/import.${format}`, fmData, config);

          setSuccessLines(
            cold ? `Файл корректный. Будет загружено ${count} заявок` : `Успешно загружено ${count} заявок`,
          );
        } catch (err) {
          if (err?.response?.status === 422) setErrorLines(err?.response?.data?.error?.trim());
          else {
            onError?.({ err } as any);
          }
        }
      },
      onChange(info) {
        const { status } = info.file;

        if (status === 'done') {
          dispatch(addDocumentsDeliveryRequestSuccess(info.file.response));
          message.success(`${info.file.name} файл успешно загружен.`);
        } else if (status === 'error') {
          message.error(`Ошибка ${info.file.name}, попробуйте снова`);
        }
      },
      beforeUpload: (file: File) => {
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
          message.error('Максимальный размер файла 10mb!');
        }
        return isLt2M;
      },
    }),
    [dispatch, cold],
  );

  React.useEffect(() => {
    if (visible) {
      setProgress(0);
      setErrorLines(undefined);
      setCold(false);
    }
  }, [visible]);

  return (
    <div className={styles.Upload}>
      <Button size="large" onClick={handlerOpen}>
        Загрузить
      </Button>
      <Modal title="Импорт заявок" visible={visible} onCancel={handlerCancel} footer={false}>
        <Tooltip title={`${cold ? 'Только проверяется формат файла и ошибки парсинга' : 'Создаём заявки'}`}>
          <Checkbox className={styles.Checkbox} checked={cold} onClick={() => setCold((prev) => !prev)}>
            Только проверить файл на наличие ошибок
          </Checkbox>
        </Tooltip>
        <Dragger {...draggerProp} accept=".csv, .xlsx" showUploadList={false}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Нажмите или перетащите файл сюда, чтобы загрузить его</p>
          <p className="ant-upload-hint">Разрешеныe форматы：.csv, .xlsx</p>
        </Dragger>
        {progress > 0 ? <Progress percent={progress} /> : null}
        <ErrorLines text={errorLines} console error className={styles.MessageLines} />
        <ErrorLines text={successLines} console success className={styles.MessageLines} />
      </Modal>
    </div>
  );
};

export default Upload;
