import React from 'react';

import { Card } from 'antd';
import cn from 'classnames';
import { deserialize } from 'deserialize-json-api';
import {
  DeliveryRequestComment,
  useInfiniteQuerygetapiAdminTaskComments,
  getapiAdminTaskCommentsOptions,
} from 'shared/api/DeliveryRequestComment';

import { Comments } from './components/Comments';
import styles from './TaskCommentsWidget.module.less';
import { CreateComment } from './components';

const TaskCommentsWidget = ({ filter }: NonNullable<getapiAdminTaskCommentsOptions['query']>) => {
  const { data, refetch } = useInfiniteQuerygetapiAdminTaskComments({
    query: { ...filter, include: ['author'] },
    params: { headers: { 'x-api-version': 'v2' } },
  });

  const deserializeData = React.useMemo(
    () =>
      data?.pages
        ?.map(({ data: comments }) => deserialize(comments)?.data)
        .flat()
        .reverse(),
    [data],
  ) as DeliveryRequestComment[];

  return (
    <Card className={cn(styles.TaskCommentsWidget)} id="task_comments">
      <Comments data={deserializeData} refetch={refetch} />
      <CreateComment filter={filter} refetch={refetch} />
    </Card>
  );
};

export default TaskCommentsWidget;
