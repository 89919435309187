import { put, call, takeLeading, all } from 'redux-saga/effects';
import { post } from 'shared/libs/request';
import { endEffectsStatuses, errorEffectsStatuses, startEffectsStatuses } from 'store/utils';
import * as actions from './actions';

function* createFeedback({ payload: { description, reason } }: { payload: { description: string; reason: string } }) {
  const name = 'createFeedback';

  try {
    yield startEffectsStatuses({ name });
    yield call(post, '/feedbacks', {
      data: {
        attributes: {
          description,
          reason,
        },
      },
    });

    yield endEffectsStatuses({ name });
    yield put(actions.createFeedbackSuccess());
  } catch (error) {
    yield errorEffectsStatuses({ name, error });
    yield put(actions.createFeedbackFailure());
  }
}

export default function* watchReports() {
  yield all([takeLeading(actions.createFeedback, createFeedback)]);
}
