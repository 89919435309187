import Axios from 'axios';
import { endEffectsStatuses, errorEffectsStatuses, startEffectsStatuses } from 'store/utils';
import { put, call, debounce } from 'redux-saga/effects';
import { DaDataCityActions } from './action';

interface GetDaDataProps {
  payload: {
    city?: string;
    region?: string;
    locations?: any[];
    query: string;
  };
}

function* getDaDataCity({
  payload: { city, region, query, locations = [{ country_iso_code: 'RU' }, { country_iso_code: 'KZ' }] },
}: GetDaDataProps) {
  const name = 'dadataCity';
  const location = (city || region) && { city, region };

  try {
    yield startEffectsStatuses({ name });
    const {
      data: { suggestions: data },
    } = yield call(Axios.request, {
      method: 'POST',
      url: `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`,
      headers: {
        authorization: 'Token 41faea520dd0bce140e27867be38f958e0316d2a',
      },
      data: {
        query: query || 'а',
        count: 10,
        locations: [...locations, location].filter((l) => !!l),
      },
    });

    yield endEffectsStatuses({ name });
    yield put(DaDataCityActions.getDaDataCitySuccess(data));
  } catch (error) {
    yield errorEffectsStatuses({ name, error });
    yield put(DaDataCityActions.getDaDataCityFailure());
  }
}

export default function* watchFailureReasons() {
  yield debounce(1000, DaDataCityActions.getDaDataCity, getDaDataCity);
}
