import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useCheckModal from 'shared/utils/checkModal';
import { Modal } from 'antd';
import cn from 'classnames';

import { getUser, resetPasswordUser } from 'store/users/actions';
import { User } from 'store/users/reducers';
import { State } from 'store';

import styles from './EditModal.module.less';

interface EditModalProps {
  isModalVisible: boolean;
  setVisible: (arg: boolean) => void;
  user: User;
}

const EditModal: FC<EditModalProps> = ({ isModalVisible, setVisible, user }) => {
  const dispatch = useDispatch();

  const handleClosing = useCallback(
    () => setVisible(false),

    // eslint-disable-next-line
    [],
  );

  const loading = useSelector((state: State) => state.loading.blockUser || state.loading.resetPassword);
  const error = useSelector((state: State) => state.error.blockUser || state.error.resetPassword);

  useCheckModal({
    isModalVisible,
    loading,
    error,
    onClose: () => {
      handleClosing();
      dispatch(getUser({ id: user.id }));
    },
  });

  const handlerSubmit = useCallback(() => {
    dispatch(
      resetPasswordUser({
        id: user.id,
      }),
    );
  }, [dispatch, user.id]);

  return (
    <Modal
      title="Cброс пароля"
      width={440}
      visible={isModalVisible}
      onOk={() => handlerSubmit()}
      onCancel={handleClosing}
      okText="Cбросить"
      maskTransitionName=""
    >
      <div className={cn(styles.EditModal)}>Вы уверены, что хотите сбросить пароль пользователя?</div>
    </Modal>
  );
};

export default EditModal;
