import { createSelector } from 'reselect';

export const selectDeliveriesState = (store) => store.deliveries;

export const selectDeliveries = createSelector(selectDeliveriesState, (deliveriesStates) => deliveriesStates.byId);
export const selectAllIdsDeliveries = createSelector(
  selectDeliveriesState,
  (deliveriesStates) => deliveriesStates.allIds,
);

export const selectDelivery = (id) => createSelector(selectDeliveries, (deliveriesState) => deliveriesState[id]);

export const selectActiveDeliveryId = createSelector(
  selectDeliveriesState,
  (deliveriesStates) => deliveriesStates.data.activeId,
);
