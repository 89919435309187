import { Select, SelectProps } from 'antd';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import makeFullName from 'shared/utils/makeFullName';
import styles from './SelectUsers.module.less';
import Axios from 'axios';
import { User } from 'store/users/reducers';

type SelectUsersProps<T = any> = SelectProps<T>;

const SelectUsers: React.FC<SelectUsersProps> = (popss) => {
  const [userField, setUserField] = useState<string>();
  const [users, setUsers] = useState<Record<string, User>>({});

  useEffect(() => {
    const idTimeout = setTimeout(async () => {
      const {
        data: { data },
      } = await Axios.request({
        url: `/users`,
        method: 'GET',
        params: {
          'filter[q]': userField,
        },
      });

      setUsers(
        data?.reduce(
          (acc: any, { attributes }: any) => ({
            ...acc,
            [attributes.id]: attributes,
          }),
          {},
        ),
      );
    }, 300);

    return () => clearTimeout(idTimeout);
  }, [userField]);

  return (
    <Select
      showSearch
      placeholder="Пользователь"
      className={cn(styles.SelectUsers)}
      size="large"
      allowClear
      filterOption={false}
      {...popss}
      onSearch={(v) => {
        popss.onSearch?.(v);

        setUserField(v);
      }}
    >
      {Object.values(users)?.map(({ id, ...rest }) => (
        <Select.Option value={id} key={id}>
          {makeFullName(rest)}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectUsers;
