import cn from 'classnames';
import moment from 'moment';
import React from 'react';
import WrapperField from 'shared/components/WrapperField';
import { User } from 'store/users/reducers';
import styles from './PassingTimeTwoFA.module.less';

type PassingTimeTwoFAFAProps = Readonly<{
  user: User;
}>;

const PassingTimeTwoFA: React.FC<PassingTimeTwoFAFAProps> = ({ user: { two_fa_passed_at: twoFaPassedAt } }) => {
  return (
    <div className={cn(styles.TwoFAStatus)}>
      <WrapperField
        value={twoFaPassedAt ? moment(twoFaPassedAt).format('DD.MM.YYYY HH:mm:ss') : ''}
        title="Время прохождения 2FA"
      />
    </div>
  );
};

export default PassingTimeTwoFA;
