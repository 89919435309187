import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Select, Tag, Typography } from 'antd';
import cn from 'classnames';
import { useFormik } from 'formik';
import _ from 'lodash';
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WrapperField from 'shared/components/WrapperField';
import useCheckModal from 'shared/utils/checkModal';
import { State } from 'store';
import { getUser, updateUser } from 'store/users/actions';
import { User } from 'store/users/reducers';
import * as Yup from 'yup';
import styles from './Roles.module.less';

const { Option } = Select;
const { Text } = Typography;

const validationSchema = Yup.object().shape({});

interface RolesProps {
  user: User;
}

const Roles: FC<RolesProps> = ({ user }) => {
  const dispatch = useDispatch();
  const [isModalVisible, setVisible] = useState(false);

  const handlerEdit = useCallback(() => setVisible(true), []);

  const { userRoles } = useSelector((state: State) => state);
  const loading = useSelector((state: State) => state.loading.updateUser);
  const error = useSelector((state: State) => state.error.updateUser);

  useCheckModal({
    isModalVisible,
    loading,
    error,
    onClose: () => {
      setVisible(false);
      dispatch(getUser({ id: user.id }));
    },
  });

  const initialValues = useMemo(
    () => ({
      roles: user.roles || '',
    }),
    [user],
  );

  const { touched, errors, values, setFieldValue, setFieldTouched, setValues, handleSubmit, resetForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      dispatch(
        updateUser({
          id: user.id,
          ...values,
        }),
      );
    },
  });

  const roleOptions = useMemo(() => {
    if (_.isEmpty(userRoles.byId)) return null;

    return _.values(userRoles.byId).map(({ id, name }) => (
      <Option value={id} label={name} key={id}>
        {name}
      </Option>
    ));
  }, [userRoles.byId]);

  const value = useMemo(() => {
    if (isModalVisible)
      return (
        <div className={styles.EditPanel}>
          <Select
            mode="multiple"
            style={{ width: '220px' }}
            placeholder="Роли"
            value={values.roles}
            onChange={(v) => setFieldValue('roles', v, true)}
            onBlur={() => setFieldTouched('roles', true)}
            optionLabelProp="label"
          >
            {roleOptions}
          </Select>
          {touched.roles && errors.roles && <Text type="danger">{errors.roles}</Text>}
          <Button type="link" onClick={() => handleSubmit()} loading={loading}>
            {!loading && <SaveOutlined style={{ fontSize: '24px' }} />}
          </Button>
          <Button type="link" onClick={() => setVisible(false)} loading={loading}>
            {!loading && <CloseOutlined style={{ fontSize: '24px', color: 'rgb(140 140 140)' }} />}
          </Button>
          {touched.roles && errors.roles && <Text type="danger">{errors.roles}</Text>}
        </div>
      );

    return (
      <>
        {user.roles?.map((str, index) => (
          <Tag key={index} className={styles.Tag}>
            {userRoles?.byId[str]?.name || str}
          </Tag>
        ))}
      </>
    );
  }, [
    touched.roles,
    errors.roles,
    values.roles,
    isModalVisible,
    roleOptions,
    userRoles?.byId,
    setFieldTouched,
    setFieldValue,
    handleSubmit,
    loading,
    user,
  ]);

  useEffect(() => {
    isModalVisible && resetForm();
    isModalVisible && setValues(initialValues);
  }, [isModalVisible, setValues, initialValues, resetForm]);

  return (
    <div className={cn(styles.Roles)}>
      <WrapperField value={value} title="Роли" handlerEdit={isModalVisible ? undefined : handlerEdit} />
    </div>
  );
};

export default memo(Roles);
