import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useCheckModal from 'shared/utils/checkModal';
import { Modal } from 'antd';
import cn from 'classnames';

import { blockUser, getUser, unblockUser } from 'store/users/actions';
import { User } from 'store/users/reducers';
import { State } from 'store';

import styles from './EditModal.module.less';

interface EditModalProps {
  isModalVisible: boolean;
  setVisible: (arg: boolean) => void;
  user: User;
}

const EditModal: FC<EditModalProps> = ({ isModalVisible, setVisible, user }) => {
  const dispatch = useDispatch();

  const handleClosing = useCallback(
    () => setVisible(false),

    // eslint-disable-next-line
    [],
  );

  const loading = useSelector((state: State) => state.loading.blockUser || state.loading.unblockUser);
  const error = useSelector((state: State) => state.error.blockUser || state.error.unblockUser);

  useCheckModal({
    isModalVisible,
    loading,
    error,
    onClose: () => {
      handleClosing();
      dispatch(getUser({ id: user.id }));
    },
  });

  const okText = useMemo(() => (!user.blocked_at ? 'Заблокировать' : 'Разблокировать'), [user.blocked_at]);

  const handlerSubmit = useCallback(() => {
    const handler = user.blocked_at ? unblockUser : blockUser;

    dispatch(
      handler({
        id: user.id,
      }),
    );
  }, [dispatch, user.blocked_at, user.id]);

  return (
    <Modal
      title={okText}
      width={440}
      visible={isModalVisible}
      onOk={() => handlerSubmit()}
      onCancel={handleClosing}
      okText={okText}
      maskTransitionName=""
    >
      <div className={cn(styles.EditModal)}>
        Вы уверены, что хотите <b className={styles.TextBold}>{okText}</b> пользователя?
      </div>
    </Modal>
  );
};

export default EditModal;
