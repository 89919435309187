import React from 'react';
import { Table as TableAntd } from 'antd';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { State } from 'store';

import { useHooks } from './utils';

import styles from './Table.module.less';

const Table = () => {
  const history = useHistory();

  const { columns, users, empty } = useHooks();

  const loading = useSelector((state: State) => state.loading.getUsers);

  return (
    <div className={cn(styles.Table)}>
      <TableAntd
        columns={columns}
        dataSource={users.map((item) => ({ ...item, key: item.id }))}
        onRow={({ id }) => ({
          onClick: () => history.push(`/user/${id}`),
        })}
        loading={loading}
        locale={{
          emptyText: empty,
        }}
      />
    </div>
  );
};

export default Table;
