import { FileDoneOutlined } from '@ant-design/icons';
import { Button, Card, Tooltip } from 'antd';
import cn from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './BillingDocuments.module.less';

const BillingDocuments = () => {
  const history = useHistory();

  return (
    <Tooltip title="Бухгалтерские документы">
      <Card className={cn(styles.BillingDocuments)}>
        <Button type="link" className={cn(styles.Button)} onClick={() => history.push('/billing/documents')}>
          <FileDoneOutlined />
        </Button>
      </Card>
    </Tooltip>
  );
};

export default BillingDocuments;
