import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Modal, Typography } from 'antd';
import { useFormik } from 'formik';
import { State } from 'store';
import cn from 'classnames';
import * as Yup from 'yup';

import { updateProfile } from 'store/profile/actions';
import useCheckModal from 'shared/utils/checkModal';

import styles from './EditModal.module.less';

const { Text } = Typography;

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().min(6, 'Минимум 6 символов').required('Обязательное поле'),
  new_password: Yup.string()
    .notOneOf([Yup.ref('oldPassword')], 'Старый пароль не должен совпадать с новым')
    .min(6, 'Минимум 6 символов')
    .required('Обязательное поле'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('new_password')], 'Новые пароли должен совпадать')
    .min(6, 'Минимум 6 символов')
    .required('Обязательное поле'),
});

interface EditModalProps {
  isModalVisible: boolean;
  setVisible: (arg: boolean) => void;
}

const EditModal: FC<EditModalProps> = ({ isModalVisible, setVisible }) => {
  const dispatch = useDispatch();

  const handleCancel = useCallback(
    () => setVisible(false),

    // eslint-disable-next-line
    [],
  );

  const loading = useSelector((state: State) => state.loading.updateProfile);
  const error = useSelector((state: State) => state.error.updateProfile);
  const profile = useSelector((state: State) => state.profile.data);

  useCheckModal({ isModalVisible, loading, error, onClose: handleCancel });

  const initialValues = useMemo(
    () => ({
      oldPassword: '',
      new_password: '',
      confirmPassword: '',
    }),
    [],
  );

  const { touched, errors, values, handleChange, handleBlur, setValues, handleSubmit, resetForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ oldPassword: password, new_password: new_password, confirmPassword: new_password_confirmation }) => {
      dispatch(
        updateProfile({
          id: profile.id,
          password,
          new_password,
          new_password_confirmation,
        }),
      );
    },
  });

  useEffect(() => {
    isModalVisible && resetForm();
    isModalVisible && setValues(initialValues);
  }, [isModalVisible, setValues, initialValues, resetForm]);

  return (
    <Modal
      title="Изменить пароль"
      width={320}
      visible={isModalVisible}
      onOk={() => handleSubmit()}
      onCancel={handleCancel}
      okText="Сохранить"
      maskTransitionName=""
    >
      <div className={cn(styles.EditModal)}>
        <Input
          name="oldPassword"
          type="password"
          placeholder="Старый пароль"
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="new-password"
          value={values.oldPassword}
        />
        {touched.oldPassword && errors.oldPassword && <Text type="danger">{errors.oldPassword}</Text>}
        <Input
          name="new_password"
          type="password"
          placeholder="Новый пароль"
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="new-password"
          value={values.new_password}
        />
        {touched.new_password && errors.new_password && <Text type="danger">{errors.new_password}</Text>}
        <Input
          name="confirmPassword"
          type="password"
          placeholder="Повторите новый пароль"
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="new-password"
          value={values.confirmPassword}
        />
        {touched.confirmPassword && errors.confirmPassword && <Text type="danger">{errors.confirmPassword}</Text>}
      </div>
    </Modal>
  );
};

export default EditModal;
