import { LoadingOutlined, PhoneOutlined } from '@ant-design/icons';
import { Button, Card, Spin, Tooltip } from 'antd';
import React, { FC, memo, useCallback, useMemo } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import makeFullName from 'shared/utils/makeFullName';
import { State } from 'store';
import { callDeliveryRequest } from 'store/deliveryRequests/actions';
import { selectDeliveryRequest } from 'store/deliveryRequests/selectors';
import { selectProducts } from 'store/products/selectors';
import styles from './DataRequest.module.less';

const DataRequest: FC<unknown> = () => {
  const { id }: { id: string } = useParams();
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const loading = useSelector((state: State) => state.loading.callDeliveryRequest);

  const deliveryRequest = useMemo(() => selectDeliveryRequest(id)(state), [state, id]);
  const products = useMemo(() => selectProducts(state), [state]);

  const handlerCall = useCallback(() => {
    dispatch(callDeliveryRequest({ uuid: id }));
  }, [id, dispatch]);

  const buttonCall = useMemo(() => {
    const disabled = deliveryRequest?.status !== 'validated';

    if (loading)
      return <Spin className={styles.Button} indicator={<LoadingOutlined style={{ fontSize: 21 }} spin />} />;

    return (
      <Tooltip title="Сообщить представителю, чтобы он связался с клиентом" key="call">
        <Button type="link" className={styles.Button} onClick={handlerCall} disabled={disabled}>
          <div className={styles.BodyButton}>
            Перезвонить <PhoneOutlined style={{ fontSize: 21, paddingLeft: '5px' }} />
          </div>
        </Button>
      </Tooltip>
    );
  }, [deliveryRequest, handlerCall, loading]);

  const distantion = useMemo(() => {
    const { company_name: name, inn } = deliveryRequest?.destination || {};

    if (name || inn) {
      return (
        <div>
          {name && <b className={styles.CompanyName}>{name}</b>}
          {inn && <>Инн: {inn}</>}
        </div>
      );
    }
    return null;
  }, [deliveryRequest?.destination]);

  return (
    <Card.Meta
      title={products?.[deliveryRequest?.product]?.name || deliveryRequest?.product}
      description={
        <>
          {distantion}
          <b>{makeFullName({ ...deliveryRequest?.destination })}</b>
          {deliveryRequest?.destination?.mobile_phone && (
            <>
              <br />
              <NumberFormat
                value={deliveryRequest.destination.mobile_phone}
                allowEmptyFormatting
                displayType="text"
                format={
                  /\+\d{5}\*\*\*\*\d{2}$/.test(deliveryRequest.destination.mobile_phone)
                    ? '+# (###) #**-**-##'
                    : '+# (###) ###-##-##'
                }
              />
              {buttonCall}
            </>
          )}
          {deliveryRequest?.destination?.email && (
            <>
              <br />
              {deliveryRequest?.destination?.email}
            </>
          )}
          {deliveryRequest?.notes && (
            <>
              <br /> {deliveryRequest?.notes}
            </>
          )}
          {!!deliveryRequest?.client_coordination_url && (
            <div>
              <a target="_blank" rel="noreferrer" href={deliveryRequest.client_coordination_url}>
                Ссылка на страницу согласования
              </a>
            </div>
          )}
        </>
      }
    />
  );
};

export default memo(DataRequest);
