import cn from 'classnames';
import moment from 'moment';
import React from 'react';
import WrapperField from 'shared/components/WrapperField';
import { User } from 'store/users/reducers';
import styles from './OtpSentTwoFA.module.less';

type OtpSentTwoFAFAProps = Readonly<{
  user: User;
}>;

const OtpSentTwoFA: React.FC<OtpSentTwoFAFAProps> = ({ user: { otp_sent_at: otpSentAt } }) => {
  return (
    <div className={cn(styles.TwoFAStatus)}>
      <WrapperField value={otpSentAt ? moment(otpSentAt).format('DD.MM.YYYY HH:mm:ss') : ''} title="Время OTP 2FA" />
    </div>
  );
};

export default OtpSentTwoFA;
