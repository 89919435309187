import React from 'react';
import { useHistory } from 'react-router';
import { PageHeader } from 'antd';
import cn from 'classnames';

import styles from './Header.module.less';

const Header = () => {
  const history = useHistory();

  return <PageHeader className={cn(styles.Header)} onBack={() => history.push('/')} title="Список изменений" />;
};

export default Header;
