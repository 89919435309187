import React from 'react';
import { useParams } from 'react-router-dom';
import cn from 'classnames';

import { useGenFormik } from './useGenFormik';
import { useSetDelivery } from '../../utils';
import SelectProduct from './components/SelectProduct';
import styles from './Form.module.less';
import SelectCity from './components/SelectCity';
import SelectDate from './components/SelectDate';
import SelectAddress from './components/SelectAddress';
import SelectTimeInterval from './components/SelectTimeInterval';
import SelectFullName from './components/SelectFullName';
import SelectPhone from './components/SelectPhone';
import SelectEmail from './components/SelectEmail';
import SelectCompanyName from './components/SelectCompanyName';
import SelectNotes from './components/SelectNotes';
import ControlPanel from './components/ControlPanel';
import SelectInn from './components/SelectInn';
import SelectExternalIdRequired from './components/SelectExternalIdRequired';

const Form = () => {
  const { id } = useParams<{ id: string }>();

  const { values, errors, touched, setFieldTouched, setTouched, setFieldValue, handleSubmit } = useGenFormik();
  const setDelivery = useSetDelivery();

  const configBlur = {
    id,
    setField: setDelivery,
    errors: {
      ...errors,
    },
    values: {
      ...values,
    },
    setFieldTouched,
  };

  return (
    <div className={cn(styles.Form)}>
      <SelectProduct
        {...{
          value: values?.product,
          setFieldValue,
          configBlur,
          touched: touched?.product as boolean,
          error: errors?.product as string,
        }}
      />
      <div className={styles.Double}>
        <SelectCity
          {...{
            value: values?.delivery_city,
            setFieldValue,
            configBlur,
            touched: touched?.delivery_city as boolean,
            error: errors?.delivery_city as string,
            disabled: !values?.product,
          }}
        />
        <SelectExternalIdRequired
          {...{
            value: values?.external_id,
            setFieldValue,
            configBlur,
            touched: touched?.external_id as boolean,
            error: errors?.external_id as string,
            disabled: !values?.product,
          }}
        />
      </div>
      <SelectAddress
        {...{
          value: values?.address,
          delivery_city: values?.delivery_city,
          setFieldValue,
          configBlur,
          touched: touched?.address as boolean,
          error: errors?.address as string,
        }}
      />
      <div className={styles.Double}>
        <SelectDate
          {...{
            value: values?.date,
            setFieldValue,
            configBlur,
            touched: touched?.date as boolean,
            error: errors?.date as string,
            disabled: !values?.product,
          }}
        />
        <div />
      </div>
      <SelectTimeInterval
        {...{
          setFieldValue,
          values,
          error: errors.time_interval_id || errors.time,
          touched: touched.time_interval_id || touched.time,
          product: values?.product,
        }}
      />
      <SelectFullName
        {...{
          setFieldValue,
          values,
          configBlur,
          value: values?.fulName,
          error: errors.first_name || errors.last_name || errors.patronymic,
          touched: touched.first_name || touched.last_name || touched.patronymic,
          onBlur: () =>
            setTouched({
              ...touched,
              fulName: true,
              first_name: true,
              last_name: true,
              patronymic: true,
            }),
        }}
      />
      <div className={styles.Double}>
        <SelectPhone
          {...{
            value: values?.mobile_phone,
            setFieldValue,
            configBlur,
            touched: !!touched?.mobile_phone,
            error: errors?.mobile_phone as string,
          }}
        />
        <SelectEmail
          {...{
            value: values?.email,
            setFieldValue,
            configBlur,
            touched: touched?.email as boolean,
            error: errors?.email as string,
          }}
        />
      </div>
      <div className={styles.Double}>
        <SelectCompanyName
          {...{
            value: values?.company_name,
            setFieldValue,
            configBlur,
            touched: touched?.company_name as boolean,
            error: errors?.company_name as string,
          }}
        />
        <SelectInn
          {...{
            value: values?.inn,
            setFieldValue,
            configBlur,
            touched: touched?.inn as boolean,
            error: errors?.inn as string,
          }}
        />
      </div>
      <SelectNotes
        {...{
          value: values?.notes,
          setFieldValue,
          configBlur,
          touched: touched?.notes as boolean,
          error: errors?.notes as string,
        }}
      />

      <ControlPanel handleSubmit={handleSubmit} />
    </div>
  );
};

export default Form;
