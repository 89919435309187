import React, { FC } from 'react';
import cn from 'classnames';

import WrapperField from 'shared/components/WrapperField';

import styles from './Demo.module.less';

interface DemoProps {
  demo?: boolean;
}

const Demo: FC<DemoProps> = ({ demo }) => {
  return (
    <div className={cn(styles.Demo)}>
      <WrapperField
        value={demo ? <span className={styles.On}>вкл</span> : <span className={styles.Off}>выкл</span>}
        title="Демо режим"
      />
    </div>
  );
};

export default Demo;
