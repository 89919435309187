import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

export type TaskComments = Readonly<{
  id: string;
  text: string;
  created_at: number;
}>;

export type TaskCommentsState = Readonly<{
  byId: { [key: string]: TaskComments };
  allIds: string[];
}>;

const initialState: TaskCommentsState = {
  byId: {},
  allIds: [],
};

interface Action {
  payload: {
    attributes: Omit<TaskComments, 'id'>;
    id: string;
    type: string;
  }[];
}

const byId = handleActions(
  {
    [actions.getTaskCommentsSuccess.toString()]: (state, { payload }: Action) => ({
      ...payload.reduce(
        (acc, taskComment) => ({
          ...acc,
          [taskComment.id]: { id: taskComment.id, ...taskComment.attributes },
        }),
        {},
      ),
    }),
  },
  initialState.byId,
);

const allIds = handleActions(
  {
    [actions.getTaskCommentsSuccess.toString()]: (state, { payload }: Action) => [
      ...new Set([...payload.map(({ id }) => id)]),
    ],
  },
  initialState.allIds,
);

export default combineReducers({
  byId,
  allIds,
});
