import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

export interface Data {
  id: string;
  name: string;
  description: string;
}

export interface FeedbackReasonsState {
  byId: {
    [key: string]: Data;
  };
  allIds: string[];
}

const initialState: FeedbackReasonsState = {
  byId: {},
  allIds: [],
};

const byId = handleActions(
  {
    [actions.getFeedbackReasonsSuccess.toString()]: (state, { payload }: { payload: any[] }) => ({
      ...state,
      ...payload.reduce(
        (acc, reason) => ({
          ...acc,
          [reason.id]: { id: reason.id, ...reason.attributes },
        }),
        {},
      ),
    }),
  },
  initialState.byId,
);

const allIds = handleActions(
  {
    [actions.getFeedbackReasonsSuccess.toString()]: (state, { payload }: { payload: any[] }) => [
      ...new Set([...state, ...payload.map(({ id }) => id)]),
    ],
  },
  initialState.allIds,
);

export default combineReducers({
  byId,
  allIds,
});
