import React from 'react';
import cn from 'classnames';
import { Button, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'shared/hooks';

import { twoFaSendOtp } from 'store/twoFa/actions';
import { State } from 'store';

import styles from './Sms.module.less';
import CountdownTimer from 'shared/components/CountdownTimer';
import NumberFormat from 'react-number-format';
import { ChangePhone } from '../../../ChangePhone';

const { Text } = Typography;

const Sms = () => {
  const dispatch = useDispatch();
  const queryParams = useQuery();

  const token = queryParams.get('token');
  const type = queryParams.get('type');
  const two_fa_uri = queryParams.get('two_fa_uri') || '';
  const twoFaActivated = queryParams.get('two_fa_activated');
  const two_fa_mobile_phone = queryParams.get('two_fa_mobile_phone');

  const [timer, setTimer] = React.useState(0);

  const loadingSendOtp = useSelector((state: State) => state.loading.twoFaSendOtp);

  React.useEffect(() => {
    !loadingSendOtp && setTimer(30);
  }, [token, type, two_fa_uri, twoFaActivated, loadingSendOtp]);

  const handleClickSendOtp = React.useCallback(() => {
    dispatch(twoFaSendOtp({ token }));
  }, [token, dispatch]);

  if (['sms', 'smsAuth'].includes(type!)) {
    const text =
      type === 'sms'
        ? 'Мы отправили вам код по смс для активации двухфакторной аутентификации.'
        : 'Код отправлен вам по смс.';

    return (
      <div>
        <Text>
          {text}{' '}
          <Button
            type="link"
            disabled={timer !== 0}
            className={cn(styles.CountdownTimer)}
            onClick={handleClickSendOtp}
            loading={loadingSendOtp}
          >
            {!loadingSendOtp && <CountdownTimer timer={timer} onChange={setTimer} text="Отправить повторно" />}
          </Button>
          <div className={cn(styles.HintPhone)}>
            <NumberFormat format="+# (###) #**-**-##" displayType="text" value={two_fa_mobile_phone} />
          </div>
          <ChangePhone resetTimer={() => setTimer(30)} />
        </Text>
      </div>
    );
  }

  return null;
};

export default Sms;
