import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { getDeliveryStatuses } from 'store/deliveryStatuses/actions';

export const useDeliveryStatus = () => {
  const dispatch = useDispatch();
  const {
    deliveryStatuses: { byId },
    loading: { deliveryStatuses: loading },
  } = useSelector((state: State) => state);

  React.useEffect(() => {
    if (!loading && Object.keys(byId).length === 0) {
      dispatch(getDeliveryStatuses());
    }

    // eslint-disable-next-line
  }, []);
};
