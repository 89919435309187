import { LogoutOutlined, MenuOutlined, ProfileOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Divider, Drawer, Dropdown, Menu as MenuAntd, Typography } from 'antd';
import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Logo } from 'shared/assets/sprite';
import { State } from 'store';
import { deleteAuthentication } from 'store/auth/actions';
import Menu from '../Menu';
import styles from './Header.module.less';

const { Text } = Typography;

const { primaryColor } = styles;

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const {
    profile: {
      data: { email, first_name, last_name, partner_settings },
    },
  } = useSelector((state: State) => state);

  const logOut = useCallback(() => {
    dispatch(deleteAuthentication());
  }, [dispatch]);

  return (
    <div className={cn(styles.Header)}>
      <div className={cn(styles.Content)}>
        <div className={styles.Menu}>
          <Button type="link" onClick={() => setIsOpenDrawer(true)}>
            <MenuOutlined style={{ fontSize: '20px' }} />
          </Button>
          <Drawer placement="left" title="Меню" onClose={() => setIsOpenDrawer(false)} visible={isOpenDrawer}>
            <Menu cb={() => setIsOpenDrawer(false)} />
          </Drawer>
        </div>

        <div className={cn(styles.Logo)} onClick={() => history.push('/')}>
          <div className={cn(styles.Content)}>
            <Logo width={63} height={30} fill={primaryColor} style={{ minWidth: '40px' }} />
            <svg
              width="20"
              height="20"
              style={{ margin: '0 10px', minWidth: '20px' }}
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.4 0.79L19.61 0L10.2 9.41L0.79 0L0 0.79L9.41 10.2L0 19.61L0.79 20.4L10.2 10.99L19.61 20.4L20.4 19.61L10.99 10.2L20.4 0.79Z"
                fill="black"
              ></path>
            </svg>
            <img className={styles.LogoPartner} height={30} src={partner_settings?.logo_url || ''} />
          </div>
        </div>

        <Dropdown
          overlay={
            <MenuAntd>
              <MenuAntd.Item className={styles.Email} key="0">
                {email}
              </MenuAntd.Item>
              <MenuAntd.Item className={styles.Item} onClick={() => history.push('/profile')}>
                <ProfileOutlined /> <Text type="secondary">Профиль</Text>
              </MenuAntd.Item>

              <MenuAntd.Item disabled className={styles.Devider}>
                <Divider className={styles.Divider} />
              </MenuAntd.Item>

              <MenuAntd.Item className={styles.Item} onClick={logOut}>
                <LogoutOutlined /> <Text type="secondary">Выйти</Text>
              </MenuAntd.Item>
            </MenuAntd>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <Button type="link" shape="circle" className={styles.Button} onClick={(e) => e.preventDefault()}>
            <p className={styles.Title}>{`${last_name || ''} ${first_name || ''} `}</p>
            <UserOutlined style={{ fontSize: '21px', paddingLeft: '5px' }} />
          </Button>
        </Dropdown>
      </div>
    </div>
  );
};

export default Header;
