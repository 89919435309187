import React, { useEffect, useMemo } from 'react';
import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, Scatter, Tooltip, ScatterChart } from 'recharts';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from 'store/products/actions';
import { State } from 'store';
import cn from 'classnames';
import { Card } from 'antd';
import _ from 'lodash';

import styles from './Products.module.less';

const Products = () => {
  const dispatch = useDispatch();

  const by_product = useSelector((state: State) => state.dashboard.data?.attributes.delivery_requests.by_product);
  const productsState = useSelector((state: State) => state.products.byId);

  const products = useMemo(
    () =>
      _(by_product)
        .entries()
        .map(([key, value]) => ({
          name: productsState?.[key]?.name || key,
          y: value,
        }))
        .sortBy('value')
        .reverse()
        .value(),
    [by_product, productsState],
  );

  useEffect(() => {
    dispatch(getProducts());

    // eslint-disable-next-line
  }, []);

  return (
    <Card className={cn(styles.Products)} title="Продукты">
      <ResponsiveContainer height={300} width="100%">
        <ScatterChart margin={{ top: 0, right: 5, bottom: 0, left: 0 }}>
          <XAxis
            dataKey="name"
            name="Продукт"
            tickFormatter={(val: any) => {
              const sliced = (val || '').slice(0, 10).trim();

              if (sliced.length < val.length) {
                return sliced + '...';
              }

              return sliced;
            }}
          />
          <CartesianGrid />
          <YAxis
            name="Кол-во"
            unit=" шт"
            dataKey="y"
            tickFormatter={(val: any) => val.toLocaleString().replace(/,/g, ' ')}
          />
          <Scatter data={products} fill="#8884d8" />
          <Tooltip />
        </ScatterChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default Products;
