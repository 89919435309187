import { Button } from 'antd';
import React from 'react';
import styles from './ControlPanel.module.less';

type ControlPanelType = Readonly<{
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
}>;

const ControlPanel: React.FC<ControlPanelType> = ({ handleSubmit }) => {
  return (
    <div className={styles.ControlPanel}>
      <Button type="primary" onClick={handleSubmit as any}>
        Продолжить
      </Button>
    </div>
  );
};

export default ControlPanel;
