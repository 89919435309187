import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { getDeliveryRequestStatuses } from 'store/deliveryRequestStatuses/actions';

export const useDeliveryRequestStatus = () => {
  const dispatch = useDispatch();
  const {
    deliveryRequestStatuses: { byId },
    loading: { deliveryRequestStatuses: loading },
  } = useSelector((state: State) => state);

  React.useEffect(() => {
    if (!loading && Object.keys(byId).length === 0) {
      dispatch(getDeliveryRequestStatuses());
    }

    // eslint-disable-next-line
  }, []);
};
