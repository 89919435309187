import React, { FC, useEffect, useMemo } from 'react';
import { selectFeedbackReasons } from 'store/feedbackReasons/selectors';
import { createFeedback } from 'store/feedbacks/actions';
import { Modal, Radio, Input, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ModalProps } from 'antd/lib/modal';
import { useFormik } from 'formik';
import cn from 'classnames';
import * as Yup from 'yup';
import _ from 'lodash';

import { getFeedbackReasons } from 'store/feedbackReasons/actions';
import useCheckModal from 'shared/utils/checkModal';
import { State } from 'store';

import styles from './FeedBack.module.less';

const { Text } = Typography;
const { TextArea } = Input;

const initialValues = {
  reason: '',
  description: '',
};

const validationSchema = Yup.object().shape({
  reason: Yup.string().required('Обязательное поле'),
  description: Yup.string().required('Обязательное поле'),
});

const FeedBack: FC<ModalProps> = ({ ...modalProps }) => {
  const dispatch = useDispatch();

  const isReportingError = useSelector((state: State) => state.error.createFeedback);
  const isFeedbackLoading = useSelector((state: State) => state.loading.createFeedback);
  const isFeedbackReasonsLoaded = useSelector((state: State) => state.loaded.createFeedback);
  const feedbackReasons = useSelector((state: State) => _.values(selectFeedbackReasons(state)));

  const { values, handleChange, resetForm, handleSubmit, errors, touched, submitForm } = useFormik({
    initialValues,
    onSubmit: ({ description, reason }) => {
      dispatch(createFeedback({ description, reason }));
    },
    validationSchema,
  });

  const textAreaPlaceholder = useMemo(
    () => feedbackReasons.find(({ id }) => values.reason === id)?.description ?? 'Выберите тему',
    [values.reason, feedbackReasons],
  );

  useCheckModal({
    isModalVisible: !!modalProps.visible,
    loading: isFeedbackLoading,
    error: isReportingError,
    onClose: modalProps.onCancel,
  });

  useEffect(() => {
    if (!isFeedbackReasonsLoaded) {
      dispatch(getFeedbackReasons({}));
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    modalProps.visible && resetForm();

    // eslint-disable-next-line
  }, [modalProps.visible]);

  return (
    <Modal
      {...modalProps}
      title="Отправить сообщение"
      okButtonProps={{ loading: isFeedbackLoading }}
      okText="Отправить"
      onOk={submitForm}
      maskTransitionName=""
    >
      <form onSubmit={handleSubmit} className={cn(styles.FeedBack)}>
        <Radio.Group
          className={styles.RadioGroup}
          onChange={handleChange}
          value={values.reason}
          id="reason"
          name="reason"
        >
          {feedbackReasons.map(({ id, name }) => (
            <Radio value={id} key={id}>
              {name}
            </Radio>
          ))}
        </Radio.Group>
        {touched.reason && errors.reason && <Text type="danger">{errors.reason}</Text>}

        <TextArea
          className={styles.TextArea}
          onChange={handleChange}
          value={values.description}
          disabled={!values.reason}
          placeholder={textAreaPlaceholder}
          autoSize={{ minRows: 3, maxRows: 5 }}
          id="description"
          name="description"
        />
        {touched.description && errors.description && !errors.reason && <Text type="danger">{errors.description}</Text>}
      </form>
    </Modal>
  );
};

export default FeedBack;
