import React from 'react';
import cn from 'classnames';

import styles from './Os.module.less';

type OsProps = Readonly<{
  center?: boolean;
}>;

const Os: React.FC<OsProps> = ({ center = true }) => {
  return (
    <div className={cn(styles.OS, center && styles.Center)}>
      <a href="https://apps.apple.com/ru/app/google-authenticator/id388497605" target="_blank" rel="noreferrer">
        <img src="https://files.fastriver.ru/app/ios.png" alt="" style={{ height: '30px' }} />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ru&gl=US"
        target="_blank"
        rel="noreferrer"
      >
        <img src="https://files.fastriver.ru/app/android.png" alt="" style={{ height: '30px' }} />
      </a>
    </div>
  );
};

export default Os;
