import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import cn from 'classnames';
import React, { FC } from 'react';
import styles from './WrapperField.module.less';

interface WrapperFieldProps {
  title?: string;
  value?: string | number | JSX.Element;
  handlerEdit?: () => void;
}

const WrapperField: FC<WrapperFieldProps> = ({ title = '', value, handlerEdit }) => {
  return (
    <div className={cn(styles.WrapperField)}>
      {title && <div className={cn(styles.Title)}>{title}:</div>}
      <div className={cn(styles.Data)}>
        <div className={cn(styles.Text)}>{value || 'не указано'}</div>
        {handlerEdit && (
          <Button type="link" className={cn(styles.Button)} onClick={handlerEdit}>
            <EditOutlined className={styles.Icon} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default WrapperField;
