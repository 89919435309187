import React from 'react';
import styles from './SelectInn.module.less';
import { Input, Typography } from 'antd';
import { SetFieldValue } from '../../useGenFormik';
import { useInitialUpdateField } from 'pages/DeliveryRequest/utils';

type SelectInnProps = Readonly<{
  value?: string;
  setFieldValue: SetFieldValue;
  configBlur: any;
  touched?: boolean;
  error?: string;
}>;

const SelectInn: React.FC<SelectInnProps> = ({ value, setFieldValue, configBlur, touched, error }) => {
  const onBlurCompanyName = useInitialUpdateField({
    name: 'inn',
    updateMessage: 'Инн',
    ...configBlur,
  });

  return (
    <div className={styles.SelectInn}>
      <Input
        placeholder="Инн"
        name="inn"
        value={value}
        onChange={(e) => setFieldValue('inn', e.target.value, true)}
        onBlur={onBlurCompanyName}
        className={styles.Select}
        type="number"
      />
      {touched && error && <Typography.Text type="danger">{error}</Typography.Text>}
    </div>
  );
};

export default SelectInn;
