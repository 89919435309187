import { PlusOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Select, Typography } from 'antd';
import cn from 'classnames';
import { useFormik } from 'formik';
import _ from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import useCheckModal from 'shared/utils/checkModal';
import { State } from 'store';
import { getUserRoles } from 'store/userRoles/actions';
import { createUser, getUsers } from 'store/users/actions';
import * as Yup from 'yup';
import styles from './CreateUser.module.less';

const { Text } = Typography;
const { Option } = Select;

const validationSchema = Yup.object().shape({
  first_name: Yup.string().min(2, 'Слишком коротко').max(50, 'Слишком длинно').required('Обязательное поле'),
  last_name: Yup.string().min(2, 'Слишком коротко').max(50, 'Слишком длинно').required('Обязательное поле'),
  patronymic: Yup.string().min(2, 'Слишком коротко').max(50, 'Слишком длинно'),
  email: Yup.string().email('Некорректный Email').required('Обязательное поле'),
});

const initialValues = {
  first_name: '',
  last_name: '',
  patronymic: '',
  email: '',
  mobile_phone: '',
  roles: [],
};

type CreateUserProps = Readonly<{
  isPluseIcon?: boolean;
  className?: string;
}>;

const CreateUser = ({ isPluseIcon = false, className }: CreateUserProps) => {
  const dispatch = useDispatch();

  const [isModal, setModal] = useState(false);

  const loading = useSelector((state: State) => state.loading.createUser);
  const error = useSelector((state: State) => state.error.createUser);
  const { userRoles } = useSelector((state: State) => state);

  const roleOptions = useMemo(() => {
    if (_.isEmpty(userRoles.byId)) return null;

    return _.values(userRoles.byId).map(({ id, name }) => (
      <Option value={id} label={name} key={id}>
        {name}
      </Option>
    ));
  }, [userRoles.byId]);

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (value) => {
      dispatch(createUser(value));
    },
  });

  const handlerOpening = useCallback(() => setModal(true), [setModal]);
  const handlerClosing = useCallback(() => setModal(false), [setModal]);
  const handlerSubmit = useCallback(() => handleSubmit(), [handleSubmit]);

  useCheckModal({
    isModalVisible: isModal,
    loading,
    error,
    onClose: () => {
      handlerClosing();
      dispatch(getUsers());
    },
  });

  useEffect(() => {
    isModal && resetForm();
  }, [isModal, resetForm]);

  useEffect(() => {
    dispatch(getUserRoles());

    // eslint-disable-next-line
  }, []);

  return (
    <div className={cn(styles.CreateUser, className)}>
      {isPluseIcon ? (
        <Button type="link" onClick={handlerOpening} className={styles.Button}>
          <PlusOutlined style={{ fontSize: '22px' }} />
        </Button>
      ) : (
        <Button type="primary" onClick={handlerOpening}>
          Добавить пользователя
        </Button>
      )}

      <Modal
        okText="Добавить"
        onOk={handlerSubmit}
        cancelText="Отмена"
        title="Добавить пользователя"
        visible={isModal}
        onCancel={handlerClosing}
        maskTransitionName=""
        width={320}
        okButtonProps={{ loading }}
      >
        <div className={cn(styles.Modal)}>
          <Input
            onChange={handleChange}
            value={values.first_name}
            name="first_name"
            placeholder="Имя"
            onBlur={handleBlur}
          />
          {touched.first_name && errors.first_name && <Text type="danger">{errors.first_name}</Text>}

          <Input
            onChange={handleChange}
            value={values.last_name}
            name="last_name"
            placeholder="Фамилия"
            onBlur={handleBlur}
          />
          {touched.last_name && errors.last_name && <Text type="danger">{errors.last_name}</Text>}

          <Input
            onChange={handleChange}
            value={values.patronymic}
            name="patronymic"
            placeholder="Отчество"
            onBlur={handleBlur}
          />
          {touched.patronymic && errors.patronymic && <Text type="danger">{errors.patronymic}</Text>}

          <Input onChange={handleChange} value={values.email} name="email" placeholder="email" onBlur={handleBlur} />
          {touched.email && errors.email && <Text type="danger">{errors.email}</Text>}

          <NumberFormat
            placeholder="+7 (___) ___-__-__"
            name="mobile_phone"
            format="+7 (###) ###-##-##"
            className="ant-input"
            mask="_"
            value={values.mobile_phone}
            onValueChange={({ value }) => setFieldValue('mobile_phone', value, true)}
            onBlur={() => setFieldTouched('roles', true)}
          />
          {touched.mobile_phone && errors.mobile_phone && <Text type="danger">{errors.mobile_phone}</Text>}

          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Роли"
            value={values.roles}
            onChange={(v) => setFieldValue('roles', v, true)}
            onBlur={() => setFieldTouched('roles', true)}
            optionLabelProp="label"
          >
            {roleOptions}
          </Select>
          {touched.roles && errors.roles && <Text type="danger">{errors.roles}</Text>}
        </div>
      </Modal>
    </div>
  );
};

export default memo(CreateUser);
