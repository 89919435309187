import React, { useMemo } from 'react';
import { WarningTwoTone } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { State } from 'store';
import { Card } from 'antd';
import cn from 'classnames';

import styles from './Payments.module.less';

const Payments = () => {
  const costs = useSelector((state: State) => state.dashboard.data?.attributes.costs);
  const roles = useSelector((state: State) => state.profile.data.roles);

  const loading = useSelector((state: State) => state.loading.dashboard);

  const content = useMemo(() => {
    if (roles?.includes('billing')) {
      return (
        <>
          <div className={styles.Item}>
            <div>В текущем месяце</div>
            <div>{costs?.this_month || 0}</div>
          </div>
          <div className={styles.Item}>
            <div>В прошлом месяце</div>
            <div>{costs?.last_month || 0}</div>
          </div>
        </>
      );
    }

    if (!roles) return null;

    return (
      <div className={styles.Warning}>
        <WarningTwoTone twoToneColor="#faad14" style={{ fontSize: '40px' }} />
        <div>Недостаточно прав</div>
      </div>
    );
  }, [costs, roles]);

  return (
    <Card className={cn(styles.Payments)} title="Суммы оплаты доставок" loading={loading}>
      <div className={styles.Body}>{content}</div>
    </Card>
  );
};

export default Payments;
