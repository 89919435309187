import { Card, Tag, Typography } from 'antd';
import cn from 'classnames';
import moment from 'moment';
import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IStatusColors, STATUS_COLORS } from 'shared/constants';
import { selectDeliveryStatuses } from 'store/deliveryStatuses/selectors';
import { selectFailureReasons } from 'store/failureReasons/selectors';
import { State } from 'store/index';
import styles from './Item.module.less';

const { Text } = Typography;

const Item = ({ destination, date, time, status, failure_comment, failure_reason, ...delivery }: any) => {
  const state = useSelector((state: State) => state);

  const deliveryStatuses = useMemo(() => selectDeliveryStatuses(state), [state]);
  const failureReasons = useMemo(() => selectFailureReasons(state), [state]);

  const extra = (
    <div className={styles.Status}>
      <Text type="secondary">{`${date ? moment(date).format('DD.MM.GG') : ''} ${
        time ? moment.utc().startOf('day').add(time, 'minutes').format('HH:mm') : ''
      }`}</Text>{' '}
      <Tag color={STATUS_COLORS[status as keyof IStatusColors]}>{deliveryStatuses?.[status]?.name || status}</Tag>
    </div>
  );

  const reason = useMemo(() => {
    if (!failure_reason && !failure_comment) return null;

    return (
      <Card.Meta
        title={failureReasons?.[failure_reason]?.name}
        description={failure_comment}
        className={styles.FailureReasons}
      />
    );
  }, [failure_reason, failure_comment, failureReasons]);

  return (
    <div className={cn(styles.Item)}>
      <Card title="Доставка" extra={extra}>
        <Card.Meta description={destination?.address} />

        {delivery?.completed_at && (
          <Card.Meta description={`Доставлена: ${moment(delivery.completed_at * 1000).format('DD.MM.YYYY HH:mm')}`} />
        )}
        {delivery?.service_time_fact && (
          <Card.Meta
            description={`Время встречи: ${moment('1900-01-01 00:00:00')
              .add(delivery?.service_time_fact, 'seconds')
              .format(`${delivery.service_time_fact >= 3600 ? 'HH ч' : ''} mm мин ss сек`)}`}
          />
        )}
        {reason}
      </Card>
    </div>
  );
};

export default memo(Item);
