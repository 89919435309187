/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type User = Readonly<{
  id: number;
  sip_caller_id?: string | null;
  type: string;
  email: string;
  first_name: string;
  last_name: string;
  patronymic: string;
  roles: null | string[];
  city_id: number;
  work_start: number;
  work_end: number;
  depot_id: number;
  mobile_phone: string;
  created_at: number;
  fired_at: null | number;
  last_logged_in: null | string;
  by_car?: boolean;
  home_address?: string;
  home_lat?: number;
  home_lon?: number;
  telegram?: string;
  passport_series?: string;
  passport_number?: string;
  zone_id?: number;
  call_sip_only?: boolean;
  notes?: string;
  captain_id?: number | null;
  captain: User;
}>;

export interface DeliveryRequestComment {
  /** ID */
  id: number;

  /** ID заявки на доставку */
  delivery_request_id: number;

  /** Текст комментария */
  body: string;

  /** Причина комментария */
  reason: string;

  /** Прочитано */
  read: boolean;

  /** Показывать ли партнёру */
  visible: boolean;

  /** unix-timestamp создания */
  created_at: number;

  /** unix-timestamp изменения */
  updated_at: number;

  author?: User;

  created_at_t?: string;

  reason_t?: string;
}

import axios, { AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'> & {
  showErrorToast?: boolean;
};

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

import { AxiosError } from 'axios';
/**
 * @title Coruscant (params in:formData)
 * @version 2.0
 * @baseUrl //localhost:3000
 *
 *
 * <p>Вторая версия API</p>
 *
 * <p>Активируется хедером `X-API-VERSION=v2`</p>
 * &copy; 2020 Fastriver
 */

type GetWithoutBody<T extends { body?: any }> = T extends { body?: infer R } ? R : never;

import { genInfiniteQuery, genMutation, genQuery, TQueryKey } from './genHandler';

/**
 * No description
 *
 * @tags DeliveryRequestComment
 * @name GetapiAdminTaskComments
 * @summary Список комментариев
 * @request GET:/delivery_request_comments
 */

export type getapiAdminTaskCommentsOptions = {
  query?: {
    filter?: {
      delivery_request_uuid?: string | number;
      read?: boolean;
      by_partner?: true;
      by_org?: true;
      task_id?: string | number;
      visible?: boolean;
    };
    include?: string[];
  };
  params?: RequestParams;
};
export type getapiAdminTaskCommentsData = DeliveryRequestComment[];

export const getapiAdminTaskComments = ({ query, params }: getapiAdminTaskCommentsOptions | void = {}) =>
  axios.request<any, AxiosResponse<DeliveryRequestComment[], AxiosError<any>>>({
    url: `/delivery_request_comments`,
    method: 'GET',
    params: query,
    type: ContentType.UrlEncoded,
    ...params,
  });
export const genQueryKeygetapiAdminTaskComments = (
  args: {
    query?: {
      filter?: {
        delivery_request_uuid?: number;
        read?: boolean;
        by_partner?: undefined | true;
        by_org?: undefined | true;
      };
      include?: string[];
    };
  } | void = {},
): TQueryKey => {
  if (Object.keys(args || {}).length > 0) return ['/delivery_request_comments', args || {}];
  return ['/delivery_request_comments'];
};
export const useMutationgetapiAdminTaskComments = genMutation(getapiAdminTaskComments);
export const useInfiniteQuerygetapiAdminTaskComments = genInfiniteQuery({
  handler: getapiAdminTaskComments,
  genQueryKey: genQueryKeygetapiAdminTaskComments,
});
export const useQuerygetapiAdminTaskComments = genQuery({
  handler: getapiAdminTaskComments,
  genQueryKey: genQueryKeygetapiAdminTaskComments,
});

/**
 * No description
 *
 * @tags DeliveryRequestComment
 * @name PostapiAdminTaskComments
 * @summary Создание комментария
 * @request POST:/delivery_request_comments
 */

export type postapiAdminTaskCommentsOptions = {
  data: {
    'data[attributes][body]': string;
    'data[attributes][delivery_request_id]': number;
    'data[attributes][visible]'?: boolean;
  };
  params?: RequestParams;
};
export type postapiAdminTaskCommentsData = GetWithoutBody<DeliveryRequestComment>;

export const postapiAdminTaskComments = ({ data, params }: postapiAdminTaskCommentsOptions) =>
  axios.request<any, AxiosResponse<DeliveryRequestComment, AxiosError<any>>>({
    url: `/delivery_request_comments`,
    method: 'POST',
    data: data,
    type: ContentType.UrlEncoded,
    ...params,
  });
export const genQueryKeypostapiAdminTaskComments = (args: {
  data: {
    'data[attributes][body]': string;
    'data[attributes][delivery_request_id]': number;
    'data[attributes][visible]'?: boolean;
  };
}): TQueryKey => {
  if (Object.keys(args || {}).length > 0) return ['/delivery_request_comments', args || {}];
  return ['/delivery_request_comments'];
};
export const useMutationpostapiAdminTaskComments = genMutation(postapiAdminTaskComments);
export const useInfiniteQuerypostapiAdminTaskComments = genInfiniteQuery({
  handler: postapiAdminTaskComments,
  genQueryKey: genQueryKeypostapiAdminTaskComments,
});
export const useQuerypostapiAdminTaskComments = genQuery({
  handler: postapiAdminTaskComments,
  genQueryKey: genQueryKeypostapiAdminTaskComments,
});

/**
 * No description
 *
 * @tags DeliveryRequestComment
 * @name PatchapiAdminTaskCommentsId
 * @summary Обновление комментария
 * @request PATCH:/delivery_request_comments/{id}
 */

export type patchapiAdminTaskCommentsIdOptions = {
  id: number;
  data: {
    'data[attributes][body]': string;
    'data[attributes][delivery_request_id]': number;
    'data[attributes][visible]'?: boolean;
  };
  params?: RequestParams;
};
export type patchapiAdminTaskCommentsIdData = GetWithoutBody<DeliveryRequestComment>;

export const patchapiAdminTaskCommentsId = ({ id, data, params }: patchapiAdminTaskCommentsIdOptions) =>
  axios.request<any, AxiosResponse<DeliveryRequestComment, AxiosError<any>>>({
    url: `/delivery_request_comments/${id}`,
    method: 'PATCH',
    data: data,
    type: ContentType.FormData,
    ...params,
  });
export const genQueryKeypatchapiAdminTaskCommentsId = (args: {
  id: number;
  data: {
    'data[attributes][body]': string;
    'data[attributes][delivery_request_id]': number;
    'data[attributes][visible]'?: boolean;
  };
}): TQueryKey => {
  if (Object.keys(args || {}).length > 0) return ['/delivery_request_comments/${id}', args || {}];
  return ['/delivery_request_comments/${id}'];
};
export const useMutationpatchapiAdminTaskCommentsId = genMutation(patchapiAdminTaskCommentsId);
export const useInfiniteQuerypatchapiAdminTaskCommentsId = genInfiniteQuery({
  handler: patchapiAdminTaskCommentsId,
  genQueryKey: genQueryKeypatchapiAdminTaskCommentsId,
});
export const useQuerypatchapiAdminTaskCommentsId = genQuery({
  handler: patchapiAdminTaskCommentsId,
  genQueryKey: genQueryKeypatchapiAdminTaskCommentsId,
});

/**
 * No description
 *
 * @tags DeliveryRequestComment
 * @name PatchapiAdminTaskCommentsIdRead
 * @summary Прочитать комментария
 * @request PATCH:/delivery_request_comments/{id}/read
 */

export type patchapiAdminTaskCommentsIdReadOptions = {
  id: number;
  params?: RequestParams;
};
export type patchapiAdminTaskCommentsIdReadData = GetWithoutBody<DeliveryRequestComment>;

export const patchapiAdminTaskCommentsIdRead = ({ id, params }: patchapiAdminTaskCommentsIdReadOptions) =>
  axios.request<any, AxiosResponse<DeliveryRequestComment, AxiosError<any>>>({
    url: `/delivery_request_comments/${id}/read`,
    method: 'PATCH',
    type: ContentType.UrlEncoded,
    ...params,
  });
export const genQueryKeypatchapiAdminTaskCommentsIdRead = (args: { id: number }): TQueryKey => {
  if (Object.keys(args || {}).length > 0) return ['/delivery_request_comments/${id}/read', args || {}];
  return ['/delivery_request_comments/${id}/read'];
};
export const useMutationpatchapiAdminTaskCommentsIdRead = genMutation(patchapiAdminTaskCommentsIdRead);
export const useInfiniteQuerypatchapiAdminTaskCommentsIdRead = genInfiniteQuery({
  handler: patchapiAdminTaskCommentsIdRead,
  genQueryKey: genQueryKeypatchapiAdminTaskCommentsIdRead,
});
export const useQuerypatchapiAdminTaskCommentsIdRead = genQuery({
  handler: patchapiAdminTaskCommentsIdRead,
  genQueryKey: genQueryKeypatchapiAdminTaskCommentsIdRead,
});
