import React, { useMemo } from 'react';

// eslint-disable-next-line
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useSelector } from 'react-redux';
import Item from './components/Item';
import { Divider } from 'antd';
import cn from 'classnames';
import _ from 'lodash';

import { selectActiveDeliveryId, selectDeliveries } from 'store/deliveries/selectors';

import styles from './List.module.less';

const List = () => {
  const state = useSelector((state) => state);

  const deliveries = useMemo(() => _(selectDeliveries(state)).values().value(), [state]);
  const activeDeliveryId = useMemo(() => selectActiveDeliveryId(state), [state]);

  return (
    <div className={cn(styles.List)}>
      <Divider orientation="left">История доставок</Divider>

      <ResponsiveMasonry columnsCountBreakPoints={{ 320: 1, 768: 2 }}>
        <Masonry gutter="15px">
          {deliveries
            ?.filter(({ id }) => id !== activeDeliveryId)
            .map((delivery, index) => (
              <Item key={index} {...delivery} />
            ))}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
};

export default List;
