import { Card } from 'antd';
import cn from 'classnames';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import WrapperField from 'shared/components/WrapperField';
import { State } from 'store';
import styles from './Body.module.less';
import AttemptsFailed from './components/AttemptsFailed';
import AttemptsTwoFa from './components/AttemptsTwoFa';
import BlockStatus from './components/BlockStatus';
import ChangeStatusTwoFA from './components/ChangeStatusTwoFA';
import Email from './components/Email';
import FullName from './components/FullName';
import MobilePhone from './components/MobilePhone';
import OtpSentTwoFA from './components/OtpSentTwoFA';
import PassingTimeTwoFA from './components/PassingTimeTwoFA';
import ResetPassword from './components/ResetPassword';
import Roles from './components/Roles';
import StatusTwoFA from './components/StatusTwoFA';
import TypeTwoFA from './components/TypeTwoFA';
import UnlockIntrusion from './components/UnlockIntrusion';

const Body = () => {
  const { id }: { id: string } = useParams();

  const user = useSelector((state: State) => state.users.byId[id]);
  const loading = useSelector((state: State) => state.loading.getUser);

  const personalData = useMemo(() => {
    if (loading || !user) return;

    return (
      <Card title="Личные данные" className={cn(styles.PersonalData)} loading={loading}>
        <FullName user={user} />
        <Email user={user} />
        <MobilePhone user={user} />
        <Roles user={user} />
      </Card>
    );

    // eslint-disable-next-line
  }, [loading]);

  const security = useMemo(() => {
    if (loading || !user) return;

    return (
      <Card title="Безопасность" className={cn(styles.Security)} loading={loading}>
        <WrapperField
          title="Время создания пользователя"
          value={moment(user.created_at * 1000).format('DD.MM.YYYY HH.mm')}
        />
        <WrapperField
          title="Время последнего логина"
          value={user.last_logged_in ? moment(user.last_logged_in * 1000).format('DD.MM.YYYY HH.mm') : '-'}
        />
        <AttemptsFailed user={user} />
        <BlockStatus user={user} />
        <ResetPassword user={user} />
        <StatusTwoFA user={user} />
        <TypeTwoFA user={user} />
        <PassingTimeTwoFA user={user} />
        <OtpSentTwoFA user={user} />
        <AttemptsTwoFa user={user} />
        <ChangeStatusTwoFA user={user} />
        <UnlockIntrusion user={user} />
      </Card>
    );

    // eslint-disable-next-line
  }, [loading]);

  return (
    <div className={styles.Body}>
      {personalData}
      {security}
    </div>
  );
};

export default Body;
