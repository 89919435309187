import React from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { State } from 'store';
import { NotFound } from 'pages';

import Header from './components/Header';
import Table from './components/Table';

import styles from './UserList.module.less';

const UserList = () => {
  const error = useSelector((state: State) => state.error.getUsers);

  if (error) return <NotFound status={error.status} title={error.status} subTitle={error.title} />;

  return (
    <div className={cn(styles.UserList)}>
      <Header />
      <Table />
    </div>
  );
};

export default UserList;
