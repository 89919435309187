import { Modal } from 'antd';
import cn from 'classnames';
import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useCheckModal from 'shared/utils/checkModal';
import { State } from 'store';
import { activateTwoFaUser, disableTwoFaUser, getUser } from 'store/users/actions';
import { User } from 'store/users/reducers';
import styles from './EditModal.module.less';

interface EditModalProps {
  isModalVisible: boolean;
  setVisible: (arg: boolean) => void;
  user: User;
}

const EditModal: FC<EditModalProps> = ({ isModalVisible, setVisible, user }) => {
  const dispatch = useDispatch();
  const { two_fa_status: twoFaStatus } = user;

  const handleClosing = useCallback(
    () => setVisible(false),

    // eslint-disable-next-line
    [],
  );

  const loading = useSelector((state: State) => state.loading.activateTwoFaUser || state.loading.disableTwoFaUser);
  const error = useSelector((state: State) => state.error.activateTwoFaUser || state.error.disableTwoFaUser);

  useCheckModal({
    isModalVisible,
    loading,
    error,
    onClose: () => {
      handleClosing();
      dispatch(getUser({ id: user.id }));
    },
  });

  const handlerSubmit = useCallback(() => {
    if (twoFaStatus === 'disabled') {
      dispatch(
        activateTwoFaUser({
          id: user.id,
        }),
      );
    } else {
      dispatch(
        disableTwoFaUser({
          id: user.id,
        }),
      );
    }
  }, [dispatch, user.id, twoFaStatus]);

  return (
    <Modal
      title={`${twoFaStatus === 'disabled' ? 'Активировать' : 'Деактивировать'} 2FA`}
      width={440}
      visible={isModalVisible}
      onOk={() => handlerSubmit()}
      onCancel={handleClosing}
      okText={twoFaStatus === 'disabled' ? 'Активировать' : 'Деактивировать'}
      maskTransitionName=""
    >
      <div className={cn(styles.EditModal)}>
        Вы уверены, что хотите {twoFaStatus === 'disabled' ? 'активировать' : 'деактивировать'} 2FA?
      </div>
    </Modal>
  );
};

export default EditModal;
