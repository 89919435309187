import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AutoComplete, Input } from 'antd';
import cn from 'classnames';

import { State } from 'store';
import { DaDataFioActions } from 'store/dadataFio/action';

import styles from './AutoCompleteFio.module.less';

// const { Option } = Select;

interface AutoCompleteFioProps {
  value?: string;
  onChange?: (id: string) => void;
  onBlur?: React.FocusEventHandler<HTMLElement>;
  className?: string;
}

const AutoCompleteFio = ({ value, onChange, onBlur, className }: AutoCompleteFioProps) => {
  const dispatch = useDispatch();

  const [filter, setFilter] = useState('');

  const {
    dadataFio: { byId },
    loading: { dadataFio: isLoading },
  } = useSelector((state: State) => state);

  useEffect(() => {
    dispatch(DaDataFioActions.getDaDataFio({ query: filter }));
  }, [filter, dispatch]);

  const data = React.useMemo(() => Object.values(byId), [byId]);

  return (
    <AutoComplete
      className={cn(styles.AutoCompleteFio, className)}
      onSelect={onChange}
      onChange={(v) => {
        setFilter(v);
        onChange?.(v);
      }}
      options={data}
      onBlur={onBlur}
      value={value}
    >
      <Input.Search placeholder="ФИО" loading={isLoading} />
    </AutoComplete>
  );
};

export default AutoCompleteFio;
