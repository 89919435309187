import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Modal, Typography } from 'antd';
import { useFormik } from 'formik';
import { State } from 'store';
import cn from 'classnames';
import * as Yup from 'yup';

import { updateProfile } from 'store/profile/actions';
import useCheckModal from 'shared/utils/checkModal';

import styles from './EditModal.module.less';

const { Text } = Typography;

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required('Обязательное поле'),
  password: Yup.string().required('Обязательное поле'),
});

interface EditModalProps {
  isModalVisible: boolean;
  setVisible: (arg: boolean) => void;
}

const EditModal: FC<EditModalProps> = ({ isModalVisible, setVisible }) => {
  const dispatch = useDispatch();

  const handleCancel = useCallback(
    () => setVisible(false),

    // eslint-disable-next-line
    [],
  );

  const loading = useSelector((state: State) => state.loading.updateProfile);
  const error = useSelector((state: State) => state.error.updateProfile);
  const profile = useSelector((state: State) => state.profile.data);

  useCheckModal({ isModalVisible, loading, error, onClose: handleCancel });

  const initialValues = useMemo(
    () => ({
      email: profile?.email || '',
      password: '',
    }),
    [profile],
  );

  const { touched, errors, values, handleChange, handleBlur, setValues, handleSubmit, resetForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      dispatch(
        updateProfile({
          id: profile.id,
          ...values,
        }),
      );
    },
  });

  useEffect(() => {
    isModalVisible && resetForm();
    isModalVisible && setValues(initialValues);
  }, [isModalVisible, setValues, initialValues, resetForm]);

  return (
    <Modal
      title="Править Email"
      width={320}
      visible={isModalVisible}
      onOk={() => handleSubmit()}
      onCancel={handleCancel}
      okText="Сохранить"
      maskTransitionName=""
    >
      <div className={cn(styles.EditModal)}>
        <Input
          name="email"
          placeholder="Email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          autoComplete="off"
        />
        {touched.email && errors.email && <Text type="danger">{errors.email}</Text>}
        <Input type="password" style={{ display: 'none' }} />
        <Input
          name="password"
          type="password"
          placeholder="Пароль"
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="new-password"
          value={values.password}
        />
        {touched.password && errors.password && <Text type="danger">{errors.password}</Text>}
      </div>
    </Modal>
  );
};

export default EditModal;
