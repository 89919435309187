import { useEffect, useMemo } from 'react';
import { SetNotifications } from 'store/notifications/action';
import { useDispatch } from 'react-redux';
import { usePrevious } from 'react-use';

type error = false | { status: 403 | 404 | 500; title?: string };

interface UseCheckModalProps {
  onClose?: (args?: any) => void;
  loading: boolean;
  error: error;
  message?: string;
  description?: string;
  isModalVisible: boolean;
}

const useCheckModal = ({
  isModalVisible,
  loading,
  error,
  onClose,
  message = 'Успешно',
  description = '',
}: UseCheckModalProps) => {
  const dispatch = useDispatch();
  const prevLoading = usePrevious(loading);

  const isSuccess = useMemo(
    () => prevLoading === true && loading === false && isModalVisible && !error,
    // eslint-disable-next-line
    [loading],
  );

  useEffect(() => {
    if (isSuccess) {
      !!message &&
        dispatch(
          SetNotifications({
            variant: 'success',
            message,
            description,
          }),
        );
      onClose?.();
    }

    // eslint-disable-next-line
  }, [isSuccess, dispatch]);
};

export default useCheckModal;
