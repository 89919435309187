import { Card } from 'antd';
import cn from 'classnames';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import CreateUser from 'shared/components/CreateUser';
import { State } from 'store';
import styles from './Users.module.less';

const Users = () => {
  const history = useHistory();

  const users = useSelector((state: State) => state.dashboard.data?.attributes.users);
  const user = useSelector((state: State) => state.profile.data);
  const roles = useSelector((state: State) => state.profile.data.roles);

  const handler = useCallback(() => {
    if (roles?.includes('admin')) return history.push('/user/list');

    return;
  }, [roles, history]);

  return (
    <Card
      className={cn(styles.Users)}
      title="Пользователи"
      extra={<CreateUser className={cn(!user?.roles?.includes('admin') && styles.Disabled)} isPluseIcon={true} />}
    >
      <div
        className={cn(styles.CountUsers, {
          [styles.Active]: roles?.includes('admin'),
        })}
        onClick={handler}
      >
        {users}
      </div>
    </Card>
  );
};

export default Users;
