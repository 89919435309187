import { call, put } from 'redux-saga/effects';

import * as commonActions from 'store/common/actions';

import * as actionsNotification from './notifications/action';

type startEffectsStatusesProps = ({ name }: { name: string }) => void;
type endEffectsStatusesProps = ({ name }: { name: string; message?: string; description?: string }) => void;
type errorEffectsStatusesProps = ({ name, error }: { name: string; error: any }) => void;

export const startEffectsStatuses: startEffectsStatusesProps = function* ({ name }) {
  yield put(commonActions.setLoading({ [name]: true }));
  yield put(commonActions.setLoaded({ [name]: false }));
};
export const endEffectsStatuses: endEffectsStatusesProps = function* ({ name, message = '', description = '' }) {
  yield put(commonActions.setLoading({ [name]: false }));
  yield put(commonActions.setLoaded({ [name]: true }));
  yield put(commonActions.setError({ [name]: false }));

  if (!!message || !!description) {
    yield put(
      actionsNotification.SetNotifications({
        variant: 'success',
        message,
        description,
      }),
    );
  }
};
export const errorEffectsStatuses: errorEffectsStatusesProps = function* ({ error, name }) {
  yield put(
    actionsNotification.SetNotifications({
      variant: 'error',
      message: error?.response?.status || '',
      description: error?.response?.data?.error || '',
    }),
  );
  yield put(
    commonActions.setError({ [name]: { status: error?.response?.status, title: error?.response?.data?.error } }),
  );
  yield put(commonActions.setLoading({ [name]: false }));
};

const readAsText = (blob: any) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsText(blob);
  });
};

export function* getError(error: any) {
  const file = yield call(readAsText, error.response.data);
  const { error: message } = JSON.parse(file);

  return {
    response: {
      status: error?.response?.status,
      data: { error: message },
    },
  };
}
