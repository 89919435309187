import { combineActions, handleActions } from 'redux-actions';
import * as actions from './action';

export interface NotificationsState {
  variant?: 'success' | 'error' | 'warning' | 'info' | null;
  message?: string | null;
  description?: string | null;
}

const initialState: NotificationsState = {};

export default handleActions(
  {
    [combineActions(actions.SetNotifications).toString()](state: NotificationsState, { payload }: any) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  initialState,
);
