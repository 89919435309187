import { createAction } from 'redux-actions';

export const getDeliveryRequest = createAction('GET_DELIVERY_REQUEST');
export const getDeliveryRequestSuccess = createAction('GET_DELIVERY_REQUEST_SUCCESS');
export const getDeliveryRequestFailure = createAction('GET_DELIVERY_REQUEST_FAILURE');

export const getDeliveryRequests = createAction('GET_DELIVERY_REQUESTS');
export const getDeliveryRequestsSuccess = createAction('GET_DELIVERY_REQUESTS_SUCCESS');
export const getDeliveryRequestsFailure = createAction('GET_DELIVERY_REQUESTS_FAILURE');

export const createDeliveryRequest = createAction('CREATE_DELIVERY_REQUESTS');
export const createDeliveryRequestSuccess = createAction('CREATE_DELIVERY_REQUESTS_SUCCESS');
export const createDeliveryRequestFailure = createAction('CREATE_DELIVERY_REQUESTS_FAILURE');

export const updateDeliveryRequest = createAction('UPDATE_DELIVERY_REQUESTS');
export const updateDeliveryRequestSuccess = createAction('UPDATE_DELIVERY_REQUESTS_SUCCESS');
export const updateDeliveryRequestFailure = createAction('UPDATE_DELIVERY_REQUESTS_FAILURE');

export const confirmDeliveryRequest = createAction('CONFIRM_DELIVERY_REQUEST');
export const confirmDeliveryRequestSuccess = createAction('CONFIRM_DELIVERY_REQUEST_SUCCESS');
export const confirmDeliveryRequestFailure = createAction('CONFIRM_DELIVERY_REQUEST_FAILURE');

export const rejectDeliveryRequest = createAction('REJECT_DELIVERY_REQUESTS');

export const deleteDeliveryRequest = createAction('DELETE_DELIVERY_REQUEST');
export const deleteDeliveryRequestSuccess = createAction('DELETE_DELIVERY_REQUEST_SUCCESS');
export const deleteDeliveryRequestFailure = createAction('DELETE_DELIVERY_REQUEST_FAILURE');

export const callDeliveryRequest = createAction('CALL_DELIVERY_REQUEST');
export const callDeliveryRequestSuccess = createAction('CALL_DELIVERY_REQUEST_SUCCESS');
export const callDeliveryRequestFailure = createAction('CALL_DELIVERY_REQUEST_FAILURE');
