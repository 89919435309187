import React, { FC, memo, useCallback, useState } from 'react';
import cn from 'classnames';

import WrapperField from 'shared/components/WrapperField';

import EditModal from './components/EditModal';

import styles from './FullName.module.less';

interface FullNameProps {
  fullName?: string;
}

const FullName: FC<FullNameProps> = ({ fullName }) => {
  const [isModalVisible, setVisible] = useState(false);

  const handlerEdit = useCallback(() => setVisible(true), []);

  return (
    <div className={cn(styles.FullName)}>
      <WrapperField value={fullName} title="ФИО" handlerEdit={handlerEdit} />
      <EditModal {...{ isModalVisible, setVisible }} />
    </div>
  );
};

export default memo(FullName);
