import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';
import * as actions from './actions';

const initialState = {
  byId: {},
  allIds: [],
  data: { total: null },
};

const byId = handleActions(
  {
    [combineActions(
      actions.createDeliveryRequestSuccess,
      actions.updateDeliveryRequestSuccess,
      actions.getDeliveryRequestSuccess,
      actions.deleteDeliveryRequestSuccess,
    )]: (state, { payload }) => ({ ...state, [payload?.uuid]: payload }),

    [actions.getDeliveryRequestsSuccess]: (state, { payload }) => ({
      ...payload.data.reduce(
        (acc, deliveryReq) => ({ ...acc, [deliveryReq.id]: { id: deliveryReq.id, ...deliveryReq.attributes } }),
        {},
      ),
    }),
  },
  initialState.byId,
);

const allIds = handleActions(
  {
    [combineActions(
      actions.createDeliveryRequestSuccess,
      actions.updateDeliveryRequestSuccess,
      actions.getDeliveryRequestSuccess,
      actions.deleteDeliveryRequestSuccess,
    )]: (state, { payload }) => [...new Set([...state, payload?.uuid])],
  },
  initialState.allIds,
);

const data = handleActions(
  {
    [actions.getDeliveryRequestsSuccess]: (state, { payload }) => ({ total: payload.meta?.total }),
  },
  initialState.data,
);

export default combineReducers({
  byId,
  allIds,
  data,
});
