import { call, put, all, takeLatest } from 'redux-saga/effects';
import { get } from 'shared/libs/request';
import * as actions from './actions';

function* getCancelReasons() {
  try {
    const { data } = yield call(get, '/cancel_reasons');

    yield put(actions.getCancelReasonsSuccess(data));
  } catch (error) {
    yield put(actions.getCancelReasonsFailure());
  }
}

export default function* watchCancelReasons() {
  yield all([takeLatest(actions.getCancelReasons, getCancelReasons)]);
}
